import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import logoGoapprovePlus from '../../assets/animations/logoGoapprovePlus.json';

const Container = styled.div`
  z-index: 1200;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2a547c;
`;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: logoGoapprovePlus,
};

const GoApproveSplash = () => (
  <Container>
    <Lottie
      options={defaultOptions}
      height={'100%'}
      width={'30%'}
      isClickToPauseDisabled
    />
  </Container>
);

export default GoApproveSplash;
