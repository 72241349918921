import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MaterialDialogContent from '@material-ui/core/DialogContent';

const Container = styled(MaterialDialogContent)`
  && {
    padding: 10px 20px;
  }
`;

const DialogContent = ({className, children}) => (
  <Container className={className}>{children}</Container>
);

DialogContent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default DialogContent;
