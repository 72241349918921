import {
  DELETE_ALL_APP_LOG_REQUEST,
  DELETE_ALL_APP_LOG_SUCCESS,
  DELETE_ALL_APP_LOG_ERROR,
} from '../../_actions/appLog';

const deleteAllLogsError = (state = null, action) => {
  switch (action.type) {
    case DELETE_ALL_APP_LOG_REQUEST:
    case DELETE_ALL_APP_LOG_SUCCESS:
      return null;
    case DELETE_ALL_APP_LOG_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getDeleteAllLogsError = state => state.deleteAllLogsError;

export default deleteAllLogsError;
