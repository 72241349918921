import {
  API_BASE_URL,
  PUBLIC_KEY,
  CLIENT_PRIVATE_KEY,
} from '../../config/constants';
import {
  generateRandomKey,
  encryptWithSymmKey,
  encryptWithPublicKey,
  decryptWithPrivateKey,
  decryptWithSymmKey,
} from '../encryption';

import {
  NoConnectionError,
  ApiCallErrorWithoutBodyError,
  NotAuthenticatedError,
  NotAllowedError,
  UnknownRequestError,
} from '../../config/messageCodes';
import {getLocale} from '../../utils/locale';
import {timeoutFetch} from '../timeoutFetch';
import {isAgironError, isGoApproveError} from '../agiron';

let language = getLocale().toUpperCase();

const encrypt = body => {
  const keys = {
    symmKey: generateRandomKey(),
    iv: generateRandomKey(),
  };

  const encryptedBody = encryptWithSymmKey(
    JSON.stringify(body),
    keys.symmKey,
    keys.iv
  );

  const encryptedKeys = encryptWithPublicKey(JSON.stringify(keys), PUBLIC_KEY);

  return {
    encryptedBody: JSON.stringify({_data: encryptedBody}),
    encryptedKeys: encryptedKeys,
  };
};

const decrypt = (body, keys) => {
  try {
    const {symmKey, iv} = JSON.parse(
      decryptWithPrivateKey(keys, CLIENT_PRIVATE_KEY)
    );
    const data = decryptWithSymmKey(body, symmKey, iv);
    return JSON.parse(data);
  } catch (error) {
    return {};
  }
};

const callApi = async (method, path, body, user, isFileResponse) => {
  const url = `${API_BASE_URL}${path}`;
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accept-Language', language);

  let requestBody;
  if (body !== undefined || typeof body === 'object') {
    const {encryptedBody, encryptedKeys} = encrypt(body);
    requestBody = encryptedBody;
    headers.append('xyz', encryptedKeys);
  }
  if (user) {
    headers.append('X-User', user);
  }

  try {
    const res = await timeoutFetch(url, {
      credentials: 'include',
      method,
      headers,
      body: requestBody,
    });

    if (res.status === 401) {
      throw NotAuthenticatedError;
    }

    if (isFileResponse && res.status < 400) {
      const file = await res.blob();
      return window.URL.createObjectURL(file);
    }
    let responseBody;
    // If this header exists, it means the response is encrypted.
    const keys = res.headers.get('X-Data-Content');
    if (keys) {
      const text = await res.text();
      responseBody = await decrypt(text, keys);
    } else {
      responseBody = await res.json().catch(() => {});
    }
    if (!res.ok && responseBody && responseBody.error) {
      throw responseBody.error;
    } else if (!res.ok) {
      throw ApiCallErrorWithoutBodyError;
    }

    return responseBody;
  } catch (error) {
    if (isAgironError(error) || isGoApproveError(error)) {
      throw error;
    }

    throw handleCallApiError(error);
  }
};

const handleCallApiError = error => {
  if (error.message.toUpperCase() === 'NETWORK REQUEST FAILED') {
    return NoConnectionError;
  } else if (error.message.toUpperCase() === 'NOT ALLOWED') {
    return NotAllowedError;
  }

  return UnknownRequestError;
};

export const setLanguage = newLanguage => {
  language = newLanguage || getLocale().toUpperCase();
};

export default callApi;
