import {
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
} from '../../_actions/authentication';

const logoutError = (state = null, action) => {
  switch (action.type) {
    case LOGOUT_REQUEST:
    case LOGOUT_SUCCESS:
      return null;
    case LOGOUT_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getLogoutError = state => state.logoutError;

export default logoutError;
