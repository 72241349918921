import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import {withTheme} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import '../../assets/styles/dotsLoading.css';

const TextMessage = withTheme()(styled(Typography)`
  && {
    margin-top: 10px;
    font-size: 22px;
    color: ${props => props.theme.palette.primary.main};
  }
`);

const LoadingDots = ({children}) => (
  <TextMessage className="loading-dots">
    {children}
    <span>.</span>
    <span>.</span>
    <span>.</span>
  </TextMessage>
);

LoadingDots.propTypes = {
  children: PropTypes.object.isRequired,
};

export default LoadingDots;
