import {
  FETCH_DOCUMENT_LIST_REQUEST,
  FETCH_DOCUMENT_LIST_SUCCESS,
  FETCH_DOCUMENT_LIST_ERROR,
  CLEAR_FETCH_DOCUMENT_LIST_ERROR,
  CLEAR_ALL_FETCH_DOCUMENT_LIST_ERRORS,
} from '../../_actions/documentList';

const fetchError = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_LIST_REQUEST:
    case FETCH_DOCUMENT_LIST_SUCCESS:
    case CLEAR_FETCH_DOCUMENT_LIST_ERROR:
      return {
        ...state,
        [action.documentTypeId]: null,
      };
    case CLEAR_ALL_FETCH_DOCUMENT_LIST_ERRORS: {
      let updatedState = state;
      const keys = Object.keys(state);
      keys.forEach(key => (updatedState[key] = null));

      return updatedState;
    }
    case FETCH_DOCUMENT_LIST_ERROR:
      return {
        ...state,
        [action.documentTypeId]: action.error,
      };
    default:
      return state;
  }
};

export const getFetchError = (state, documentTypeId) => {
  const error = state.fetchError[documentTypeId];
  if (error === undefined) {
    return null;
  }

  return error;
};

export default fetchError;
