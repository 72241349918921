import {normalize} from 'normalizr';
import {documentSummary} from '../../_actions/schema';
import * as documentSummaryApi from '../../utils/api/documentSummary';
import {getDocumentTypes} from '../../_reducers/documentType';
import {SummaryUnableToGetError} from '../../config/messageCodes';
import {parseError} from '../../utils/agiron';
import {verifyAuthSystem} from '../authentication';
import {
  getIsAuthSystemAuthenticated,
  getCheckAuthSystemError,
} from '../../_reducers/authentication';
import {getActiveOtherUser} from '../../_reducers/otherUser';
import {AppLogType} from '../../utils/appLog/types';
import {createAppLog} from '../appLog';

export const FETCH_DOCUMENT_SUMMARY_REQUEST = 'FETCH_DOCUMENT_SUMMARY_REQUEST';
export const FETCH_DOCUMENT_SUMMARY_SUCCESS = 'FETCH_DOCUMENT_SUMMARY_SUCCESS';
export const FETCH_DOCUMENT_SUMMARY_ERROR = 'FETCH_DOCUMENT_SUMMARY_ERROR';

export const fetchDocumentSummary = documentTypeId => async (
  dispatch,
  getState
) => {
  dispatch(fetchDocumentSummaryRequest(documentTypeId));
  const state = getState();
  const user = getActiveOtherUser(state);
  await verifyAuthSystem()(dispatch, getState);
  const response = await documentSummaryApi
    .fetchDocumentSummary(documentTypeId)
    .catch(error => {
      const parsedError = parseError(error, SummaryUnableToGetError);
      createAppLog({
        requestType: AppLogType.DOCUMENT_SUMMARY,
        documentTypeId,
        details: {otherActiveUser: user, error: parsedError},
      })(dispatch, getState);
      dispatch(fetchDocumentSummaryError(documentTypeId, parsedError));
    });

  if (response) {
    const normalizedData = normalize(response, documentSummary);
    dispatch(fetchDocumentSummarySuccess(documentTypeId, normalizedData));
  }
};

export const fetchAllDocumentSummaries = () => async (dispatch, getState) => {
  const state = getState();
  const documentTypes = getDocumentTypes(state);
  documentTypes.map(documentType => {
    return dispatch(fetchDocumentSummaryRequest(documentType.id));
  });
  await verifyAuthSystem()(dispatch, getState);
  const isAuthenticated = getIsAuthSystemAuthenticated(getState());
  const user = getActiveOtherUser(state);
  if (!isAuthenticated) {
    const authError = getCheckAuthSystemError(getState());
    documentTypes.map(documentType => {
      createAppLog({
        requestType: AppLogType.DOCUMENT_SUMMARY,
        documentTypeId: documentType.id,
        details: {otherActiveUser: user, error: authError},
      })(dispatch, getState);
      return dispatch(fetchDocumentSummaryError(documentType.id, authError));
    });
    return;
  }

  const fetchDocumentSummaryPromises = documentTypes.map(async ({id}) => {
    return documentSummaryApi.fetchDocumentSummary(id).catch(error => {
      if (error && error.agironCode) {
        dispatch(fetchDocumentSummaryError(id, error));
      } else {
        dispatch(
          fetchDocumentSummaryError(id, {
            ...SummaryUnableToGetError,
            data: {error: error && error.message ? error.message : error},
          })
        );
      }
    });
  });

  const responses = await Promise.all(fetchDocumentSummaryPromises);
  responses.map(response => {
    if (response) {
      const normalizedData = normalize(response, documentSummary);
      return dispatch(fetchDocumentSummarySuccess(response.id, normalizedData));
    } else {
      return null;
    }
  });
};

export const fetchDocumentSummaryRequest = id => ({
  type: FETCH_DOCUMENT_SUMMARY_REQUEST,
  id,
});

export const fetchDocumentSummarySuccess = (id, response) => ({
  type: FETCH_DOCUMENT_SUMMARY_SUCCESS,
  id,
  response,
});

export const fetchDocumentSummaryError = (id, error) => ({
  type: FETCH_DOCUMENT_SUMMARY_ERROR,
  id,
  error,
});
