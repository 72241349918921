import React from 'react';
import PropTypes from 'prop-types';
import {ToastContainer} from 'react-toastify';
import ErrorDetailDialog from './ErrorDetailDialog';

export const ToastContext = React.createContext({
  showErrorDetail: () => {},
});

class ToastProvider extends React.Component {
  state = {
    errorModalOpen: false,
    shortErrorText: '',
    error: '',
  };

  showErrorDetail = (shortErrorText, error) => {
    this.setState({
      errorModalOpen: true,
      shortErrorText,
      error,
    });
  };

  closeErrorDetail = () => {
    this.setState({
      errorModalOpen: false,
    });
  };

  render() {
    return (
      <ToastContext.Provider
        value={{
          showErrorDetail: this.showErrorDetail,
        }}
      >
        {this.props.children}
        <ToastContainer />
        {this.state.errorModalOpen && (
          <ErrorDetailDialog
            shortErrorText={this.state.shortErrorText}
            error={this.state.error}
            onClose={this.closeErrorDetail}
          />
        )}
      </ToastContext.Provider>
    );
  }
}

ToastProvider.propTypes = {
  children: PropTypes.element,
};

export default ToastProvider;
