import {combineReducers} from 'redux';
import appearance, * as fromAppearance from './appearance';
import isFetchingAppearance, * as fromIsFetchingAppearance from './isFetchingAppearance';
import fetchAppearanceError, * as fromFetchAppearanceError from './fetchAppearanceError';

const getLocalState = state => state.appearance;

export default combineReducers({
  appearance,
  isFetchingAppearance,
  fetchAppearanceError,
});

export const getAppearance = state =>
  fromAppearance.getAppearance(getLocalState(state));

export const getIsFetchingAppearance = state =>
  fromIsFetchingAppearance.getIsFetchingAppearance(getLocalState(state));

export const getFetchAppearanceError = state =>
  fromFetchAppearanceError.getFetchAppearanceError(getLocalState(state));
