import {FETCH_DOCUMENT_LIST_ERROR} from '../_actions/documentList';
import {FETCH_DOCUMENT_DETAIL_ERROR} from '../_actions/documentDetail';
import {
  FETCH_DOCUMENT_FILE_LIST_ERROR,
  FETCH_DOCUMENT_FILE_ERROR,
} from '../_actions/documentFile';
import {AUTH_SYSTEM_AUTHENTICATION_ERROR_CODE} from '../config/constants';
import {VERIFY_AUTH_SYSTEM_ERROR} from '../_actions/authentication';

export const REQUEST_AUTHENTICATION_ERROR = 'REQUEST_AUTHENTICATION_ERROR';

export const detectRequestAuthenticationError = ({
  dispatch,
}) => next => action => {
  switch (action.type) {
    case VERIFY_AUTH_SYSTEM_ERROR:
    case FETCH_DOCUMENT_LIST_ERROR:
    case FETCH_DOCUMENT_DETAIL_ERROR:
    case FETCH_DOCUMENT_FILE_LIST_ERROR:
    case FETCH_DOCUMENT_FILE_ERROR:
      if (isAuthenticationError(action.error)) {
        dispatch({
          type: REQUEST_AUTHENTICATION_ERROR,
          error: action.error,
        });
      }
      break;
    default:
      break;
  }

  next(action);
};

const isAuthenticationError = error => {
  if (!error) {
    return false;
  }

  return error.agironCode === AUTH_SYSTEM_AUTHENTICATION_ERROR_CODE;
};
