import React from 'react';
import PropTypes from 'prop-types';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import Color from 'color';
import * as appearanceReducer from '../../_reducers/appearance';
import {connect} from 'react-redux';

const BLACK = '#000000';
const WHITE = '#ffffff';

const defaults = {
  main: '#00a6a6',
};

const MThemeProvider = ({children, appearance}) => {
  const main = appearance.app_color || defaults.main;
  const color = Color(main);

  const contrastColor = color.isDark() ? WHITE : BLACK;

  const theme = createMuiTheme({
    palette: {
      goapprove: {
        primaryColor: main,
        primaryLightColor: color
          .mix(Color(WHITE), 0.85)
          .fade(0.15)
          .hex(),
        primaryDarkColor: color.mix(Color(BLACK), 0.2).hex(),
        primaryContrastColor: contrastColor,
        primaryContrastLightColor: Color(contrastColor)
          .fade(0.4)
          .string(),
        contrastText: contrastColor,
      },
      primary: {
        main,
        contrastText: contrastColor,
      },
      contrastText: contrastColor,
    },
    overrides: {
      MuiDrawer: {
        docked: {
          zIndex: 1099,
        },
        paperAnchorDockedLeft: {
          borderWidth: 0,
        },
      },
      MuiTypography: {
        body2: {
          fontWeight: 400,
        },
      },
      MuiBackdrop: {
        root: {zIndex: 1098},
      },
      MuiDialog: {
        paper: {zIndex: 1300, borderRadius: 0},
        paperScrollBody: {margin: '100px auto'},
      },
      MuiDialogContent: {
        root: {
          padding: '0 0 24px 0',
        },
      },
      MuiDialogTitle: {
        root: {
          padding: '15px 15px 15px 25px',
        },
      },
      MuiMenu: {
        paper: {zIndex: 1100},
      },
    },
    typography: {
      useNextVariants: true,
    },
  });

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

MThemeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  appearance: PropTypes.object,
};

export default connect(state => {
  return {
    appearance: appearanceReducer.getAppearance(state),
  };
}, {})(MThemeProvider);
