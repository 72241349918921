import callApi from './callApi';

export const registerDevice = async (
  os,
  browserName,
  browserVersion,
  email,
  timezone,
  ipAddress
) => {
  return await callApi('POST', '/device/register', {
    os,
    browserName,
    browserVersion,
    email,
    timezone,
    ipAddress,
  });
};

export const getDeviceStatus = async () => {
  return await callApi('GET', '/device/status');
};

export const getDevice = async () => {
  const response = await callApi('GET', '/device');
  return response.device;
};

export const updatePushToken = async token => {
  return await callApi('PUT', '/device/push_token', {token});
};

export const updateLanguage = async language => {
  const response = await callApi('PUT', '/device/language', {
    language: language.toUpperCase(),
  });
  return response.device;
};

export const updateTimezone = async timezone => {
  const response = await callApi('PUT', '/device/timezone', {
    timezone,
  });
  return response.device;
};

export const updateTimeout = async timeout => {
  const response = await callApi('PUT', '/device/timeout', {
    timeout,
  });

  return response.device;
};

export const updateGoFaceIdEnabled = async goFaceIdEnabled => {
  const response = await callApi('PUT', '/device/go_face_id_enabled', {
    go_face_id_enabled: goFaceIdEnabled,
  });
  return response.device;
};
