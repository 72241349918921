import _ from 'lodash';
import {FETCH_DOCUMENT_FILE_LIST_SUCCESS} from '../../_actions/documentFile';

const fileList = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_FILE_LIST_SUCCESS:
      return _.merge({}, state, {
        [action.documentTypeId]: {
          [action.documentId]: action.response,
        },
      });
    default:
      return state;
  }
};

export const getFileList = (state, documentTypeId, documentId) =>
  _.get(state, ['fileList', documentTypeId, documentId], []);

export default fileList;
