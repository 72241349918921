import {
  FETCH_DEVICE_STATUS_REQUEST,
  FETCH_DEVICE_STATUS_SUCCESS,
  FETCH_DEVICE_STATUS_ERROR,
} from '../../_actions/device';

const isFetchingDeviceStatus = (state = false, action) => {
  switch (action.type) {
    case FETCH_DEVICE_STATUS_REQUEST:
      return true;
    case FETCH_DEVICE_STATUS_SUCCESS:
    case FETCH_DEVICE_STATUS_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsFetchingDeviceStatus = state => state.isFetchingDeviceStatus;

export default isFetchingDeviceStatus;
