import _ from 'lodash';
import {
  FETCH_DOCUMENT_FILE_LIST_REQUEST,
  FETCH_DOCUMENT_FILE_LIST_SUCCESS,
  FETCH_DOCUMENT_FILE_LIST_ERROR,
} from '../../_actions/documentFile';

const fetchFileListError = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_FILE_LIST_REQUEST:
    case FETCH_DOCUMENT_FILE_LIST_SUCCESS:
      return _.merge({}, state, {
        [action.documentTypeId]: {
          [action.documentId]: null,
        },
      });
    case FETCH_DOCUMENT_FILE_LIST_ERROR:
      return _.merge({}, state, {
        [action.documentTypeId]: {
          [action.documentId]: action.error,
        },
      });
    default:
      return state;
  }
};

export const getFetchFileListError = (state, documentTypeId, documentId) =>
  _.get(state, ['fetchFileListError', documentTypeId, documentId], null);

export default fetchFileListError;
