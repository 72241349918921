import {
  FETCH_DOCUMENT_TYPES_REQUEST,
  FETCH_DOCUMENT_TYPES_SUCCESS,
  FETCH_DOCUMENT_TYPES_ERROR,
  FETCH_DOCUMENT_TYPE_BY_ID_REQUEST,
  FETCH_DOCUMENT_TYPE_BY_ID_SUCCESS,
  FETCH_DOCUMENT_TYPE_BY_ID_ERROR,
} from '../../_actions/documentType';

const fetchError = (state = null, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_TYPES_REQUEST:
    case FETCH_DOCUMENT_TYPES_SUCCESS:
    case FETCH_DOCUMENT_TYPE_BY_ID_REQUEST:
    case FETCH_DOCUMENT_TYPE_BY_ID_SUCCESS:
      return null;
    case FETCH_DOCUMENT_TYPES_ERROR:
    case FETCH_DOCUMENT_TYPE_BY_ID_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getFetchError = state => state.fetchError;

export default fetchError;
