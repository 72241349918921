import _ from 'lodash';
import moment from 'moment-timezone';

const supportedLanguages: Array<string> = ['en', 'es', 'pt'];

const WEEK_DAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

const getBrowserLocale = (): string => {
  let locale =
    (navigator.languages && navigator.languages[0]) || navigator.language;

  locale = locale.split(/[-_]/)[0];
  if (!_.find(supportedLanguages, l => l === locale)) {
    locale = 'en';
  }
  return locale;
};

let deviceLanguage = getBrowserLocale();

export const setDeviceLanguage = language => {
  deviceLanguage = language;
};

export const getSapTranslation = objectWithDescription => {
  const {description, name, sap_subkey, sap_action} = objectWithDescription;
  const sapFieldName = sap_subkey || name || sap_action;
  const foundlanguage = description[deviceLanguage.toUpperCase()];

  if (foundlanguage) {
    return foundlanguage.name || foundlanguage.label || sapFieldName;
  }
  for (let language in description) {
    if (description[language].label) {
      return description[language].label;
    }
    if (description[language].name) {
      return description[language].name;
    }
  }

  return sapFieldName;
};

export const getCustomFieldTranslation = customField => {
  const {description, name} = customField;
  const language = deviceLanguage.toUpperCase();

  if (description[language] && description[language].text) {
    return description[language].text;
  }
  for (let language in description) {
    if (description[language].text) {
      return description[language].text;
    }
  }
  return name;
};

const browserLocale: string = deviceLanguage;

export const getLocale = (): string => browserLocale;

export const getTimezone = () => {
  return moment.tz.guess();
};

export const getDayOfTheWeekName = () => {
  const now = moment(new Date(), getTimezone());
  const weekDayNumber = now.format('d');
  return WEEK_DAYS[weekDayNumber];
};
