import {
  DELETE_ALL_APP_LOG_REQUEST,
  DELETE_ALL_APP_LOG_SUCCESS,
  DELETE_ALL_APP_LOG_ERROR,
} from '../../_actions/appLog';

const isDeletingAllLogs = (state = false, action) => {
  switch (action.type) {
    case DELETE_ALL_APP_LOG_REQUEST:
      return true;
    case DELETE_ALL_APP_LOG_SUCCESS:
    case DELETE_ALL_APP_LOG_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsDeletingAllLogs = state => state.isDeletingAllLogs;

export default isDeletingAllLogs;
