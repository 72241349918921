import {FETCH_MICROSOFT_AUTH_SUCCESS} from '../../../_actions/microsoft/auth';

const authentication = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MICROSOFT_AUTH_SUCCESS:
      return action.response;
    default: {
      return state;
    }
  }
};

export const getMicrosoftAuth = state => state.authentication;

export default authentication;
