import _ from 'lodash';
import {
  FETCH_DOCUMENT_FILE_REQUEST,
  FETCH_DOCUMENT_FILE_SUCCESS,
  FETCH_DOCUMENT_FILE_ERROR,
} from '../../_actions/documentFile';

const fetchError = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_FILE_REQUEST:
    case FETCH_DOCUMENT_FILE_SUCCESS:
      return _.merge({}, state, {
        [action.documentTypeId]: {
          [action.documentId]: {
            [action.fileId]: null,
          },
        },
      });
    case FETCH_DOCUMENT_FILE_ERROR:
      return _.merge({}, state, {
        [action.documentTypeId]: {
          [action.documentId]: {
            [action.fileId]: action.error,
          },
        },
      });
    default:
      return state;
  }
};

export const getFetchError = (state, documentTypeId, documentId, fileId) =>
  _.get(state, ['fetchError', documentTypeId, documentId, fileId], null);

export default fetchError;
