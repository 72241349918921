import {
  FETCH_DEVICE_STATUS_REQUEST,
  FETCH_DEVICE_STATUS_SUCCESS,
  FETCH_DEVICE_STATUS_ERROR,
} from '../../_actions/device';

const fetchDeviceStatusError = (state = null, action) => {
  switch (action.type) {
    case FETCH_DEVICE_STATUS_REQUEST:
    case FETCH_DEVICE_STATUS_SUCCESS:
      return null;
    case FETCH_DEVICE_STATUS_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getFetchDeviceStatusError = state => state.fetchDeviceStatusError;

export default fetchDeviceStatusError;
