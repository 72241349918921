import {combineReducers} from 'redux';
import isFetching, * as fromIsFetching from './isFetching';
import fetchError, * as fromFetchError from './fetchError';
import byId, * as fromById from './byId';
import ids, * as fromIds from './ids';

const getLocalState = state => state.documentType;

export default combineReducers({isFetching, fetchError, byId, ids});

export const getIsFetching = state =>
  fromIsFetching.getIsFetching(getLocalState(state));

export const getFetchError = state =>
  fromFetchError.getFetchError(getLocalState(state));

export const getDocumentTypeIds = state => fromIds.getIds(getLocalState(state));

export const getDocumentType = (state, documentTypeId) =>
  fromById.getDocumentType(getLocalState(state), documentTypeId);

export const getDocumentTypes = state => {
  const localState = getLocalState(state);
  const ids = fromIds.getIds(localState);
  return ids.map(id => fromById.getDocumentType(localState, id));
};
