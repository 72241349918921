import callApi from './callApi';

export const sendTwoFaSms = async language => {
  const languageLowerCase = language.toLowerCase();
  // TODO: Remover conversão para pt-br quando o portal for consertado
  return await callApi('POST', '/twofa/send', {
    language: languageLowerCase === 'pt' ? 'pt-br' : languageLowerCase,
  });
};

export const verifyTwoFaPin = async (pinId, pin) => {
  return await callApi('POST', '/twofa/verify', {pinId, pin});
};
