/**
 * This hook is used to run a effect when a change password request is
 * successful.
 * To get access to the values it needs, a context is necessary. It retrieves
 * the isChangingPassword and changePasswordError from the reducers and shares
 * them with the hook via useContext.
 */
import {useRef, useEffect} from 'react';
import {useEffectSkipRender} from './useEffectSkipRender';
import {useChangePassContext} from '../context/changePassContext';

/**
 * Runs an effect when a successful request to change the password is
 * detected.
 * @param {function} onSuccess
 */
export const useOnChangePasswordSuccess = onSuccess => {
  const {isChangingPassword, changePasswordError} = useChangePassContext();

  // Because the check to determine if a change password request was
  // successful, is more complex than comparing if two values are equal,
  // there needs to have something else to trigger the effect when this
  // condition is met.
  // This is where count is used, it is a simple number that will be increased
  // by 1 every time the condition is met. And thus causing the change and
  // triggerig the effect.
  let ref = useRef({
    count: 0,
    isChangingPassword,
    changePasswordError,
  });

  let count = ref.current.count;

  // Checking if a change password request was successful
  if (
    ref.current.isChangingPassword &&
    !isChangingPassword &&
    !changePasswordError
  ) {
    count++;
  }

  // Effect that updates the ref values
  useEffect(() => {
    ref.current = {
      ...ref.current,
      count,
      isChangingPassword,
      changePasswordError,
    };
  });

  // Main effect
  useEffectSkipRender(onSuccess, [count]);
};
