import {combineReducers} from 'redux';
import isFetchingMicrosoftSystem, * as fromIsFetchingMicrosoftSystem from './isFetchingMicrosoftSystem';
import system, * as fromMicrosoftSystem from './microsoftSystem';
import fetchMicrosoftSystemError, * as fromFetchMicrosoftSystemError from './fetchMicrosoftSystemError';

const getLocalState = state => state.microsoftSystem;

export default combineReducers({
  isFetchingMicrosoftSystem,
  system,
  fetchMicrosoftSystemError,
});

export const getIsFetchingMicrosoftSystem = state =>
  fromIsFetchingMicrosoftSystem.getIsFetchingMicrosoftSystem(
    getLocalState(state)
  );

export const getMicrosoftSystem = state =>
  fromMicrosoftSystem.getMicrosoftSystem(getLocalState(state));

export const getFetchMicrosoftSystemError = state =>
  fromFetchMicrosoftSystemError.getFetchMicrosoftSystemError(
    getLocalState(state)
  );
