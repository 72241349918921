import {
  PROCESS_DOCUMENTS_REQUEST,
  SET_PROCESS_DOCUMENTS_ERROR_COUNT,
} from '../../_actions/processDocument';

const processErrorCount = (state = {}, action) => {
  switch (action.type) {
    case PROCESS_DOCUMENTS_REQUEST:
      return {
        ...state,
        [action.documentTypeId]: 0,
      };
    case SET_PROCESS_DOCUMENTS_ERROR_COUNT:
      return {
        ...state,
        [action.documentTypeId]: action.errorCount,
      };
    default:
      return state;
  }
};

export const getProcessErrorCount = (state, documentTypeId) =>
  state.processErrorCount[documentTypeId] || 0;

export default processErrorCount;
