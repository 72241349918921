import callApi from './callApi';

export const postAuthentication = async (password, system_id) => {
  return await callApi('POST', '/authentication', {
    password,
    system_id,
  });
};

export const checkAuthentication = async () => {
  return await callApi('GET', '/authentication/status');
};

export const getLogout = async () => {
  return await callApi('GET', '/logout');
};

export const changePassForSystem = async (systemId, password) => {
  return await callApi('PUT', `/authentication/pass_for_system/${systemId}`, {
    password,
  });
};

export const verifyAuthSystem = async () => {
  return await callApi('GET', '/authentication/verifyAuthSystem');
};

export const postVerifyAuthSystem = async (password, documentTypeId) => {
  return await callApi('POST', '/authentication/verifyAuthSystem', {
    _password: password,
    document_type_id: documentTypeId,
  });
};
