import {
  FETCH_DOCUMENT_TYPES_SUCCESS,
  FETCH_DOCUMENT_TYPE_BY_ID_SUCCESS,
} from '../../_actions/documentType';

const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_TYPES_SUCCESS:
    case FETCH_DOCUMENT_TYPE_BY_ID_SUCCESS:
      return {...state, ...action.response.entities.documentType};
    default:
      return state;
  }
};

export const getDocumentType = (state, documentTypeId) => {
  if (documentTypeId) {
    return state.byId[documentTypeId];
  }
};

export default byId;
