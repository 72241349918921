import * as AppLogApi from '../../utils/api/appLog';

export const CREATE_APP_LOG_REQUEST = 'CREATE_APP_LOG_REQUEST';
export const CREATE_APP_LOG_SUCCESS = 'CREATE_APP_LOG_SUCCESS';
export const CREATE_APP_LOG_ERROR = 'CREATE_APP_LOG_ERROR';
export const FETCH_APP_LOG_REQUEST = 'FETCH_APP_LOG_REQUEST';
export const FETCH_APP_LOG_SUCCESS = 'FETCH_APP_LOG_SUCCESS';
export const FETCH_APP_LOG_ERROR = 'FETCH_APP_LOG_ERROR';
export const DELETE_APP_LOG_REQUEST = 'DELETE_APP_LOG_REQUEST';
export const DELETE_APP_LOG_SUCCESS = 'DELETE_APP_LOG_SUCCESS';
export const DELETE_APP_LOG_ERROR = 'DELETE_APP_LOG_ERROR';
export const DELETE_ALL_APP_LOG_REQUEST = 'DELETE_ALL_APP_LOG_REQUEST';
export const DELETE_ALL_APP_LOG_SUCCESS = 'DELETE_ALL_APP_LOG_SUCCESS';
export const DELETE_ALL_APP_LOG_ERROR = 'DELETE_ALL_APP_LOG_ERROR';

export const createAppLog = app_log => async dispatch => {
  dispatch(createAppLogRequest());
  const response = await AppLogApi.createAppLog(app_log).catch(error => {
    dispatch(createAppLogError(error));
  });
  if (response) {
    dispatch(createAppLogSuccess(response));
  }
};

export const fetchAppLogs = () => async dispatch => {
  dispatch(fetchAppLogRequest());
  const response = await AppLogApi.getAllAppLogByUser().catch(error => {
    dispatch(fetchAppLogError(error));
  });
  if (response) {
    dispatch(fetchAppLogSuccess(response));
  }
};

export const deleteAppLog = appLogId => async dispatch => {
  dispatch(deleteAppLogRequest());
  const response = await AppLogApi.deleteAppLog(appLogId).catch(error => {
    dispatch(deleteAppLogError(appLogId, error));
  });
  if (response) {
    dispatch(deleteAppLogSuccess(appLogId, response));
  }
};

export const deleteAllAppLog = appLogsIds => async dispatch => {
  dispatch(deleteAllAppLogRequest());
  const response = await AppLogApi.deleteAllAppLogs(appLogsIds).catch(error => {
    dispatch(deleteAllAppLogError(appLogsIds, error));
  });
  if (response) {
    dispatch(deleteAllAppLogSuccess(appLogsIds, response));
  }
};

export const createAppLogRequest = () => ({
  type: CREATE_APP_LOG_REQUEST,
});

export const createAppLogSuccess = response => ({
  type: CREATE_APP_LOG_SUCCESS,
  response,
});

export const createAppLogError = error => ({
  type: CREATE_APP_LOG_ERROR,
  error,
});

export const fetchAppLogRequest = () => ({
  type: FETCH_APP_LOG_REQUEST,
});

export const fetchAppLogSuccess = response => ({
  type: FETCH_APP_LOG_SUCCESS,
  response,
});

export const fetchAppLogError = error => ({
  type: FETCH_APP_LOG_ERROR,
  error,
});

export const deleteAppLogRequest = () => ({
  type: DELETE_APP_LOG_REQUEST,
});

export const deleteAppLogSuccess = (appLogId, response) => ({
  type: DELETE_APP_LOG_SUCCESS,
  appLogId,
  response,
});

export const deleteAppLogError = (appLogId, error) => ({
  type: DELETE_APP_LOG_ERROR,
  appLogId,
  error,
});

export const deleteAllAppLogRequest = () => ({
  type: DELETE_ALL_APP_LOG_REQUEST,
});

export const deleteAllAppLogSuccess = (appLogId, response) => ({
  type: DELETE_ALL_APP_LOG_SUCCESS,
  appLogId,
  response,
});

export const deleteAllAppLogError = (appLogId, error) => ({
  type: DELETE_ALL_APP_LOG_ERROR,
  appLogId,
  error,
});
