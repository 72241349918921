import {
  FETCH_DEVICE_REQUEST,
  FETCH_DEVICE_SUCCESS,
  FETCH_DEVICE_ERROR,
} from '../../_actions/device';

const isFetchingDevice = (state = false, action) => {
  switch (action.type) {
    case FETCH_DEVICE_REQUEST:
      return true;
    case FETCH_DEVICE_SUCCESS:
    case FETCH_DEVICE_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsFetchingDevice = state => state.isFetchingDevice;

export default isFetchingDevice;
