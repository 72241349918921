import * as authenticationApi from '../../utils/api/authentication';
import {checkTokenMicrosoftAuth} from '../microsoft/auth';
import {getUserAssign} from '../../_reducers/userAssign';
import {getMicrosoftSystem} from '../../_reducers/microsoft/system';
import {fetchMicrosoftSystemById} from '../microsoft/system';

export const AUTHENTICATE_USER_REQUEST = 'AUTHENTICATE_USER_REQUEST';
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';
export const AUTHENTICATE_USER_ERROR = 'AUTHENTICATE_USER_ERROR';
export const CHECK_AUTHENTICATION_REQUEST = 'CHECK_AUTHENTICATION_REQUEST';
export const CHECK_AUTHENTICATION_SUCCESS = 'CHECK_AUTHENTICATION_SUCCESS';
export const CHECK_AUTHENTICATION_ERROR = 'CHECK_AUTHENTICATION_ERROR';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const CHANGE_PASS_FOR_SYSTEM_REQUEST = 'CHANGE_PASS_FOR_SYSTEM_REQUEST';
export const CHANGE_PASS_FOR_SYSTEM_SUCCESS = 'CHANGE_PASS_FOR_SYSTEM_SUCCESS';
export const CHANGE_PASS_FOR_SYSTEM_ERROR = 'CHANGE_PASS_FOR_SYSTEM_ERROR';
export const CLEAR_CHANGE_PASS_FOR_SYSTEM_ERROR =
  'CLEAR_CHANGE_PASS_FOR_SYSTEM_ERROR';
export const VERIFY_AUTH_SYSTEM_REQUEST = 'VERIFY_AUTH_SYSTEM_REQUEST';
export const VERIFY_AUTH_SYSTEM_SUCCESS = 'VERIFY_AUTH_SYSTEM_SUCCESS';
export const VERIFY_AUTH_SYSTEM_ERROR = 'VERIFY_AUTH_SYSTEM_ERROR';

export const authenticateUser = (password, systemId) => async dispatch => {
  dispatch(authenticateUserRequest());
  const response = await authenticationApi
    .postAuthentication(password, systemId)
    .catch(error => {
      dispatch(authenticateUserError(error));
    });

  if (response) {
    dispatch(authenticateUserSuccess(response));
  }
};

export const checkAuthentication = () => async dispatch => {
  dispatch(checkAuthenticationRequest());
  const response = await authenticationApi
    .checkAuthentication()
    .catch(error => {
      dispatch(checkAuthenticationError(error));
    });

  if (response) {
    dispatch(checkAuthenticationSuccess(response));
  }
};

export const logout = () => async dispatch => {
  dispatch(logoutRequest());

  const response = await authenticationApi.getLogout().catch(error => {
    dispatch(logoutError(error));
  });

  if (response) {
    dispatch(logoutSuccess());
  }
};

export const changePassForSystem = (systemId, password) => async dispatch => {
  dispatch(changePassForSystemRequest(systemId));
  const response = await authenticationApi
    .changePassForSystem(systemId, password)
    .catch(error => {
      dispatch(changePassForSystemError(systemId, error));
    });

  if (response) {
    dispatch(changePassForSystemSuccess(systemId));
  }
};

export const verifyAuthSystem = () => async (dispatch, getState) => {
  dispatch(verifyAuthSystemRequest());
  const state = getState();
  const userAssign = getUserAssign(state);
  const microsoftAuth = await hasMicrosoftAuth(dispatch, getState);

  if (microsoftAuth) {
    await fetchMicrosoftSystemById(userAssign.auth_system)(dispatch);
    await refreshTokenMicrosoftAuth(dispatch, getState);
  } else {
    const response = await authenticationApi.verifyAuthSystem().catch(error => {
      dispatch(verifyAuthSystemError(error));
    });

    if (response) {
      dispatch(verifyAuthSystemSuccess(response));
    }
  }
};

export const verifyAuthSystemWithPass = (password, documentTypeId) => async (
  dispatch,
  getState
) => {
  dispatch(verifyAuthSystemRequest());

  const microsoftAuth = await hasMicrosoftAuth(dispatch, getState);

  if (microsoftAuth) {
    await refreshTokenMicrosoftAuth(dispatch, getState);
  } else {
    const response = await authenticationApi
      .postVerifyAuthSystem(password, documentTypeId)
      .catch(error => {
        dispatch(verifyAuthSystemError(error));
      });

    if (response) {
      dispatch(verifyAuthSystemSuccess(response));
    }
  }
};

export const authenticateUserRequest = () => ({
  type: AUTHENTICATE_USER_REQUEST,
});

export const authenticateUserSuccess = response => ({
  type: AUTHENTICATE_USER_SUCCESS,
  response,
});

export const authenticateUserError = error => ({
  type: AUTHENTICATE_USER_ERROR,
  error,
});

export const checkAuthenticationRequest = () => ({
  type: CHECK_AUTHENTICATION_REQUEST,
});

export const checkAuthenticationSuccess = response => ({
  type: CHECK_AUTHENTICATION_SUCCESS,
  response,
});

export const checkAuthenticationError = error => ({
  type: CHECK_AUTHENTICATION_ERROR,
  error,
});

export const logoutRequest = () => ({
  type: LOGOUT_REQUEST,
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const logoutError = error => ({
  type: LOGOUT_ERROR,
  error,
});

export const changePassForSystemRequest = systemId => ({
  type: CHANGE_PASS_FOR_SYSTEM_REQUEST,
  systemId,
});

export const changePassForSystemSuccess = systemId => ({
  type: CHANGE_PASS_FOR_SYSTEM_SUCCESS,
  systemId,
});

export const changePassForSystemError = (systemId, error) => ({
  type: CHANGE_PASS_FOR_SYSTEM_ERROR,
  systemId,
  error,
});

export const clearChangePassForSystemError = () => ({
  type: CLEAR_CHANGE_PASS_FOR_SYSTEM_ERROR,
});

export const verifyAuthSystemRequest = () => ({
  type: VERIFY_AUTH_SYSTEM_REQUEST,
});

export const verifyAuthSystemSuccess = response => ({
  type: VERIFY_AUTH_SYSTEM_SUCCESS,
  response,
});

export const verifyAuthSystemError = error => ({
  type: VERIFY_AUTH_SYSTEM_ERROR,
  error,
});

const hasMicrosoftAuth = async (dispatch, getState) => {
  const state = getState();
  const userAssign = getUserAssign(state);
  return (
    userAssign && userAssign.auth_system_type.toUpperCase() === 'MICROSOFT'
  );
};

const refreshTokenMicrosoftAuth = async (dispatch, getState) => {
  const state = getState();

  const {
    authSystemUserName,
    user: {microsoft_id},
  } = await getUserAssign(state);
  const {tenant, client_id} = await getMicrosoftSystem(state);

  await checkTokenMicrosoftAuth(
    microsoft_id,
    authSystemUserName,
    tenant,
    client_id
  )(dispatch);
};
