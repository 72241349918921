import {
  FETCH_DEVICE_SUCCESS,
  UPDATE_DEVICE_TIMEOUT_SUCCESS,
} from '../_actions/device';
import {setTimeoutTime} from '../utils/timeoutFetch';

export const setTimeoutMiddleware = () => next => action => {
  if (
    action.type === FETCH_DEVICE_SUCCESS ||
    action.type === UPDATE_DEVICE_TIMEOUT_SUCCESS
  ) {
    const timeout = action.response.timeout;
    setTimeoutTime(timeout * 1000);
  }

  next(action);
};
