import {
  REGISTER_DEVICE_REQUEST,
  REGISTER_DEVICE_SUCCESS,
  REGISTER_DEVICE_ERROR,
} from '../../_actions/device';

const registerError = (state = null, action) => {
  switch (action.type) {
    case REGISTER_DEVICE_REQUEST:
    case REGISTER_DEVICE_SUCCESS:
      return null;
    case REGISTER_DEVICE_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getRegisterError = state => state.registerError;

export default registerError;
