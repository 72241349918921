import {
  FETCH_APPEARANCE_REQUEST,
  FETCH_APPEARANCE_SUCCESS,
  FETCH_APPEARANCE_ERROR,
} from '../../_actions/appearance';

const fetchAppearanceError = (state = null, action) => {
  switch (action.type) {
    case FETCH_APPEARANCE_REQUEST:
    case FETCH_APPEARANCE_SUCCESS:
      return null;
    case FETCH_APPEARANCE_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getFetchAppearanceError = state => state.fetchAppearanceError;

export default fetchAppearanceError;
