import {
  FETCH_USER_ASSIGN_SUCCESS,
  CREATE_FILTER_SUCCESS,
  ACTIVE_FILTER_REQUEST,
  ACTIVE_FILTER_SUCCESS,
  ACTIVE_FILTER_ERROR,
  UPDATE_FILTER_REQUEST,
  UPDATE_FILTER_SUCCESS,
  UPDATE_FILTER_ERROR,
  DELETE_FILTER_REQUEST,
  DELETE_FILTER_SUCCESS,
  DELETE_FILTER_ERROR,
  UNACTIVATE_FILTER_SUCCESS,
} from '../../_actions/userAssign';

const userAssign = (state = null, action) => {
  switch (action.type) {
    case FETCH_USER_ASSIGN_SUCCESS:
      return action.response.user_assign;
    case ACTIVE_FILTER_REQUEST:
    case DELETE_FILTER_REQUEST:
    case UPDATE_FILTER_REQUEST:
      return {
        ...state,
        filters: setFilterIsProcessing(action.filterId, state.filters),
      };
    case CREATE_FILTER_SUCCESS:
    case ACTIVE_FILTER_SUCCESS:
    case UPDATE_FILTER_SUCCESS:
    case UNACTIVATE_FILTER_SUCCESS:
      return {...state, filters: action.response};
    case DELETE_FILTER_SUCCESS:
      return {
        ...state,
        filters: state.filters.filter(filter => filter._id !== action.filterId),
      };
    case DELETE_FILTER_ERROR:
    case ACTIVE_FILTER_ERROR:
    case UPDATE_FILTER_ERROR:
      return {
        ...state,
        filters: setFilterError(action.filterId, state.filters, action.error),
      };
    default:
      return state;
  }
};

const setFilterIsProcessing = (filterId, filters) =>
  filters.map(filter => {
    if (filter._id === filterId) {
      return {...filter, isProcessing: true, error: null};
    } else {
      return {...filter, isProcessing: null, error: null};
    }
  });

const setFilterError = (filterId, filters, error) =>
  filters.map(filter => {
    if (filter._id === filterId) {
      return {...filter, isProcessing: null, error};
    } else {
      return {...filter, error: null};
    }
  });

export const getUserAssign = state => state.userAssign;

export default userAssign;
