import {
  FETCH_DOCUMENT_SUMMARY_REQUEST,
  FETCH_DOCUMENT_SUMMARY_SUCCESS,
  FETCH_DOCUMENT_SUMMARY_ERROR,
} from '../../_actions/documentSummary';

const isFetching = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_SUMMARY_REQUEST:
      return {
        ...state,
        [action.id]: true,
      };
    case FETCH_DOCUMENT_SUMMARY_SUCCESS:
    case FETCH_DOCUMENT_SUMMARY_ERROR:
      return {
        ...state,
        [action.id]: false,
      };
    default:
      return state;
  }
};

export const getIsFetching = (state, id) => {
  const fetching = state.isFetching[id];
  if (isFetching === undefined) {
    return false;
  }

  return fetching;
};

export default isFetching;
