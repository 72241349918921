import {combineReducers} from 'redux';
import byUser, * as fromByUser from './byUser';
import isCreating, * as fromIsCreating from './isCreating';
import isFetching, * as fromIsFetching from './isFetching';
import isDeleting, * as fromIsDeleting from './isDeleting';
import createError, * as fromCreateError from './createError';
import fetchError, * as fromFetchError from './fetchError';
import deleteError, * as fromDeleteError from './deleteError';
import isDeletingAllLogs, * as fromIsDeletingAllLogs from './isDeletingAllLogs';
import deleteAllLogsError, * as fromDeleteAllLogsError from './deleteAllLogsError';

const getLocalState = state => state.appLog;

export default combineReducers({
  byUser,
  isCreating,
  isFetching,
  isDeleting,
  createError,
  fetchError,
  deleteError,
  isDeletingAllLogs,
  deleteAllLogsError,
});

export const getAppLogByUser = state =>
  fromByUser.getAppLogByUser(getLocalState(state));

export const getIsCreating = state =>
  fromIsCreating.getIsCreating(getLocalState(state));

export const getCreateError = state =>
  fromCreateError.getCreateError(getLocalState(state));

export const getIsFetching = state =>
  fromIsFetching.getIsFetching(getLocalState(state));

export const getFetchError = state =>
  fromFetchError.getFetchError(getLocalState(state));

export const getIsDeleting = state =>
  fromIsDeleting.getIsDeleting(getLocalState(state));

export const getDeleteError = state =>
  fromDeleteError.getDeleteError(getLocalState(state));

export const getIsDeletingAllLogs = state =>
  fromIsDeletingAllLogs.getIsDeletingAllLogs(getLocalState(state));

export const getDeleteAllLogsError = state =>
  fromDeleteAllLogsError.getDeleteAllLogsError(getLocalState(state));
