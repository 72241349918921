import callApi from './callApi';

export const fetchDocumentSummary = async (documentTypeId, user) => {
  const response = await callApi(
    'GET',
    `/document_types/${documentTypeId}/summary`,
    undefined,
    user
  );

  return response.summary;
};
