import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import MaterialCloseIcon from '@material-ui/icons/Close';
import {withTheme} from '@material-ui/core/styles';

const Container = styled.div`
  && {
    background-color: ${props => props.color};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
    padding: 5px 20px;
  }
`;

const CloseIcon = styled(MaterialCloseIcon)`
  color: ${props => props.icon_color};
`;

const DialogTitleContainer = ({className, children, theme, onClose}) => (
  <Container className={className} color={theme.palette.goapprove.primaryColor}>
    {children}
    {onClose && (
      <IconButton onClick={onClose}>
        <CloseIcon icon_color={theme.palette.goapprove.primaryContrastColor} />
      </IconButton>
    )}
  </Container>
);

DialogTitleContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  theme: PropTypes.object,
  onClose: PropTypes.func,
};

export default withTheme()(DialogTitleContainer);
