import {
  SET_ACTIVE_OTHER_USER_REQUEST,
  SET_ACTIVE_OTHER_USER_SUCCESS,
  SET_ACTIVE_OTHER_USER_ERROR,
} from '../../_actions/otherUser';

const activateOtherUserError = (state = null, action) => {
  switch (action.type) {
    case SET_ACTIVE_OTHER_USER_REQUEST:
    case SET_ACTIVE_OTHER_USER_SUCCESS:
      return null;
    case SET_ACTIVE_OTHER_USER_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getActivateOtherUserError = state => state.activateOtherUserError;

export default activateOtherUserError;
