import _ from 'lodash';
import {
  DOCUMENT_TEMPLATE_TYPE,
  MOBILE_TEMPLATE_TYPE,
} from '../config/constants';
import {CURRENCY_CODES} from '../config/currencyCodes';
import unorm from 'unorm';

export const parseStructureLayout = layout => {
  return layout.map(l => {
    return l.fields;
  });
};

export const parseStructureFields = fields => {
  const withParsedDesc = fields.map(f => ({
    ...f,
    description: _.keyBy(f.description, 'language'),
  }));
  return _.keyBy(withParsedDesc, 'name');
};

export const parseStructureCustomFields = customFields => {
  const withParsedDesc = customFields.map(f => ({
    ...f,
    description: _.keyBy(f.description, 'language'),
  }));
  return _.keyBy(withParsedDesc, 'name');
};

export const parseTemplate = template => {
  if (!_.isObjectLike(template)) {
    return template;
  }

  return {
    ...template,
    structures: template.structures.map(s => {
      return {
        ...s,
        description: _.keyBy(s.description, 'language'),
        layout: parseStructureLayout(s.layout),
        fields: parseStructureFields(s.fields),
        custom_fields: parseStructureCustomFields(s.custom_fields),
      };
    }),
  };
};

export const getLabelStyle = fieldStyle => {
  return {
    fontSize: fieldStyle.font_size || undefined,
    color: fieldStyle.label_color || undefined,
    fontWeight: fieldStyle.label_font_weight || undefined,
  };
};

export const getValueStyle = fieldStyle => {
  return {
    fontSize: fieldStyle.font_size || undefined,
    color: fieldStyle.value_color || undefined,
    fontWeight: fieldStyle.value_font_weight || undefined,
  };
};

export const parseDocumentTypeTemplates = documentType => {
  const templateField =
    DOCUMENT_TEMPLATE_TYPE === MOBILE_TEMPLATE_TYPE
      ? 'mobile_template'
      : 'web_template';

  const docCopy = Object.assign({}, documentType);

  docCopy.description = _.keyBy(docCopy.description, 'language');
  docCopy.summary.template = parseTemplate(docCopy.summary[templateField]);
  docCopy.document.template = parseTemplate(docCopy.document[templateField]);
  docCopy.list.template = parseTemplate(docCopy.list[templateField]);
  docCopy.action.actions = _.reverse(docCopy.action.actions).map(action => {
    return {
      ...action,
      description: _.keyBy(action.description, 'language'),
      template: parseTemplate(action[templateField]),
    };
  });

  return docCopy;
};

export const getListFieldsFromDocumentType = documentType =>
  documentType.list.template.structures[0].fields;

export const getLayoutFromDocumentType = documentType =>
  documentType.list.template.structures[0].layout;

export const getGroupFieldFromDocumentType = documentType =>
  documentType.list.template.structures[0].group_field;

export const getSortField = documentType => {
  const fields = _.toArray(getListFieldsFromDocumentType(documentType));
  const sortField = fields.find(field => field.sort_direction);
  if (sortField) {
    return sortField;
  }

  return null;
};

export const getFieldByName = (documentType, fieldName) => {
  const fields = _.toArray(getListFieldsFromDocumentType(documentType));
  const field = fields.find(field => field.name === fieldName);
  if (field) {
    return field;
  }

  return null;
};

export const getOrderedKeyFields = documentType => {
  const fields = getListFieldsFromDocumentType(documentType);
  const keyFields = Object.keys(fields)
    .map(key => fields[key])
    .filter(field => field.is_key)
    .sort((a, b) => parseInt(a.key_order, 10) - parseInt(b.key_order, 10))
    .map(field => field.name);

  return keyFields;
};

const getIdFieldValue = keyFields => document => {
  return keyFields
    .reduce((acc, keyField) => {
      acc.push(document[keyField]);
      return acc;
    }, [])
    .join('|');
};

export const parseDocumentList = (documentList, documentType) => {
  if (!documentType.list || !documentType.list.template) {
    return [];
  }
  const keyFields = getOrderedKeyFields(documentType);

  return _(documentList)
    .map(document => ({
      ...document,
      __id: getIdFieldValue(keyFields)(document),
    }))
    .value();
};

export const addIdFieldToDocumentDetail = (documentDetail, id) => {
  return Object.assign({}, documentDetail, {__id: id});
};

export const getDocumentActionBySapCode = (documentType, sapCode) => {
  return documentType.action.actions.find(
    action => action.sap_action === sapCode
  );
};

export const getFileIdField = fields => _.findKey(fields, {is_file_id: true});
export const getFileNameField = fields =>
  _.findKey(fields, {is_file_name: true});
export const getFileExtensionField = fields =>
  _.findKey(fields, {is_file_extension: true});

export const makeCheckTemplateErrors = options => documentType => {
  if (!documentType) {
    return null;
  }
  if (!documentType[options.type] || !documentType[options.type].template) {
    throw options.templateNotFoundError;
  }

  const template = documentType[options.type].template;
  if (!Array.isArray(template.structures) || template.structures.length === 0) {
    throw options.templateHasNoStructureError;
  }

  if (options.type === 'action') {
    const fields = template.structures[0].fields;
    const hasActionKey = Object.keys(fields).find(
      field => fields[field].is_key
    );
    if (!fields || !hasActionKey) {
      throw options.templateHasNoKey;
    }
  }

  if (
    !Array.isArray(template.structures[0].layout) ||
    template.structures[0].layout.length === 0
  ) {
    throw options.templateHasNoLayoutError;
  }
};

export const getDocumentsBySearch = (
  documentList,
  documentType,
  searchText,
  intl
) => {
  if (!searchText) {
    return documentList;
  }
  const fieldsOnLayout = getFieldsOnLayout(documentType);
  const fieldsList = getListFieldsFromDocumentType(documentType);

  var searchedDocuments = documentList.filter(document => {
    return Object.keys(document).some(field => {
      let fieldValue = document[field].toString();
      fieldValue = convertFieldValue(fieldValue, fieldsList[field], intl);

      // Remover acentos e manter textos em minusculo
      const normalizedFieldValue = unorm
        .nfd(fieldValue)
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

      const normalizedSearchText = unorm
        .nfd(searchText)
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

      return (
        normalizedFieldValue
          .toLowerCase()
          .indexOf(normalizedSearchText.toLowerCase()) !== -1 &&
        _.includes(fieldsOnLayout, field)
      );
    });
  });
  return searchedDocuments;
};

const getFieldsOnLayout = documentType => {
  const fieldsOnLayout = [];
  const layout = getLayoutFromDocumentType(documentType);
  const groupByField = getGroupFieldFromDocumentType(documentType);

  if (groupByField) {
    fieldsOnLayout.push(groupByField.field);
  }

  layout.map(line => line.map(field => fieldsOnLayout.push(field.field)));

  return fieldsOnLayout;
};

const convertFieldValue = (value, field, intl) => {
  if (!field) {
    return value;
  }
  if (!value) {
    return '';
  }

  switch (field.type) {
    case 'date':
      return intl.formatDate(value, {
        ...getDatePropertiesFromField(field),
        timeZone: 'UTC',
      });
    case 'float':
      return intl.formatNumber(value, {
        style: 'decimal',
      });
    case 'currency':
      return (
        (CURRENCY_CODES[value] && CURRENCY_CODES[value].symbol_native) || value
      );
    default:
      return value;
  }
};

export const getDatePropertiesFromField = field =>
  _.pickBy(field.date_format, item => item !== 'none');

export const getFilteredDocuments = (documents, filters, documentType) => {
  const activeFilters = getActiveFilters(filters, documentType);
  if (activeFilters.length === 0) {
    return documents;
  } else {
    return documents.filter(document =>
      isDocumentValidByFilter(document, activeFilters)
    );
  }
};

const isDocumentValidByFilter = (document, filters) =>
  filters.filter(filter =>
    matchRuleFilters(filter.value, document[filter.field])
  ).length === filters.length;

const matchRuleFilters = (filterValue, docValue) => {
  // Remover acentos e manter textos em minusculo
  const normalizedFilterValue = unorm
    .nfd(filterValue)
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  const normalizedDocValue = unorm
    .nfd(docValue)
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

  // Validação o documento é valido
  return normalizedDocValue.indexOf(normalizedFilterValue) !== -1;
};

const getActiveFilters = (filters, documentType) =>
  filters.filter(
    filter => filter.active && filterFieldExistInTemplate(filter, documentType)
  );

const filterFieldExistInTemplate = (filter, documentType) => {
  const fields = getListFieldsFromDocumentType(documentType);
  return fields.hasOwnProperty(filter.field);
};
