import {combineReducers} from 'redux';
import userAssign, * as fromUserAssign from './userAssign';
import userInfo, * as fromUserInfo from './userInfo';
import userGroupConfigs, * as fromUserGroupConfigs from './userGroupConfigs';
import isFetchingUserAssign, * as fromIsFetchingUserAssign from './isFetchingUserAssign';
import fetchUserAssignError, * as fromFetchUserAssignError from './fetchUserAssignError';
import isUnactivatingFilters, * as fromIsUnactivatingFilters from './isUnactivatingFilters';
import isCreatingFilter, * as fromIsCreatingFilter from './isCreatingFilter';
import createFilterError, * as fromCreateFilterError from './createFilterError';

const getLocalState = state => state.userAssign;

export default combineReducers({
  userAssign,
  userInfo,
  isFetchingUserAssign,
  fetchUserAssignError,
  userGroupConfigs,
  isCreatingFilter,
  createFilterError,
  isUnactivatingFilters,
});

export const getUserAssign = state =>
  fromUserAssign.getUserAssign(getLocalState(state));

export const getUserInfo = state =>
  fromUserInfo.getUserInfo(getLocalState(state));

export const getUserGroupConfigs = state =>
  fromUserGroupConfigs.getUserGroupConfigs(getLocalState(state));

export const getIsFetchingUserAssign = state =>
  fromIsFetchingUserAssign.getIsFetchingUserAssign(getLocalState(state));

export const getFetchUserAssignError = state =>
  fromFetchUserAssignError.getFetchUserAssignError(getLocalState(state));

export const getIsCreatingFilter = state =>
  fromIsCreatingFilter.getIsCreatingFilter(getLocalState(state));

export const getIsUnactivatingFilters = state =>
  fromIsUnactivatingFilters.getIsUnactivatingFilters(getLocalState(state));

export const getCreateFilterError = state =>
  fromCreateFilterError.getCreateFilterError(getLocalState(state));

export const getFilterById = (state, filterId) => {
  const userAssign = fromUserAssign.getUserAssign(getLocalState(state));
  const filters =
    userAssign && Array.isArray(userAssign.filters) ? userAssign.filters : [];
  return filters.find(filter => filter._id === filterId);
};

export const getFiltersByDocumentType = (state, documentTypeId) => {
  const userAssign = fromUserAssign.getUserAssign(getLocalState(state));
  if (!userAssign) return [];
  const filters = Array.isArray(userAssign.filters) ? userAssign.filters : [];
  return filters.filter(filter => filter.document_type === documentTypeId);
};
