import {
  SummaryTemplateError,
  SummaryTemplateRenderError,
  SummaryTemplateNotFoundError,
  SummaryTemplateHasNoStructureError,
  SummaryTemplateHasNoLayoutError,
  ListTemplateError,
  ListTemplateRenderError,
  ListTemplateNotFoundError,
  ListTemplateHasNoStructureError,
  ListTemplateHasNoLayoutError,
  DocumentTypesUnableToGetError,
  SummaryUnableToGetError,
  DocumentListUnableToGetError,
  NoConnectionError,
  DocumentDetailUnableToGetError,
  DocumentFileListUnableToGetError,
  DocumentFileUnableToGetError,
  DetailTemplateNotFoundError,
  DetailTemplateHasNoStructureError,
  DetailDetailTabTemplateRenderError,
  DetailDetailTabTemplateStructureHasNoLayout,
  DetailListTabTemplateRenderError,
  DetailListTabTemplateStructureHasNoLayout,
  DetailFileTemplateRenderError,
  DetailFileTemplateStructureHasNoLayout,
  ActionTemplateRenderError,
  ActionTemplateNotFoundError,
  ActionTemplateHasNoStructureError,
  ActionTemplateHasNoLayoutError,
  ActionTemplateHasNoKeyError,
  DetailTemplateTypeNotFoundError,
  OtherUserNoPermission,
  MicrosoftAuthenticationError,
  MicrosoftAuthenticationNotPermission,
  MicrosoftAuthenticationEmailNotMatch,
  MicrosoftAuthenticationPopupError,
  MicrosoftAuthenticationUserCancelledPopup,
  TimeoutError,
  UnknownRequestError,
} from '../../config/messageCodes';

const pt = {
  authentication: {
    enterEmail: 'Digite o seu e-mail',
    enterPinCode: 'Digite o código',
    nextStep: 'Próximo',
    userGreeting: 'Olá, {name}!',
    sendingSms: 'Enviando SMS',
    smsSent:
      'Um SMS com um código de 6 dígitos foi enviado para o número ******-****{phone}.',
    verifyingPin: 'Verificando PIN',
    incorrectPin: 'PIN incorreto',
    enterPassword: 'Digite a sua senha',
    showPassword: 'Mostrar senha',
    hidePassword: 'Esconder senha',
    resendSms: 'Não recebeu o código?',
    resendSmsButton: 'Reenviar',
    invalidAuthentication: 'Autenticação inválida',
  },
  changePassword: {
    updateYourPassword: 'Atualize sua Senha',
    authPassword: 'Senha de Autenticação',
    save: 'SALVAR',
    cancel: 'CANCELAR',
    success: 'Senha atualizada com sucesso',
  },
  documentTypes: {
    noDocumentType: 'Seu usuário não possui um tipo de documento associado.',
    noDocumentTypeWhatToDo:
      'Solicite ao departamento responsável, a adição de um tipo de documento.',
  },
  fullContentMessage: {
    retry: 'TENTAR NOVAMENTE',
    whatToDoLabel: 'O QUE FAZER?',
    loginAsAnotherUser: 'Entrar com outro usuário',
  },
  toast: {
    errorClose: 'FECHAR',
    errorDetails: 'DETALHES',
    errorDialogTitle: 'Detalhes',
    errorDialogClose: 'FECHAR',
  },
  workLimitAccess: {
    title: 'Acessso temporariamente bloqueado',
    timeLabel: 'Período de uso:',
    reload: 'Recarregar',
  },
  error: {
    label: 'Erro: ',
    message: 'Falha na requisição, tente novamente mais tarde.',
    tryAgain: 'Tentar Novamente',
    loginAgain: 'Logar com outro usuário',
    notNow: 'Agora Não',
    updateFailed: 'Falha na Atualização.',
    generalWhatToDo:
      'Você pode entrar com contato com o departamento responsável e comunicar o erro.',
  },
  success: {
    updatedSuccessfully: 'Atualizado com Sucesso!',
  },
  inProgress: {
    sending: 'Enviando...',
    updating: 'Atualizando',
  },
  drawer: {
    defineSubstitute: 'Definir Substituto',
    otherUserTitle: 'Ver Documentos de Outro Usuário',
    otherUserSearch: 'Procurar Usuário',
    logs: 'Logs',
    about: 'Sobre',
    settings: 'Configurações',
    exit: 'Sair',
    exitConfirmationMessage:
      'Tem certeza que deseja sair? Para voltar a utilizar o Go Approve Plus, será necessário fazer login novamente.',
    attention: 'Atenção',
  },
  title: {
    documentTypes: 'Tipos de Documento',
    documentList: 'Documentos',
    documentDetail: 'Detalhes',
    authentication: 'Autenticação',
  },
  button: {
    others: 'Outros',
    send: 'Enviar',
    cancel: 'Cancelar',
    ok: 'Ok',
    close: 'Fechar',
    showLog: 'Ver Log',
    confirm: 'Confirmar',
    apply: 'Aplicar',
    save: 'Salvar',
    delete: 'Excluir',
    yes: 'Sim',
    no: 'Não',
  },
  detail: {
    clickADocument: 'Selecione um documento para visualizar os seus detalhes.',
    emptyDetail: 'Nenhum detalhe encontrado.',
    emptyItem: 'Nenhum item encontrado.',
  },
  list: {
    clickADocumentType: 'Selecione um tipo de documento',
    noItems: 'Não há itens a serem processados no momento.',
    selectedToProcess: `{count, plural,
      one {Marcado}
      other {Marcados}} para processamento`,
    selectAll: 'Marcar tudo',
    unselectAll: 'Desmarcar tudo',
  },
  emptyList: {
    message: 'Não há itens nessa lista.',
    reload: 'Clique aqui para recarregar',
  },
  template: {
    longText: 'Texto longo',
  },
  log: {
    documentActionTitle: 'Log',
    documentActionSuccess: 'Sucesso',
    documentActionError: 'Erro',
  },
  documentProcess: {
    enterPassword: 'Digite a sua senha',
    passwordRequired: 'O campo senha é obrigatório.',
    documentProcess: 'Processamento de documento',
    processedDocument: `{count} {count, plural,
      one {processado com sucesso!}
      other {processados com sucesso!}}`,
    userAlreadyAuthentcated: 'Seu usuário será autenticado automaticamente',
  },
  header: {
    searchPlaceholder: 'Digite aqui',
  },
  otherUser: {
    searchDialogTitle: 'Buscar usuário',
    searchDialogInputLabel: 'Nome ou email',
    searchDialogSearchButton: 'Buscar',
    searchDialogCloseButton: 'Fechar',
    searchDialogNoUserFound: 'Nenhum usuário encontrado',
    searchDialogActivatingMessage: 'Verificando permissões...',
    searchResultItemActivate: 'Ativar',
    stopDialogMessage:
      'Deseja parar de visualizar os documentos do usuário "{name}"?',
    stopDialogCancel: 'Cancelar',
    stopDialogStopViewing: 'Parar de visualizar',
    indicatorBarMessage: 'Visualizando documentos de: {name}',
  },
  about: {
    version: 'Versão',
    developedBy: 'Desenvolvido por',
    goApproveIntro: 'GO APPROVE PLUS É UM APLICATIVO DA SUÍTE',
    goAppsIntro: 'GO APPS - FERRAMENTAS DE PRODUTIVIDADE.',
  },
  settings: {
    language: 'Idioma',
    english: 'Inglês',
    portuguese: 'Português',
    spanish: 'Espanhol',
    general: 'Geral',
    timeout: 'Timeout',
    seconds: 'segundos',
    security: 'Segurança',
    goFaceId: 'Go Face ID',
  },
  filters: {
    title: 'Filtros',
    createFilter: 'Criar Filtro',
    emptyFilters: 'Nenhum filtro criado',
    enterFilterValue: 'Digite o valor',
    createNewFilter: 'Criar novo filtro',
    selectField: 'Selecione o campo',
    editFilter: 'Editar filtro',
    deleteFilter: 'Excluir filtro',
    deleteFilterTitle: 'Excluir filtro?',
    deleteFilterMessage: 'Tem certeza que deseja excluir esse filtro?',
    deleteFilterTip:
      'Dica: Você pode desativá-lo caso queira usá-lo mais tarde.',
    cleanFilters: 'Limpar filtros',
    unactiveAllFilters: 'Desativar todos os filtros',
    unactiveAllFiltersMessage:
      'Deseja desativar todos os filtros para esse tipo de documento?',
  },
  appLogs: {
    documentId: 'Chave do documento',
    fieldName: 'Nome do campo',
    fileExtension: 'Extensão do arquivo',
    logDetails: 'Detalhes do Log',
    goApproveCode: 'Código do erro',
    agironCode: 'Código do erro',
    translatedMessage: 'Mensagem',
    otherActiveUserName: 'Visualizando pelo usuário',
    documentSummary: 'Sumário',
    documentType: 'Tipo de documento',
    documentList: 'Listas',
    documentProcess: 'Processo de documento',
    documentDetail: 'Detalhe',
    attachmentList: 'Lista de anexo',
    attachmentDownload: 'Download de anexo',
    unknown: 'Desconhecido',
    itemError: `{count} {count, plural,
      one {Erro}
      other {Erros}}`,
    noLogs: 'Nenhum log registrado.',
    attention: 'Atenção!',
    deleteLogModalMsg: 'Deseja excluir definitivamente este registro?',
    deleteLogsSuccess: `{count, plural,
      one {Excluído com sucesso}
      other {Excluídos com sucesso}}`,
    deleteLogsError: 'Erro ao excluir',
    deleteAllLogsMessage: 'Deseja excluir definitivamente todos os registros?',
    deleteAllLogsSuccess: 'Excluídos com sucesso',
  },
  goFace: {
    authWithFace: 'AUTENTICAR COM BIOMETRIA FACIAL',
    registerFace: 'CADASTRAR BIOMETRIA FACIAL',
    registerFaceTitle: 'Configuração de face',
    prepositionOf: 'de',
    userRegistered: 'Usuário configurado!',
    userRegisteredMessage:
      'Você já pode utilizar o Go Face ID para processar seus documentos',
    registerFaceMessage:
      'Agora você pode processar documentos com Reconhecimento Facial, mesmo que seu dispositivo não tenha essa funcionalidade.',
    configureFaceMessage: `Tire {count, plural,
        one {# foto}
        other {# fotos}
      } do seu rosto`,
    authFaceTitle: 'Autenticação com Go Face ID',
    authError: 'Erro de Autenticação',
    okButton: 'OK',
    cancelButton: 'CANCELAR',
    facePermissionTitle: 'Permissão para utilizar a câmera',
    facePermissionMessage:
      'Precisamos da sua permissão para utilizar a sua câmera',
    askStatusOneFace: 'Mantenha apenas seu rosto na imagem',
    askStatusFaceOnMark: 'Posicione seu rosto na marcação',
    askStatusDone: 'Concluída com sucesso!',
    askStatusSmile: 'Por favor, sorria',
    askStatusBlink: 'Por favor, pisque',
    askStatusTurn: 'Por favor, vire suavemente o rosto',
    askStatusDetecting: 'Detectando rosto...',
    configure: 'CONFIGURAR',
    cameraDisabledTitle: 'Câmera desabilitada',
    cameraDisabledMessage:
      'Para autenticar com o Go Face ID, você precisa permitir a utilização da sua câmera.',
    noCameraTitle: 'Nenhuma câmera encontrada',
    noCameraMessage:
      'Para autenticação com Go Face ID é necessário que haja uma webcam no seu dispositivo e que ela esteja habilitada.',
    passwdAuthMessage:
      'Por favor, autentique-se com sua senha ou entre em contato com o suporte caso a autenticação por senha não esteja disponível.',
    noCameraHelp: 'Para habilitar a câmera, siga ',
    noCameraHelpLink: 'estes passos',
  },
  goApproveError: {
    [SummaryTemplateError.goApproveCode]: 'Erro no template de sumário',
    [SummaryTemplateRenderError.goApproveCode]:
      'Houve um problema ao renderizar o sumário',
    [SummaryTemplateNotFoundError.goApproveCode]:
      'Template de sumário não encontrado',
    [SummaryTemplateHasNoStructureError.goApproveCode]:
      'Template de sumário não possui estrutura',
    [SummaryTemplateHasNoLayoutError.goApproveCode]:
      'Estrutura do template de sumário não possui layout',
    [ListTemplateError.goApproveCode]: 'Erro no template de lista',
    [ListTemplateRenderError.goApproveCode]:
      'Houve um problema ao renderizar a lista de documentos',
    [ListTemplateNotFoundError.goApproveCode]:
      'Template de lista não encontrado',
    [ListTemplateHasNoStructureError.goApproveCode]:
      'Template de lista não possui estrutura',
    [ListTemplateHasNoLayoutError.goApproveCode]:
      'Estrutura do template de lista não possui layout',
    [DocumentTypesUnableToGetError.goApproveCode]:
      'Não foi possível obter os tipos de documentos',
    [SummaryUnableToGetError.goApproveCode]: 'Não foi possível obter o sumário',
    [DocumentListUnableToGetError.goApproveCode]:
      'Não foi possível obter a lista de documentos',
    [DocumentDetailUnableToGetError.goApproveCode]:
      'Não foi possível obter os detalhes do documento',
    [DocumentFileListUnableToGetError.goApproveCode]:
      'Não foi possível obter a lista de arquivos',
    [DocumentFileUnableToGetError.goApproveCode]:
      'Não foi possível obter o arquivo',
    [DetailTemplateNotFoundError.goApproveCode]:
      'Nenhum template de detalhes encontrado',
    [DetailTemplateHasNoStructureError.goApproveCode]:
      'Template de detalhe não possui estrutura',
    [DetailDetailTabTemplateRenderError.goApproveCode]:
      'Houve um problema ao renderizar os detalhes do documento',
    [DetailDetailTabTemplateStructureHasNoLayout.goApproveCode]:
      'Estrutura do template de detalhe não possui layout',
    [DetailListTabTemplateRenderError.goApproveCode]:
      'Houve um problema ao renderizar esta lista',
    [DetailListTabTemplateStructureHasNoLayout.goApproveCode]:
      'Estrutura do template de lista não possui layout',
    [DetailFileTemplateRenderError.goApproveCode]:
      'Houve um problema ao renderizar a lista de arquivos',
    [DetailFileTemplateStructureHasNoLayout.goApproveCode]:
      'Estrutura do template de lista de arquivos não possui layout',
    [DetailTemplateTypeNotFoundError.goApproveCode]:
      'Estrutura do template não possui um tipo definido',
    [ActionTemplateRenderError.goApproveCode]:
      'Houve um problema ao renderizar os campos da ação',
    [ActionTemplateNotFoundError.goApproveCode]:
      'Template de ação não encontrado',
    [ActionTemplateHasNoStructureError.goApproveCode]:
      'Template de ação não possui estrutura',
    [ActionTemplateHasNoLayoutError.goApproveCode]:
      'Estrutura do template de ação não possui layout',
    [ActionTemplateHasNoKeyError.goApproveCode]:
      'Estrutura do template de ação não possui chave',
    [NoConnectionError.goApproveCode]: 'Sem conexão de rede',
    [OtherUserNoPermission.goApproveCode]: 'Usuário não possui permissão',
    [MicrosoftAuthenticationError.goApproveCode]:
      'Houve um problema na autenticação com a conta Microsoft',
    [MicrosoftAuthenticationNotPermission.goApproveCode]:
      'Sua empresa ou seu usuário não possuem autenticação via conta Microsoft',
    [MicrosoftAuthenticationEmailNotMatch.goApproveCode]:
      'Endereço de e-mail da Microsoft não corresponde ao endereço de e-mail cadastrado na plataforma.',
    [MicrosoftAuthenticationPopupError.goApproveCode]:
      'Erro ao abrir a janela pop-up. Isso pode acontecer se você estiver usando o IE ou se os pop-ups estiverem bloqueados no navegador.',
    [MicrosoftAuthenticationUserCancelledPopup.goApproveCode]:
      'Clique no botão próximo para prosseguir com a autenticação.',
    [TimeoutError.goApproveCode]:
      'O servidor não respondeu no tempo esperado e a solicitação expirou',
    [UnknownRequestError.goApproveCode]: 'Erro de solicitação desconhecido',
  },
  booleanOption: {
    enabled: 'Ativado',
    disabled: 'Desativado',
  },
};

export default pt;
