import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {withTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const Text = styled(Typography)`
  && {
    color: ${props => props.textcolor};
    font-size: 20px;
  }
`;

const DialogTitle = ({children, theme, className}) => (
  <Text
    className={className}
    textcolor={theme.palette.goapprove.primaryContrastColor}
  >
    {children}
  </Text>
);

DialogTitle.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object,
  className: PropTypes.string,
};

export default withTheme()(DialogTitle);
