import callApi from './callApi';

export const fetchDocumentList = async (documentTypeId, user) => {
  const response = await callApi(
    'GET',
    `/document_types/${documentTypeId}/documents`,
    undefined,
    user
  );

  return response.documents;
};
