import {combineReducers} from 'redux';
import isAuthenticated, * as fromIsAuthenticated from './isAuthenticated';
import isAuthenticating, * as fromIsAuthenticating from './isAuthenticating';
import authenticationError, * as fromAuthenticationError from './authenticationError';
import isMakingLogout, * as fromIsMakingLogout from './isMakingLogout';
import logoutError, * as fromLogoutError from './logoutError';
import userHasSession, * as fromUserHasSession from './userHasSession';
import isCheckingAuthentication, * as fromIsCheckingAuthentication from './isCheckingAuthentication';
import isChangingPassword, * as fromIsChangingPassword from './isChangingPassword';
import changePasswordError, * as fromChangePasswordError from './changePasswordError';
import requestAuthenticationError, * as fromRequestAuthenticationError from './requestAuthenticationError';
import isAuthSystemAuthenticated, * as fromIsAuthSystemAuthenticated from './isAuthSystemAuthenticated';
import isCheckingAuthSystem, * as fromIsCheckingAuthSystem from './isCheckingAuthSystem';
import checkAuthSystemError, * as fromCheckAuthSystemError from './checkAuthSystemError';

const getLocalState = state => state.authentication;

export default combineReducers({
  isAuthenticated,
  isAuthenticating,
  authenticationError,
  isMakingLogout,
  logoutError,
  userHasSession,
  isCheckingAuthentication,
  isChangingPassword,
  changePasswordError,
  requestAuthenticationError,
  isAuthSystemAuthenticated,
  isCheckingAuthSystem,
  checkAuthSystemError,
});

export const getIsAuthenticated = state =>
  fromIsAuthenticated.getIsAuthenticated(getLocalState(state));

export const getIsAuthenticating = state =>
  fromIsAuthenticating.getIsAuthenticating(getLocalState(state));

export const getAuthenticationError = state =>
  fromAuthenticationError.getAuthenticationError(getLocalState(state));

export const getIsMakingLogout = state =>
  fromIsMakingLogout.getIsMakingLogout(getLocalState(state));

export const getLogoutError = state =>
  fromLogoutError.getLogoutError(getLocalState(state));

export const getUserHasSession = state =>
  fromUserHasSession.getUserHasSession(getLocalState(state));

export const getIsCheckingAuthentication = state =>
  fromIsCheckingAuthentication.getIsCheckingAuthentication(
    getLocalState(state)
  );

export const getIsChangingPassword = state =>
  fromIsChangingPassword.getIsChangingPassword(getLocalState(state));

export const getChangePasswordError = state =>
  fromChangePasswordError.getChangePasswordError(getLocalState(state));

export const getRequestAuthenticationError = state =>
  fromRequestAuthenticationError.getRequestAuthenticationError(
    getLocalState(state)
  );

export const getIsAuthSystemAuthenticated = state =>
  fromIsAuthSystemAuthenticated.getIsAuthSystemAuthenticated(
    getLocalState(state)
  );

export const getIsCheckingAuthSystem = state =>
  fromIsCheckingAuthSystem.getIsCheckingAuthSystem(getLocalState(state));

export const getCheckAuthSystemError = state =>
  fromCheckAuthSystemError.getCheckAuthSystemError(getLocalState(state));
