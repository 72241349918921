import _ from 'lodash';
import {FETCH_DOCUMENT_SUMMARY_SUCCESS} from '../../_actions/documentSummary';
import {WORK_TIME_ACCESS_ERROR} from '../../_middlewares/detectWorkTimeAccessError';

const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_SUMMARY_SUCCESS:
      return _.uniqBy([...state, action.response.result], id => id);
    case WORK_TIME_ACCESS_ERROR:
      return [];
    default:
      return state;
  }
};

export const getIds = state => state.ids;

export default ids;
