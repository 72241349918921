import {
  UPDATE_DEVICE_TIMEZONE_REQUEST,
  UPDATE_DEVICE_TIMEZONE_SUCCESS,
  UPDATE_DEVICE_TIMEZONE_ERROR,
} from '../../_actions/device';

const updateTimezoneError = (state = null, action) => {
  switch (action.type) {
    case UPDATE_DEVICE_TIMEZONE_REQUEST:
    case UPDATE_DEVICE_TIMEZONE_SUCCESS:
      return null;
    case UPDATE_DEVICE_TIMEZONE_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getUpdateTimezoneError = state => state.updateTimezoneError;

export default updateTimezoneError;
