import {combineReducers} from 'redux';
import byId, * as fromById from './byId';
import ids, * as fromIds from './ids';
import isFetching, * as fromIsFetching from './isFetching';
import fetchError, * as fromFetchError from './fetchError';
//import byDocumentTypeId, * as fromByDocumentTypeId from "./byDocumentTypeId";
import isDocumentSelected, * as fromIsDocumentSelected from './isDocumentSelected';
import listWasCleared, * as fromListWasCleared from './listWasCleared';

const getLocalState = state => state.documentList;

export default combineReducers({
  byId,
  ids,
  isFetching,
  fetchError,
  isDocumentSelected,
  listWasCleared,
});

export const getIsFetching = (state, documentTypeId) =>
  fromIsFetching.getIsFetching(getLocalState(state), documentTypeId);

export const getFetchError = (state, documentTypeId) =>
  fromFetchError.getFetchError(getLocalState(state), documentTypeId);

export const getDocumentListIds = (state, documentTypeId) =>
  fromIds.getIds(getLocalState(state), documentTypeId);

export const getDocumentList = (state, documentTypeId) => {
  const localState = getLocalState(state);
  const ids = fromIds.getIds(localState, documentTypeId);
  return ids.map(id =>
    fromById.getDocumentById(localState, documentTypeId, id)
  );
};

export const getDocumentById = (state, documentTypeId, documentId) =>
  fromById.getDocumentById(getLocalState(state), documentTypeId, documentId);

export const getIsDocumentSelected = (state, documentTypeId, id) =>
  fromIsDocumentSelected.getIsDocumentSelected(
    getLocalState(state),
    documentTypeId,
    id
  );

export const getSelectedDocumentIds = (state, documentTypeId) =>
  fromIsDocumentSelected.getSelectedDocumentIds(
    getLocalState(state),
    documentTypeId
  );

export const getListWasCleared = state =>
  fromListWasCleared.getListWasCleared(getLocalState(state));
