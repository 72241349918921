import {
  CREATE_FACE_REQUEST,
  CREATE_FACE_SUCCESS,
  CREATE_FACE_ERROR,
} from '../../_actions/faceAuthentication';

const createFaceError = (state = null, action) => {
  switch (action.type) {
    case CREATE_FACE_REQUEST:
    case CREATE_FACE_SUCCESS:
      return null;
    case CREATE_FACE_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getCreateFaceError = state => state.createFaceError;

export default createFaceError;
