import {
  CREATE_APP_LOG_REQUEST,
  CREATE_APP_LOG_SUCCESS,
  CREATE_APP_LOG_ERROR,
} from '../../_actions/appLog';

const createError = (state = null, action) => {
  switch (action.type) {
    case CREATE_APP_LOG_REQUEST:
    case CREATE_APP_LOG_SUCCESS:
      return null;
    case CREATE_APP_LOG_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getCreateError = state => state.createError;

export default createError;
