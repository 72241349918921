import {combineReducers} from 'redux';
import pinId, * as fromPinId from './pinId';
import isSendingTwoFaSms, * as fromIsSendingTwoFaSms from './isSendingTwoFaSms';
import sendTwoFaSmsError, * as fromSendTwoFaSmsError from './sendTwoFaSmsError';
import phoneNumber, * as fromPhoneNumber from './phoneNumber';
import isVerifyingTwoFaPin, * as fromIsVerifyingTwoFaPin from './isVerifyingTwoFaPin';
import verifyTwoFaPinResult, * as fromVerifyTwoFaPinResult from './verifyTwoFaPinResult';
import verifyTwoFaPinError, * as fromVerifyTwoFaPinError from './verifyTwoFaPinError';

const getLocalState = state => state.twofa;

export default combineReducers({
  pinId,
  phoneNumber,
  isSendingTwoFaSms,
  sendTwoFaSmsError,
  isVerifyingTwoFaPin,
  verifyTwoFaPinResult,
  verifyTwoFaPinError,
});

export const getPinId = state => fromPinId.getPinId(getLocalState(state));

export const getPhoneNumber = state =>
  fromPhoneNumber.getPhoneNumber(getLocalState(state));

export const getSendTwoFaSmsError = state =>
  fromSendTwoFaSmsError.getSendTwoFaSmsError(getLocalState(state));

export const getIsSendingTwoFaSms = state =>
  fromIsSendingTwoFaSms.getIsSendingTwoFaSms(getLocalState(state));

export const getIsVerifyingTwoFaPin = state =>
  fromIsVerifyingTwoFaPin.getIsVerifyingTwoFaPin(getLocalState(state));

export const getVerifyTwoFaPinResult = state =>
  fromVerifyTwoFaPinResult.getVerifyTwoFaPinResult(getLocalState(state));

export const getVerifyTwoFaPinError = state =>
  fromVerifyTwoFaPinError.getVerifyTwoFaPinError(getLocalState(state));
