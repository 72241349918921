import {
  CHECK_AUTHENTICATION_REQUEST,
  CHECK_AUTHENTICATION_SUCCESS,
  CHECK_AUTHENTICATION_ERROR,
  LOGOUT_SUCCESS,
} from '../../_actions/authentication';
import {APP_SHOULD_START_OVER} from '../../_middlewares/detectAppShouldStartOver';

const isCheckingAuthentication = (state = true, action) => {
  switch (action.type) {
    case CHECK_AUTHENTICATION_REQUEST:
      return true;
    case CHECK_AUTHENTICATION_SUCCESS:
    case CHECK_AUTHENTICATION_ERROR:
    case LOGOUT_SUCCESS:
    case APP_SHOULD_START_OVER:
      return false;
    default:
      return state;
  }
};

export const getIsCheckingAuthentication = state =>
  state.isCheckingAuthentication;

export default isCheckingAuthentication;
