import {
  AUTHENTICATE_USER_REQUEST,
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER_ERROR,
} from '../../_actions/authentication';

const authenticationError = (state = null, action) => {
  switch (action.type) {
    case AUTHENTICATE_USER_REQUEST:
    case AUTHENTICATE_USER_SUCCESS:
      return null;
    case AUTHENTICATE_USER_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getAuthenticationError = state => state.authenticationError;

export default authenticationError;
