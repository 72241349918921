import callApi from './callApi';

export const processDocument = async (
  documentTypeId,
  documentId,
  action,
  user
) => {
  const response = await callApi(
    'POST',
    `/document_types/${documentTypeId}/documents/${documentId}/process`,
    {action},
    user
  );
  return response;
};
