import {
  UPDATE_DEVICE_GO_FACE_ID_ENABLED_REQUEST,
  UPDATE_DEVICE_GO_FACE_ID_ENABLED_SUCCESS,
  UPDATE_DEVICE_GO_FACE_ID_ENABLED_ERROR,
} from '../../_actions/device';

const isUpdatingGoFaceIdEnabled = (state = false, action) => {
  switch (action.type) {
    case UPDATE_DEVICE_GO_FACE_ID_ENABLED_REQUEST:
      return true;
    case UPDATE_DEVICE_GO_FACE_ID_ENABLED_SUCCESS:
    case UPDATE_DEVICE_GO_FACE_ID_ENABLED_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsUpdatingGoFaceIdEnabled = state =>
  state.isUpdatingGoFaceIdEnabled;

export default isUpdatingGoFaceIdEnabled;
