import {
  FETCH_MICROSOFT_SYSTEM_REQUEST,
  FETCH_MICROSOFT_SYSTEM_SUCCESS,
  FETCH_MICROSOFT_SYSTEM_ERROR,
} from '../../../_actions/microsoft/system';

const fetchMicrosoftSystemError = (state = null, action) => {
  switch (action.type) {
    case FETCH_MICROSOFT_SYSTEM_REQUEST:
    case FETCH_MICROSOFT_SYSTEM_SUCCESS:
      return null;
    case FETCH_MICROSOFT_SYSTEM_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getFetchMicrosoftSystemError = state =>
  state.fetchMicrosoftSystemError;

export default fetchMicrosoftSystemError;
