import * as userAssignApi from '../../utils/api/userAssign';
import {parseError} from '../../utils/agiron';
import {fetchUserAssign} from '../userAssign';
import {getUserGroupConfigs} from '../../_reducers/userAssign';
import {OtherUserNoPermission} from '../../config/messageCodes';

export const FETCH_OTHER_USERS_REQUEST = 'FETCH_OTHER_USERS_REQUEST';
export const FETCH_OTHER_USERS_SUCCCESS = 'FETCH_OTHER_USERS_SUCCCESS';
export const FETCH_OTHER_USERS_ERROR = 'FETCH_OTHER_USERS_ERROR';
export const SET_ACTIVE_OTHER_USER_REQUEST = 'SET_ACTIVE_OTHER_USER_REQUEST';
export const SET_ACTIVE_OTHER_USER_SUCCESS = 'SET_ACTIVE_OTHER_USER_SUCCESS';
export const SET_ACTIVE_OTHER_USER_ERROR = 'SET_ACTIVE_OTHER_USER_ERROR';
export const UNSET_ACTIVE_OTHER_USER = 'UNSET_ACTIVE_OTHER_USER';

export const fetchOtherUsers = searchTerm => async (dispatch, getState) => {
  dispatch(fetchOtherUsersRequest());
  const hasOtherUserPermission = await checkHasOtherUserPermission(
    dispatch,
    getState
  );
  if (!hasOtherUserPermission) {
    dispatch(fetchOtherUsersError(OtherUserNoPermission));
    return;
  }
  const response = await userAssignApi
    .getOtherUsers(searchTerm)
    .catch(error => {
      const parsedError = parseError(error, null);
      dispatch(fetchOtherUsersError(parsedError));
    });

  if (response) {
    dispatch(fetchOtherUsersSuccess(response));
  }
};

export const activateOtherUser = userId => async (dispatch, getState) => {
  dispatch(setActiveOtherUserRequest());
  const hasOtherUserPermission = await checkHasOtherUserPermission(
    dispatch,
    getState
  );
  if (!hasOtherUserPermission) {
    dispatch(setActiveOtherUserError(OtherUserNoPermission));
    return;
  }
  dispatch(setActiveOtherUserSuccess(userId));
};

export const fetchOtherUsersRequest = () => ({
  type: FETCH_OTHER_USERS_REQUEST,
});

export const fetchOtherUsersSuccess = response => ({
  type: FETCH_OTHER_USERS_SUCCCESS,
  response,
});

export const fetchOtherUsersError = error => ({
  type: FETCH_OTHER_USERS_ERROR,
  error,
});

export const setActiveOtherUserRequest = () => ({
  type: SET_ACTIVE_OTHER_USER_REQUEST,
});

export const setActiveOtherUserSuccess = userId => ({
  type: SET_ACTIVE_OTHER_USER_SUCCESS,
  userId,
});

export const setActiveOtherUserError = error => ({
  type: SET_ACTIVE_OTHER_USER_ERROR,
  error,
});

export const unsetActiveOtherUser = () => ({
  type: UNSET_ACTIVE_OTHER_USER,
});

const checkHasOtherUserPermission = async (dispatch, getState) => {
  await fetchUserAssign()(dispatch);
  const state = getState();
  const groupConfigs = getUserGroupConfigs(state);
  return groupConfigs.another_approval_user;
};
