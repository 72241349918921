import {combineReducers} from 'redux';
import {LOG_TYPE_DOCUMENT_ACTION} from '../../utils/log';
import ids, * as fromIds from './ids';
import byId, * as fromById from './byId';

const getLocalState = state => state.log;

export default combineReducers({ids, byId});

export const getLogList = state => {
  const localState = getLocalState(state);
  const ids = fromIds.getIds(localState);
  return ids.map(id => fromById.getById(localState, id));
};

export const getLogById = (state, id) =>
  fromById.getById(getLocalState(state), id);

export const getLogListByDocument = (state, documentTypeId) => {
  const localState = getLocalState(state);
  const ids = fromIds.getIds(localState);
  const logs = ids.map(id => fromById.getById(localState, id));
  return logs.filter(
    log =>
      log.type === LOG_TYPE_DOCUMENT_ACTION &&
      log.documentTypeId === documentTypeId
  );
};
