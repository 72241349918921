import {FETCH_OTHER_USERS_SUCCCESS} from '../../_actions/otherUser';

const otherUsers = (state = [], action) => {
  switch (action.type) {
    case FETCH_OTHER_USERS_SUCCCESS:
      return action.response;
    default:
      return state;
  }
};

export const getOtherUsers = state => state.otherUsers;

export default otherUsers;
