import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import WarningIcon from '../../../assets/images/warning.png';
import {ErrorMessage} from '../../../components/Error';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {FormattedMessage} from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withTheme} from '@material-ui/core/styles';
import LoadingDots from '../../loading/LoadingDots';

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const WarningIconStyled = styled.img`
  object-fit: contain;
  display: flex;
  margin-bottom: 18px;
  background-color: #1592e6;
  padding: 7px;
  border-radius: 100%;
  width: 48px;
`;

const TextMessage = withTheme()(styled(Typography)`
  && {
    margin-top: 10px;
    font-size: 22px;
    color: ${props => props.theme.palette.primary.main};
  }
`);

const TextMessageError = withTheme()(styled(Typography)`
  && {
    font-size: 15px;
    color: ${props => props.theme.palette.primary.main};
  }
`);

const ButtonStyled = withTheme()(styled(Button)`
  && {
    margin-top: 15px;
  }
`);

const ButtonStyledOutlined = withTheme()(styled(Button)`
  && {
    margin-top: 15px;
    border-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.main};
  }
`);

class FullScreenUpdateError extends React.Component {
  state = {
    isOpen: false,
  };

  autoCloseDialog = dialogTimeout => {
    setTimeout(() => this.onCloseDialog(), dialogTimeout);
  };

  onCloseDialog = () => {
    this.setState({isOpen: false});
  };

  componentDidUpdate = prevProps => {
    const {showLoading, error, dialogTimeout, userAssignError} = this.props;
    if (
      prevProps.showLoading !== showLoading ||
      prevProps.userAssignError !== userAssignError
    ) {
      if (!showLoading && !error) {
        this.autoCloseDialog(dialogTimeout);
      } else {
        this.setState({isOpen: true});
      }
    }
  };

  render() {
    const {isOpen} = this.state;
    const {
      error,
      tryAgainButton,
      loginAgainButton,
      showLoading,
      userAssignError,
    } = this.props;
    return (
      isOpen && (
        <ErrorContainer>
          {showLoading ? (
            <Fragment>
              <CircularProgress size={32} />
              <LoadingDots>
                <FormattedMessage id="inProgress.updating" />
              </LoadingDots>
            </Fragment>
          ) : error ? (
            <Fragment>
              <WarningIconStyled src={WarningIcon} />
              <TextMessage>
                <FormattedMessage id="error.updateFailed" />
              </TextMessage>
              <TextMessageError>
                <ErrorMessage error={error} />
              </TextMessageError>

              <ButtonContainer>
                <ButtonStyled
                  variant={'raised'}
                  color="primary"
                  onClick={() => tryAgainButton()}
                >
                  <FormattedMessage id="error.tryAgain" />
                </ButtonStyled>

                {!userAssignError ? (
                  <ButtonStyledOutlined
                    variant={'outlined'}
                    onClick={this.onCloseDialog}
                  >
                    <FormattedMessage id="error.notNow" />
                  </ButtonStyledOutlined>
                ) : (
                  <ButtonStyledOutlined
                    variant={'outlined'}
                    onClick={loginAgainButton}
                  >
                    <FormattedMessage id="error.loginAgain" />
                  </ButtonStyledOutlined>
                )}
              </ButtonContainer>
            </Fragment>
          ) : (
            <Fragment>
              <TextMessage>
                <FormattedMessage id="success.updatedSuccessfully" />
              </TextMessage>
            </Fragment>
          )}
        </ErrorContainer>
      )
    );
  }
}

FullScreenUpdateError.propTypes = {
  isOpen: PropTypes.bool,
  error: PropTypes.object,
  userAssignError: PropTypes.object,
  tryAgainButton: PropTypes.func,
  loginAgainButton: PropTypes.func,
  showLoading: PropTypes.bool,
  onCloseDialog: PropTypes.func,
  dialogTimeout: PropTypes.number,
};

FullScreenUpdateError.defaultProps = {
  isOpen: false,
  dialogTimeout: 3000,
};

export default FullScreenUpdateError;
