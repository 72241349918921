import {
  RESET_DOCUMENT_LIST,
  FETCH_DOCUMENT_LIST_REQUEST,
} from '../../_actions/documentList';

const listWasCleared = (state = false, action) => {
  switch (action.type) {
    case RESET_DOCUMENT_LIST:
      return true;
    case FETCH_DOCUMENT_LIST_REQUEST:
      return false;
    default:
      return state;
  }
};

export const getListWasCleared = state => state.listWasCleared;

export default listWasCleared;
