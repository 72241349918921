import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import RouterConfig from './RouterConfig';
import GoApproveSplash from './components/loading/GoApproveSplash';
import {checkAuthentication} from './_actions/authentication';
import {getUserHasSession} from './_reducers/authentication';
import {getIsCheckingAuthentication} from './_reducers/authentication';
import {fetchAppearance} from './_actions/appearance';
import {fetchDevice} from './_actions/device';
import * as appearanceReducer from './_reducers/appearance';
import {fetchUserAssign} from './_actions/userAssign';
import * as userAssignReducer from './_reducers/userAssign';

const SPLASH_DURATION = 2500; //tempo da animação completa
const Container = styled(TransitionGroup)`
  height: 100%;
  display: flex;
  flex: 1;
`;

class App extends React.PureComponent {
  state = {
    showSplash: true,
  };

  async componentDidMount() {
    const {
      checkAuthentication,
      fetchDevice,
      fetchAppearance,
      fetchUserAssign,
    } = this.props;
    await checkAuthentication();
    await fetchUserAssign();
    this.props.userHasSession &&
      (await Promise.all([fetchDevice(), fetchAppearance()]));
    this.setSplashDuration();
  }

  setSplashDuration = () => {
    setTimeout(() => {
      this.setState({showSplash: false});
    }, SPLASH_DURATION);
  };

  render() {
    const {showSplash} = this.state;
    const {isCheckingAuth} = this.props;

    return (
      <Container>
        {(isCheckingAuth || showSplash) && (
          <CSSTransition key="GoApproveSplash" timeout={1000} classNames="fade">
            <GoApproveSplash />
          </CSSTransition>
        )}
        {!isCheckingAuth && <RouterConfig />}
      </Container>
    );
  }
}

App.propTypes = {
  checkAuthentication: PropTypes.func,
  isCheckingAuth: PropTypes.bool,
  userHasSession: PropTypes.bool,
  isFetchingAppearance: PropTypes.bool,
  isFetchingUserAssign: PropTypes.bool,
  fetchAppearance: PropTypes.func,
  fetchUserAssign: PropTypes.func,
  fetchDevice: PropTypes.func,
};

export default connect(
  state => {
    return {
      isCheckingAuth: getIsCheckingAuthentication(state),
      userHasSession: getUserHasSession(state),
      isFetchingAppearance: appearanceReducer.getIsFetchingAppearance(state),
      isFetchingUserAssign: userAssignReducer.getIsFetchingUserAssign(state),
    };
  },
  {checkAuthentication, fetchDevice, fetchAppearance, fetchUserAssign}
)(App);
