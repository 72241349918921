import {
  UPDATE_DEVICE_TIMEZONE_REQUEST,
  UPDATE_DEVICE_TIMEZONE_SUCCESS,
  UPDATE_DEVICE_TIMEZONE_ERROR,
} from '../../_actions/device';

const isUpdatingTimezone = (state = false, action) => {
  switch (action.type) {
    case UPDATE_DEVICE_TIMEZONE_REQUEST:
      return true;
    case UPDATE_DEVICE_TIMEZONE_SUCCESS:
    case UPDATE_DEVICE_TIMEZONE_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsUpdatingTimezone = state => state.isUpdatingTimezone;

export default isUpdatingTimezone;
