import MicrosoftAuth from '../../../utils/api/microsoft/auth';
import {parseError} from '../../../utils/agiron';
import {
  verifyAuthSystemError,
  verifyAuthSystemSuccess,
} from '../../authentication';

export const FETCH_MICROSOFT_AUTH_REQUEST = 'FETCH_MICROSOFT_AUTH_REQUEST';
export const FETCH_MICROSOFT_AUTH_SUCCESS = 'FETCH_MICROSOFT_AUTH_SUCCESS';
export const FETCH_MICROSOFT_AUTH_ERROR = 'FETCH_MICROSOFT_AUTH_ERROR';
export const MICROSOFT_POP_UP_CLOSED = 'MICROSOFT_POP_UP_CLOSED';

export const fetchMicrosoftAuth = (
  email,
  tenant,
  clientId
) => async dispatch => {
  const response = await MicrosoftAuth.getToken(email, tenant, clientId).catch(
    error => {
      const parsedError = parseError(error, null);
      dispatch(fetchMicrosoftAuthError(parsedError));
    }
  );
  if (response) {
    dispatch(fetchMicrosoftAuthSuccess(response));
  }
};

export const checkTokenMicrosoftAuth = (
  microsoftId,
  email,
  tenant,
  clientId
) => async dispatch => {
  const response = await MicrosoftAuth.tokenRefresh(
    microsoftId,
    email,
    tenant,
    clientId
  ).catch(error => {
    if (error && error.goApproveCode === '2020.600.10.5') {
      dispatch(microsoftPopUpClosed());
    }

    dispatch(verifyAuthSystemError(error));
    dispatch(fetchMicrosoftAuthError(error));
  });
  if (response) {
    dispatch(verifyAuthSystemSuccess({status: true}));
    dispatch(fetchMicrosoftAuthSuccess(response));
  }
};

export const fetchMicrosoftAuthRequest = () => ({
  type: FETCH_MICROSOFT_AUTH_REQUEST,
});

export const fetchMicrosoftAuthSuccess = response => ({
  type: FETCH_MICROSOFT_AUTH_SUCCESS,
  response,
});

export const fetchMicrosoftAuthError = error => ({
  type: FETCH_MICROSOFT_AUTH_ERROR,
  error,
});

export const microsoftPopUpClosed = () => ({
  type: MICROSOFT_POP_UP_CLOSED,
});
