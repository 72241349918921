import {UserAgentApplication} from 'msal';
import {
  NotAuthenticatedError,
  MicrosoftAuthenticationEmailNotMatch,
  MicrosoftAuthenticationPopupError,
  MicrosoftAuthenticationUserCancelledPopup,
} from '../../../../config/messageCodes';

let authClient;

const startMicrosoftAuthClient = (clientId, tenant) => {
  authClient = new UserAgentApplication({
    auth: {
      clientId: clientId,
      authority: `https://login.microsoftonline.com/${tenant}`,
      validateAuthority: true,
      redirectUri: window.location.origin + '/aad-callback',
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
  });
};

export const requiresInteraction = errorMessage => {
  if (!errorMessage || !errorMessage.length) {
    return false;
  }
  return (
    errorMessage.indexOf('consent_required') > -1 ||
    errorMessage.indexOf('interaction_required') > -1 ||
    errorMessage.indexOf('login_required') > -1
  );
};

const acquireToken = async request => {
  return authClient.acquireTokenSilent(request).catch(error => {
    if (requiresInteraction(error.errorCode)) {
      return authClient.acquireTokenPopup(request);
    }
  });
};

export const getToken = async (authSystemEmail: string, tenant, clientId) => {
  try {
    startMicrosoftAuthClient(clientId, tenant);

    let loginResponse = await authClient
      .loginPopup({
        scopes: ['User.Read'],
        prompt: 'select_account',
        loginHint: authSystemEmail,
      })
      .catch(async error => {
        if (error.errorCode === 'popup_window_error') {
          throw MicrosoftAuthenticationPopupError;
        }
        if (error.errorCode === 'user_cancelled') {
          throw MicrosoftAuthenticationUserCancelledPopup;
        }
        if (error.errorCode === 'login_progress_error') {
          loginResponse = await authClient.loginPopup({
            scopes: ['User.Read'],
            prompt: 'select_account',
            loginHint: authSystemEmail,
          });

          if (
            loginResponse &&
            loginResponse.account.userName !== authSystemEmail
          ) {
            var err = 'NotMatch';
            throw err;
          }

          return loginResponse;
        }
      });
    if (loginResponse && loginResponse.account.userName !== authSystemEmail) {
      localStorage.clear();
      var err = 'NotMatch';
      throw err;
    }

    return loginResponse;
  } catch (error) {
    handleError(error);
  }
};

export const tokenRefresh = async (
  microsoftId,
  authSystemEmail,
  tenant,
  clientId
) => {
  try {
    startMicrosoftAuthClient(clientId, tenant);

    let tokens = await acquireToken({
      scopes: ['User.Read'],
    });

    if (!tokens) {
      tokens = await authClient
        .acquireTokenPopup({
          scopes: ['User.Read'],
          prompt: 'select_account',
          loginHint: authSystemEmail,
        })
        .catch(async error => {
          if (error.errorCode === 'popup_window_error') {
            throw MicrosoftAuthenticationPopupError;
          }
          if (error.errorCode === 'user_cancelled') {
            localStorage.clear();
            throw MicrosoftAuthenticationUserCancelledPopup;
          }
        });
      if (
        tokens.account.userName &&
        tokens.account.userName !== authSystemEmail
      ) {
        localStorage.clear();
        const err = 'NotMatch';
        throw err;
      }
      return tokens;
    }

    if (
      tokens.account.userName &&
      tokens.account.userName !== authSystemEmail
    ) {
      const err = 'NotMatch';
      throw err;
    }
    return tokens;
  } catch (error) {
    handleError(error);
  }
};

const handleError = error => {
  if (error === 'NotMatch') {
    throw MicrosoftAuthenticationEmailNotMatch;
  }
  if (error) {
    throw error;
  }

  throw NotAuthenticatedError;
};
