import {
  REGISTER_DEVICE_REQUEST,
  REGISTER_DEVICE_SUCCESS,
  REGISTER_DEVICE_ERROR,
} from '../../_actions/device';

const isRegistering = (state = false, action) => {
  switch (action.type) {
    case REGISTER_DEVICE_REQUEST:
      return true;
    case REGISTER_DEVICE_SUCCESS:
    case REGISTER_DEVICE_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsRegistering = state => state.isRegistering;

export default isRegistering;
