import {
  VERIFY_AUTH_SYSTEM_REQUEST,
  VERIFY_AUTH_SYSTEM_SUCCESS,
  VERIFY_AUTH_SYSTEM_ERROR,
} from '../../_actions/authentication';

const checkAuthSystemError = (state = null, action) => {
  switch (action.type) {
    case VERIFY_AUTH_SYSTEM_REQUEST:
    case VERIFY_AUTH_SYSTEM_SUCCESS:
      return null;
    case VERIFY_AUTH_SYSTEM_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getCheckAuthSystemError = state => state.checkAuthSystemError;

export default checkAuthSystemError;
