import * as ProcessDocumentApi from '../../utils/api/processDocument';
import {mergeSuccessWithError} from '../../utils/processDocument';
import {createDocumentActionLog} from '../../utils/log';
import {fetchDocumentList} from '../../_actions/documentList';
import {verifyAuthSystemWithPass} from '../authentication';
import {
  getIsAuthSystemAuthenticated,
  getCheckAuthSystemError,
} from '../../_reducers/authentication';
import {AppLogType} from '../../utils/appLog/types';
import {createAppLog} from '../../_actions/appLog';
import {getActiveOtherUser} from '../../_reducers/otherUser';

export const PROCESS_DOCUMENTS_REQUEST = 'PROCESS_DOCUMENTS_REQUEST';
export const PROCESS_DOCUMENTS_SUCCESS = 'PROCESS_DOCUMENTS_SUCCESS';
export const PROCESS_DOCUMENT_ERROR = 'PROCESS_DOCUMENT_ERROR';
export const SET_PROCESS_DOCUMENTS_ERROR_COUNT =
  'SET_PROCESS_DOCUMENT_ERROR_COUNT';

export const processDocuments = (
  documentTypeId,
  selectedDocumentIds,
  action,
  password
) => async (dispatch, getState) => {
  const state = getState();
  const user = getActiveOtherUser(state);
  dispatch(processDocumentsRequest(documentTypeId));

  await verifyAuthSystemWithPass(password, documentTypeId)(dispatch, getState);
  const isAuthenticated = getIsAuthSystemAuthenticated(getState());
  if (!isAuthenticated) {
    const authError = getCheckAuthSystemError(getState());
    // Replica o dispatch das actions de erros de processamento com o erro de autenticação
    const errors = selectedDocumentIds.map(id => ({
      __id: id,
      error: authError,
    }));
    const result = mergeSuccessWithError([], errors);
    const log = createDocumentActionLog(documentTypeId, action, result);
    createAppLog({
      requestType: AppLogType.DOCUMENT_PROCESS,
      documentTypeId,
      details: {action, result, otherActiveUser: user},
    })(dispatch, getState);

    dispatch(
      setProcessDocumentsErrorCount(documentTypeId, selectedDocumentIds.length)
    );
    dispatch(processDocumentsSuccess(documentTypeId, [], log));
    return log.id;
  }

  let processedDocumentIds = [];
  let errors = [];
  await Promise.all(
    selectedDocumentIds.map(documentId => {
      return ProcessDocumentApi.processDocument(
        documentTypeId,
        documentId,
        action,
        user
      )
        .then(() => processedDocumentIds.push(documentId))
        .catch(error => {
          errors.push({
            __id: documentId,
            error,
          });
        });
    })
  );

  const result = mergeSuccessWithError(processedDocumentIds, errors);
  const log = createDocumentActionLog(documentTypeId, action, result);
  createAppLog({
    requestType: AppLogType.DOCUMENT_PROCESS,
    documentTypeId,
    details: {action, result, otherActiveUser: user},
  })(dispatch, getState);
  dispatch(setProcessDocumentsErrorCount(documentTypeId, errors.length));
  dispatch(processDocumentsSuccess(documentTypeId, processedDocumentIds, log));
  fetchDocumentList(documentTypeId)(dispatch, getState);
  return log.id;
};

export const processDocumentsRequest = documentTypeId => ({
  type: PROCESS_DOCUMENTS_REQUEST,
  documentTypeId,
});

export const processDocumentsSuccess = (documentTypeId, keys, log) => ({
  type: PROCESS_DOCUMENTS_SUCCESS,
  documentTypeId,
  keys,
  log,
});

export const processDocumentError = (documentTypeId, documentId, error) => ({
  type: PROCESS_DOCUMENT_ERROR,
  documentTypeId,
  documentId,
  error,
});

export const setProcessDocumentsErrorCount = (documentTypeId, errorCount) => ({
  type: SET_PROCESS_DOCUMENTS_ERROR_COUNT,
  documentTypeId,
  errorCount,
});
