import _ from 'lodash';
import {
  SELECT_DOCUMENT,
  UNSELECT_DOCUMENT,
  SELECT_ALL_DOCUMENTS,
  UNSELECT_ALL_DOCUMENTS,
  FETCH_DOCUMENT_LIST_REQUEST,
  SELECT_DOCUMENTS,
} from '../../_actions/documentList';

const isDocumentSelected = (state = {}, action) => {
  switch (action.type) {
    case SELECT_DOCUMENT:
      return _.merge({}, state, {
        [action.documentTypeId]: {[action.id]: true},
      });

    case UNSELECT_DOCUMENT:
      return _.omit(state, `${action.documentTypeId}.${action.id}`);

    case SELECT_ALL_DOCUMENTS:
    case SELECT_DOCUMENTS:
      return {
        ...state,
        [action.documentTypeId]: {
          ...state[action.documentTypeId],
          ...action.ids.reduce(
            (acc, id) => ({
              ...acc,
              [id]: true,
            }),
            {}
          ),
        },
      };

    case UNSELECT_ALL_DOCUMENTS:
    case FETCH_DOCUMENT_LIST_REQUEST:
      return {...state, [action.documentTypeId]: {}};

    default:
      return state;
  }
};

export const getSelectedDocumentIds = (state, documentTypeId) => {
  const ids = state.isDocumentSelected[documentTypeId];
  if (ids === undefined) {
    return {};
  }

  return ids;
};

export const getIsDocumentSelected = (state, documentTypeId, id) => {
  const ids = getSelectedDocumentIds(state, documentTypeId);
  return Boolean(ids[id]);
};

export default isDocumentSelected;
