import callApi from './callApi';

export const getUserAssignConfig = async () => {
  return await callApi('GET', '/user_assign');
};

export const createFilter = async filter => {
  const response = await callApi('PUT', '/user_assign/filters', {filter});

  return response.filters;
};

export const updateFilter = async (filterId, filter) => {
  const response = await callApi('PUT', `/user_assign/filters/${filterId}`, {
    filter,
  });

  return response.filters;
};

export const deleteFilter = async filterId => {
  return callApi('DELETE', `/user_assign/filters/${filterId}`);
};

export const activeFilter = async (filterId, active) => {
  const response = await callApi(
    'PUT',
    `/user_assign/filters/${filterId}/active`,
    {active}
  );

  return response.filters;
};

export const unactivateAll = async documentTypeId => {
  const response = await callApi(
    'PUT',
    `/user_assign/filters/${documentTypeId}/unactivate`
  );

  return response.filters;
};

export const getOtherUsers = async searchTerm => {
  const response = await callApi(
    'GET',
    typeof searchTerm === 'string'
      ? `/user_assign/other_users?s=${encodeURI(searchTerm)}`
      : '/user_assign/other_users'
  );
  return response.users;
};
