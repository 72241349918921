import {combineReducers} from 'redux';
import isProcessing, * as fromIsProcessing from './isProcessing';
import processResult, * as fromProcessResult from './processResult';
import processErrorCount, * as fromProcessErrorCount from './processErrorCount';

const getLocalState = state => state.processDocument;

export default combineReducers({
  isProcessing,
  processResult,
  processErrorCount,
});

export const getIsProcessing = (state, documentTypeId) =>
  fromIsProcessing.getIsProcessing(getLocalState(state), documentTypeId);

export const getProcessedDocumentIds = (state, documentTypeId) =>
  fromProcessResult.getProcessedDocumentIds(
    getLocalState(state),
    documentTypeId
  );

export const getProcessErrorCount = (state, documentTypeId) =>
  fromProcessErrorCount.getProcessErrorCount(
    getLocalState(state),
    documentTypeId
  );
