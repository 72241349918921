import {
  FETCH_MICROSOFT_AUTH_REQUEST,
  FETCH_MICROSOFT_AUTH_SUCCESS,
  FETCH_MICROSOFT_AUTH_ERROR,
} from '../../../_actions/microsoft/auth';

const isFetchingMicrosoftAuth = (state = false, action) => {
  switch (action.type) {
    case FETCH_MICROSOFT_AUTH_REQUEST:
      return true;
    case FETCH_MICROSOFT_AUTH_SUCCESS:
    case FETCH_MICROSOFT_AUTH_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsFetchingMicrosoftAuth = state =>
  state.isFetchingMicrosoftAuth;

export default isFetchingMicrosoftAuth;
