import {
  SummaryTemplateError,
  SummaryTemplateRenderError,
  SummaryTemplateNotFoundError,
  SummaryTemplateHasNoStructureError,
  SummaryTemplateHasNoLayoutError,
  ListTemplateError,
  ListTemplateRenderError,
  ListTemplateNotFoundError,
  ListTemplateHasNoStructureError,
  ListTemplateHasNoLayoutError,
  DocumentTypesUnableToGetError,
  SummaryUnableToGetError,
  DocumentListUnableToGetError,
  NoConnectionError,
  DocumentDetailUnableToGetError,
  DocumentFileListUnableToGetError,
  DocumentFileUnableToGetError,
  DetailTemplateNotFoundError,
  DetailTemplateHasNoStructureError,
  DetailDetailTabTemplateRenderError,
  DetailDetailTabTemplateStructureHasNoLayout,
  DetailListTabTemplateRenderError,
  DetailListTabTemplateStructureHasNoLayout,
  DetailFileTemplateRenderError,
  DetailFileTemplateStructureHasNoLayout,
  DetailTemplateTypeNotFoundError,
  ActionTemplateRenderError,
  ActionTemplateNotFoundError,
  ActionTemplateHasNoStructureError,
  ActionTemplateHasNoLayoutError,
  ActionTemplateHasNoKeyError,
  OtherUserNoPermission,
  MicrosoftAuthenticationError,
  MicrosoftAuthenticationNotPermission,
  MicrosoftAuthenticationEmailNotMatch,
  MicrosoftAuthenticationPopupError,
  MicrosoftAuthenticationUserCancelledPopup,
  TimeoutError,
  UnknownRequestError,
} from '../../config/messageCodes';

const es = {
  authentication: {
    enterEmail: 'Introduzca su correo electrónico',
    enterPinCode: 'Introduzca el código',
    nextStep: 'Siguiente',
    userGreeting: 'Hola, {name}!',
    sendingSms: 'Enviando SMS',
    smsSent:
      'Un SMS con un código de 6 dígitos se ha enviado al número ****** - **** {phone}.',
    verifyingPin: 'Comprobación del PIN',
    incorrectPin: 'PIN incorrecto',
    enterPassword: 'Introduzca su contraseña',
    showPassword: 'Mostrar contraseña',
    hidePassword: 'Esconder contraseña',
    resendSms: 'No recibió el código?',
    resendSmsButton: 'Reenviar',
    invalidAuthentication: 'Autenticación no válida',
  },
  changePassword: {
    updateYourPassword: 'Actualiza tu contraseña',
    authPassword: 'Contraseña de autentificación',
    save: 'SALVAR',
    cancel: 'CANCELAR',
    success: 'Contraseña actualizada con éxito',
  },
  documentTypes: {
    noDocumentType: 'Su usuario no tiene un tipo de documento asignado.',
    noDocumentTypeWhatToDo:
      'Pídale al departamento responsable que le asigne uno a su usuario.',
  },
  fullContentMessage: {
    retry: 'INTENTAR NUEVAMENTE',
    whatToDoLabel: '¿QUÉ HACER?',
    loginAsAnotherUser: 'Entrar com outro usuário',
  },
  toast: {
    errorClose: 'CERRAR',
    errorDetails: 'DETALLES',
    errorDialogTitle: 'Detalles',
    errorDialogClose: 'CERRAR',
  },
  workLimitAccess: {
    title: 'Acceso bloqueado temporalmente',
    timeLabel: 'Horas habilitadas:',
    reload: 'Recargar',
  },
  error: {
    label: 'Error: ',
    message: 'Requisición fallida, intente de nuevo más tarde.',
    tryAgain: 'Intentar nuevamente',
    loginAgain: 'Log in with another user',
    notNow: 'Ahora No',
    updateFailed: 'Actualización Fallida.',
    generalWhatToDo:
      'Puede contactar al departamento responsable y comunicar este error.',
  },
  success: {
    updatedSuccessfully: 'Actualizado Exitosamente!',
  },
  inProgress: {
    sending: 'Enviando...',
    updating: 'Actualizando',
  },
  drawer: {
    defineSubstitute: 'Definir Sustituto',
    otherUserTitle: 'Ver Documentos de Otro Usuario',
    otherUserSearch: 'Buscar Usuario',
    logs: 'Registros',
    about: 'Sobre',
    settings: 'Configuraciones',
    exit: 'Cerrar',
    exitConfirmationMessage:
      '¿Está seguro de que desea salir? Para volver a utilizar el Go Approve Plus, es necesario iniciar sesión de nuevo.',
    attention: 'Atención',
  },
  title: {
    documentTypes: 'Tipos de Documentos',
    documentList: 'Documentos',
    documentDetail: 'Detalles',
    authentication: 'Autenticación',
  },
  button: {
    others: 'Otros',
    send: 'Enviar',
    cancel: 'Cancelar',
    ok: 'Ok',
    close: 'Cerrar',
    showLog: 'Ver Log',
    confirm: 'Confirmar',
    apply: 'Aplicar',
    save: 'Salvar',
    delete: 'Eliminar',
    yes: 'Sí',
    no: 'No',
  },
  detail: {
    clickADocument: 'Selecciona un documento para ver sus detalles.',
    emptyDetail: 'No se encontraron detalles.',
    emptyItem: 'No se encontraron ítems.',
  },
  list: {
    clickADocumentType: 'Seleccione un tipo de documento',
    noItems: 'No hay elementos a procesar en el momento.',
    selectedToProcess: `{count, plural,
      one {Seleccionado}
      other {Seleccionados}} para procesar`,
    selectAll: 'Seleccionar todos',
    unselectAll: 'Deselecciona todos',
  },
  emptyList: {
    message: 'No hay elementos en esta lista',
    reload: 'Haga clic aquí para volver a cargar',
  },
  template: {
    longText: 'Texto largo',
  },
  log: {
    documentActionTitle: 'Log',
    documentActionSuccess: 'Éxito',
    documentActionError: 'Error',
  },
  documentProcess: {
    enterPassword: 'Introduzca su contraseña',
    passwordRequired: 'El campo de contraseña es obligatorio.',
    documentProcess: 'Proceso de documento',
    processedDocument: `{count} {count, plural,
      one {procesado con éxito!}
      other {procesados con éxito!}}`,
    userAlreadyAuthentcated: 'Tu usuario será autenticado automáticamente',
  },
  header: {
    searchPlaceholder: 'Entre aquí',
  },
  otherUser: {
    searchDialogTitle: 'Buscar usuario',
    searchDialogInputLabel: 'Nombre o email',
    searchDialogSearchButton: 'Buscar',
    searchDialogCloseButton: 'Cerrar',
    searchDialogNoUserFound: 'Usuario no encontrado',
    searchDialogActivatingMessage: 'Comprobando permisos...',
    searchResultItemActivate: 'Activar',
    stopDialogMessage:
      'Deseja parar de ver los documentos del usuario "{name}"?',
    stopDialogCancel: 'Cancelar',
    stopDialogStopViewing: 'Dejar de ver',
    indicatorBarMessage: 'Viendo documentos del: {name}',
  },
  about: {
    version: 'Versión',
    developedBy: 'Desarrollado por',
    goApproveIntro: 'GO APPROVE PLUS ES UNA APLICACÍON DE SUITE',
    goAppsIntro: 'GO APPS - HERRAMIENTAS DE PRODUCTIVIDAD.',
  },
  settings: {
    language: 'Idioma',
    english: 'Inglés',
    portuguese: 'Portugués',
    spanish: 'Español',
    general: 'General',
    timeout: 'Timeout',
    seconds: 'segundos',
    security: 'Seguridad',
    goFaceId: 'Go Face ID',
  },
  filters: {
    title: 'Filters',
    createFilter: 'Crear filtros',
    emptyFilters: 'No se han creado filtros',
    enterFilterValue: 'Ingrese el valor del campo',
    createNewFilter: 'Crear nuevo filtro',
    selectField: 'Selecciona el campo',
    editFilter: 'Edición de filtro',
    deleteFilter: 'Eliminar filtro',
    deleteFilterTitle: '¿Eliminar filtro?',
    deleteFilterMessage: '¿Estás seguro de que quieres eliminar este filtro?',
    deleteFilterTip:
      'Punta: Puedes desactivarlo si quieres usar este filtro más tarde.',
    cleanFilters: 'Filtros limpios',
    unactiveAllFilters: 'Desactivar todos los filtros',
    unactiveAllFiltersMessage:
      '¿Está seguro de que desea desactivar todos los filtros de este tipo de documento?',
  },
  appLogs: {
    documentId: 'Clave del documento',
    fieldName: 'Nombre del campo',
    fileExtension: 'Extensión del archivo',
    logDetails: 'Detalles del log',
    goApproveCode: 'Código de error',
    agironCode: 'Código de error',
    translatedMessage: 'Mensaje',
    otherActiveUserName: 'Visualización por el usuario',
    documentSummary: 'Resumen',
    documentType: 'Tipo de documento',
    documentList: 'Lista',
    documentProcess: 'Proceso de documento',
    documentDetail: 'Detalle',
    attachmentList: 'Lista de datos adjuntos',
    attachmentDownload: 'Descargar archivos adjuntos',
    unknown: 'Desconocido',
    itemError: `{count} {count, plural,
      one {Error}
      other {Errors}}`,
    noLogs: 'No hay log registrado.',
    attention: '¡Atención!',
    deleteLogModalMsg: '¿Desea eliminar definitivamente este registro?',
    deleteLogsSuccess: `{count, plural,
      one {Excluido con éxito}
      other {Excluidos con éxito}}`,
    deleteLogsError: 'Error al eliminar',
    deleteAllLogsMessage: '¿Desea borrar permanentemente todos los registros?',
  },
  goFace: {
    authWithFace: 'AUTENTICAR CON BIOMETRÍA FACIAL',
    registerFace: 'REGISTRO DE BIOMETRÍA FACIAL',
    registerFaceTitle: 'Registro de face',
    prepositionOf: 'de',
    userRegistered: '¡Usuario configurado!',
    userRegisteredMessage:
      'Ahora puede usar Go Face ID para procesar sus documentos.',
    registerFaceMessage:
      'Ahora puede procesar documentos con Reconocimiento facial, incluso si su dispositivo no tiene esta función.',
    configureFaceMessage: `Saque {count, plural,
        one {# foto}
        other {# fotos}
      } de tu cara`,
    authFaceTitle: 'Autenticación Go Face ID',
    authError: 'Error de autenticación',
    okButton: 'OK',
    cancelButton: 'CANCELAR',
    facePermissionTitle: 'Permiso para usar la cámara',
    facePermissionMessage: 'Necesitamos su permiso para usar su cámara',
    askStatusOneFace: 'Mantén solo tu cara en la imagen',
    askStatusFaceOnMark: 'Coloca tu cara en el marcado',
    askStatusDone: 'Completado con éxito!',
    askStatusSmile: 'Por favor, sonríe',
    askStatusBlink: 'Por favor, parpadea',
    askStatusTurn: 'Por favor, gira suavemente tu cara',
    askStatusDetecting: 'Detectando cara...',
    configure: 'CONFIGURAR',
    cameraDisabledTitle: 'Esperando permiso',
    cameraDisabledMessage:
      'Para usar Go Face ID, debe permitir usar su cámara.',
    noCameraTitle: 'Cámara inexistente',
    noCameraMessage:
      'Para la autenticación de Go Face ID, se requiere una cámara web en su dispositivo y debe estar habilitada.',
    passwdAuthMessage:
      'Autentíquese con su contraseña o contacte al servicio de asistencia si la autenticación con contraseña no está disponible.',
    noCameraHelp: 'Para habilitar la cámara, siga ',
    noCameraHelpLink: 'estos pasos',
  },
  goApproveError: {
    [SummaryTemplateError.goApproveCode]: 'Error de plantilla de resumen',
    [SummaryTemplateRenderError.goApproveCode]:
      'Hubo un problema al procesar el resumen',
    [SummaryTemplateNotFoundError.goApproveCode]:
      'No se encontró una plantilla de resumen',
    [SummaryTemplateHasNoStructureError.goApproveCode]:
      'La plantilla de resumen no tiene estructura',
    [SummaryTemplateHasNoLayoutError.goApproveCode]:
      'La estructura de la plantilla de resumen no tiene diseño',
    [ListTemplateError.goApproveCode]: 'Error de plantilla de lista',
    [ListTemplateRenderError.goApproveCode]:
      'There was a problem rendering the document list',
    [ListTemplateNotFoundError.goApproveCode]:
      'No se encontró una plantilla de lista',
    [ListTemplateHasNoStructureError.goApproveCode]:
      'La plantilla de lista no tiene estructura',
    [ListTemplateHasNoLayoutError.goApproveCode]:
      'La estructura de la plantilla de lista no tiene diseño',
    [DocumentTypesUnableToGetError.goApproveCode]:
      'Incapaz de obtener tipos de documentos',
    [SummaryUnableToGetError.goApproveCode]: 'No se puede obtener un resumen',
    [DocumentListUnableToGetError.goApproveCode]:
      'No se puede obtener la lista de documentos',
    [DocumentDetailUnableToGetError.goApproveCode]:
      'No se puede obtener el detalle del documento',
    [DocumentFileListUnableToGetError.goApproveCode]:
      'No se puede obtener la lista de archivos',
    [DocumentFileUnableToGetError.goApproveCode]:
      'No se puede obtener el archivo',
    [DetailTemplateNotFoundError.goApproveCode]:
      'No se encontró ninguna plantilla de detalle',
    [DetailTemplateHasNoStructureError.goApproveCode]:
      'La plantilla de detalle no tiene estructura',
    [DetailDetailTabTemplateRenderError.goApproveCode]:
      'Hubo un problema al procesar el detalle del documento',
    [DetailDetailTabTemplateStructureHasNoLayout.goApproveCode]:
      'La estructura de la plantilla de detalle no tiene diseño',
    [DetailListTabTemplateRenderError.goApproveCode]:
      'Hubo un problema al mostrar esta lista',
    [DetailListTabTemplateStructureHasNoLayout.goApproveCode]:
      'La estructura de la plantilla de lista no tiene diseño',
    [DetailFileTemplateRenderError.goApproveCode]:
      'Hubo un problema al procesar la lista de archivos',
    [DetailFileTemplateStructureHasNoLayout.goApproveCode]:
      'La estructura de la plantilla de la lista de archivos no tiene diseño',
    [DetailTemplateTypeNotFoundError.goApproveCode]:
      'Estrutura do template não possui um tipo definido',
    [ActionTemplateRenderError.goApproveCode]:
      'Hubo un problema al representar los campos de acción',
    [ActionTemplateNotFoundError.goApproveCode]:
      'No se encontró una plantilla de acción',
    [ActionTemplateHasNoStructureError.goApproveCode]:
      'La plantilla de acción no tiene estructura',
    [ActionTemplateHasNoLayoutError.goApproveCode]:
      'La estructura de acción no tiene diseño',
    [ActionTemplateHasNoKeyError.goApproveCode]:
      'La estructura de acción no tiene llave',
    [NoConnectionError.goApproveCode]: 'No hay conexion de red',
    [OtherUserNoPermission.goApproveCode]: 'El usuario no tiene permiso',
    [MicrosoftAuthenticationError.goApproveCode]:
      'Hubo un problema al autenticar con la cuenta de Microsoft',
    [MicrosoftAuthenticationNotPermission.goApproveCode]:
      'Su empresa o usuario no tiene autenticación a través de la cuenta de Microsoft',
    [MicrosoftAuthenticationEmailNotMatch.goApproveCode]:
      'La dirección de correo electrónico de Microsoft no coincide con la dirección de correo electrónico registrada en la plataforma.',
    [MicrosoftAuthenticationPopupError.goApproveCode]:
      'Error al abrir la ventana emergente. Esto puede suceder si está utilizando IE o si las ventanas emergentes están bloqueadas en el navegador.',
    [MicrosoftAuthenticationUserCancelledPopup.goApproveCode]:
      'Haga clic en el botón siguiente para continuar con la autenticación.',
    [TimeoutError.goApproveCode]:
      'El servidor no respondió dentro del tiempo esperado y la solicitud ha expirado',
    [UnknownRequestError.goApproveCode]: 'Error de solicitud desconocido',
  },
  booleanOption: {
    enabled: 'Habilitado',
    disabled: 'Deshabilitado',
  },
};

export default es;
