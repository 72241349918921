import {
  CREATE_FACE_REQUEST,
  CREATE_FACE_SUCCESS,
  CREATE_FACE_ERROR,
} from '../../_actions/faceAuthentication';

const isCreatingFace = (state = false, action) => {
  switch (action.type) {
    case CREATE_FACE_REQUEST:
      return true;
    case CREATE_FACE_SUCCESS:
    case CREATE_FACE_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsCreatingFace = state => state.isCreatingFace;

export default isCreatingFace;
