import callApi from './callApi';

export const createAppLog = async log => {
  const response = await callApi('POST', `/app_log/`, {app_log: log});
  return response;
};

export const getAllAppLogByUser = async () => {
  const response = await callApi('GET', `/app_log/`);
  return response;
};

export const deleteAppLog = async appLogId => {
  const response = await callApi('DELETE', `/app_log/${appLogId}`);
  return response;
};

export const deleteAllAppLogs = async appLogsIds => {
  const response = await callApi('DELETE', `/app_log/many`, {
    app_log_ids: appLogsIds,
  });
  return response;
};
