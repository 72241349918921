import React from 'react';
import PropTypes from 'prop-types';
import {toast, Slide} from 'react-toastify';
import {FormattedMessage} from 'react-intl';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import SuccessIcon from '@material-ui/icons/Check';
import {ToastContext} from '../components/ToastProvider';

const SIMPLE_COLOR = 'white';
const WARNING_COLOR = '#F6C31C';
const ERROR_COLOR = '#E55E44';
const INFO_COLOR = '#3191FF';
const SUCCESS_COLOR = '#36D131';

const DURATION_TIME = 4000;

const Message = ({text, Icon, color = SIMPLE_COLOR}) => (
  <div style={styles.messageContainer}>
    {Icon ? <Icon style={{...styles.icon, color}} /> : null}
    <span style={{color}}>{text}</span>
  </div>
);

Message.propTypes = {
  text: PropTypes.node,
  Icon: PropTypes.func,
  color: PropTypes.string,
};

const ErrorMessage = ({text, error, Icon, color = SIMPLE_COLOR}) => (
  <ToastContext>
    {({showErrorDetail}) => (
      <div
        style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
      >
        <Message text={text} color={color} Icon={Icon} />
        <span
          style={styles.errorOptionText}
          onClick={() => showErrorDetail(text, error)}
        >
          <FormattedMessage id="toast.errorDetails" />
        </span>
        <span style={styles.errorOptionText}>
          <FormattedMessage id="toast.errorClose" />
        </span>
      </div>
    )}
  </ToastContext>
);

ErrorMessage.propTypes = {
  text: PropTypes.node,
  error: PropTypes.object,
  Icon: PropTypes.func,
  color: PropTypes.string,
};

const CloseButton = ({closeToast}) => {
  return <CloseIcon onClick={closeToast} style={{color: 'white'}} />;
};

CloseButton.propTypes = {
  closeToast: PropTypes.func,
};

const defaultToastParams = {
  autoClose: DURATION_TIME,
  className: 'notification-black ',
  progressClassName: 'notification-progress',
  position: 'bottom-center',
  transition: Slide,
  closeButton: <CloseButton />,
};

export const showSimple = message => {
  toast(<Message text={message} color={SIMPLE_COLOR} />, defaultToastParams);
};

export const showWarning = message => {
  toast(
    <Message text={message} color={WARNING_COLOR} Icon={WarningIcon} />,
    defaultToastParams
  );
};

export const showError = (errorText, error) => {
  toast(
    <ErrorMessage
      text={errorText}
      error={error}
      color={ERROR_COLOR}
      Icon={ErrorIcon}
    />,
    {
      ...defaultToastParams,
      autoClose: false,
      closeButton: <div />,
    }
  );
};

export const showInfo = message => {
  toast(
    <Message text={message} color={INFO_COLOR} Icon={InfoIcon} />,
    defaultToastParams
  );
};

export const showSuccess = message => {
  toast(
    <Message text={message} color={SUCCESS_COLOR} Icon={SuccessIcon} />,
    defaultToastParams
  );
};

export const styles = {
  messageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  icon: {
    marginRight: '8px',
  },
  errorOptionText: {
    fontSize: 12,
    color: 'white',
    marginLeft: 7,
    marginRight: 7,
    fontWeight: '600',
    paddingTop: 15,
    paddingBottom: 15,
  },
};
