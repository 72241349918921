import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {TYPE_ERROR} from '../../../config/messageCodes';

const GoApproveError = ({error, onlyText}) => {
  const {goApproveCode, type} = error;

  let content = null;
  if (onlyText) {
    content = <FormattedMessage id={`goApproveError.${goApproveCode}`} />;
  } else {
    content = (
      <React.Fragment>
        {type === TYPE_ERROR && <FormattedMessage id="error.label" />}
        <FormattedMessage id={`goApproveError.${goApproveCode}`} />
        {` (${goApproveCode})`}
      </React.Fragment>
    );
  }

  return content;
};

GoApproveError.propTypes = {
  error: PropTypes.object,
  onlyText: PropTypes.bool,
};

export default GoApproveError;
