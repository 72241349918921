import {FETCH_DOCUMENT_DETAIL_SUCCESS} from '../../_actions/documentDetail';
import {WORK_TIME_ACCESS_ERROR} from '../../_middlewares/detectWorkTimeAccessError';

const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_DETAIL_SUCCESS:
      return {
        ...state,
        [action.documentTypeId]: {
          ...state[action.documentTypeId],
          ...action.response.entities.documentDetail,
        },
      };
    case WORK_TIME_ACCESS_ERROR:
      return {};
    default:
      return state;
  }
};

export const getDocumentDetailById = (state, documentTypeId, documentId) => {
  const byDocumentType = state.byId[documentTypeId];
  if (byDocumentType) {
    return byDocumentType[documentId];
  }

  return null;
};

export default byId;
