import {
  CHANGE_PASS_FOR_SYSTEM_REQUEST,
  CHANGE_PASS_FOR_SYSTEM_SUCCESS,
  CHANGE_PASS_FOR_SYSTEM_ERROR,
} from '../../_actions/authentication';

const isChangingPassword = (state = false, action) => {
  switch (action.type) {
    case CHANGE_PASS_FOR_SYSTEM_REQUEST:
      return true;
    case CHANGE_PASS_FOR_SYSTEM_SUCCESS:
    case CHANGE_PASS_FOR_SYSTEM_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsChangingPassword = state => state.isChangingPassword;

export default isChangingPassword;
