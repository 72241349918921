import {
  CREATE_FACE_REQUEST,
  CREATE_FACE_SUCCESS,
  CREATE_FACE_ERROR,
} from '../../_actions/faceAuthentication';

const faceCreated = (state = null, action) => {
  switch (action.type) {
    case CREATE_FACE_SUCCESS:
      return action.response;
    case CREATE_FACE_ERROR:
    case CREATE_FACE_REQUEST:
      return null;

    default:
      return state;
  }
};

export const getFaceCreated = state => state.faceCreated;

export default faceCreated;
