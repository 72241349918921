import _ from 'lodash';
import {
  FETCH_DOCUMENT_FILE_LIST_REQUEST,
  FETCH_DOCUMENT_FILE_LIST_SUCCESS,
  FETCH_DOCUMENT_FILE_LIST_ERROR,
} from '../../_actions/documentFile';

const isFetchingFileList = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_FILE_LIST_REQUEST:
      return _.merge({}, state, {
        [action.documentTypeId]: {
          [action.documentId]: true,
        },
      });
    case FETCH_DOCUMENT_FILE_LIST_SUCCESS:
    case FETCH_DOCUMENT_FILE_LIST_ERROR:
      return _.merge({}, state, {
        [action.documentTypeId]: {
          [action.documentId]: false,
        },
      });
    default:
      return state;
  }
};

export const getIsFetchingFileList = (state, documentTypeId, documentId) =>
  _.get(state, ['isFetchingFileList', documentTypeId, documentId], false);

export default isFetchingFileList;
