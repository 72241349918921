import callApi from './callApi';
import {DOCUMENT_TEMPLATE_TYPE} from '../../config/constants';

export const fetchDocumentTypes = async userId => {
  const response = await callApi(
    'GET',
    `/document_types${userId ? '/' + userId : ''}?tt=${DOCUMENT_TEMPLATE_TYPE}`,
    undefined
  );
  return response.document_types;
};

export const fetchDocumentType = async documentTypeId => {
  const response = await callApi(
    'GET',
    `/document_types/${documentTypeId}/populated?tt=${DOCUMENT_TEMPLATE_TYPE}`,
    undefined
  );
  return response.document_type;
};
