import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import 'typeface-roboto';
import 'react-toastify/dist/ReactToastify.min.css';
import './assets/styles/app.css';
import {Provider} from 'react-redux';
import App from './App';
import configureStore from './store';
import AppIntlProvider from './components/AppIntlProvider';
import MThemeProvider from './components/MThemeProvider';
import {ChangePassProvider as ChangePassDialogProvider} from './components/Dialog/ChangePassword';
import {ChangePassProvider} from './context/changePassContext';
import ToastProvider from './components/ToastProvider';
import {PushNotificationProvider} from './components/pushNotification';

ReactDOM.render(
  <Provider store={configureStore()}>
    <AppIntlProvider>
      <MThemeProvider>
        <PushNotificationProvider>
          <ToastProvider>
            <ChangePassProvider>
              <ChangePassDialogProvider>
                <App />
              </ChangePassDialogProvider>
            </ChangePassProvider>
          </ToastProvider>
        </PushNotificationProvider>
      </MThemeProvider>
    </AppIntlProvider>
  </Provider>,
  document.getElementById('root')
);
