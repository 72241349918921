// Checks for key field
export const isFieldKey = field => Boolean(field.is_key);

// Returns the first key field name
export const getKeyFieldNameFromDocumentType = documentType => {
  const fields = documentType.list.template.structures[0].fields;
  return Object.keys(fields).find(fieldName => isFieldKey(fields[fieldName]));
};

export const mergeSuccessWithError = (successIds, errors) => {
  return [...successIds, ...errors].sort((a, b) => {
    const aId = a.__id || a;
    const bId = b.__id || b;
    if (aId > bId) {
      return 1;
    } else if (aId < bId) {
      return -1;
    } else {
      return 0;
    }
  });
};
