import {
  CREATE_PERSON_REQUEST,
  CREATE_PERSON_SUCCESS,
  CREATE_PERSON_ERROR,
} from '../../_actions/faceAuthentication';

const isCreatingPerson = (state = false, action) => {
  switch (action.type) {
    case CREATE_PERSON_REQUEST:
      return true;
    case CREATE_PERSON_SUCCESS:
    case CREATE_PERSON_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsCreatingPerson = state => state.isCreatingPerson;

export default isCreatingPerson;
