import {
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
} from '../../_actions/authentication';

const isMakingLogout = (state = false, action) => {
  switch (action.type) {
    case LOGOUT_REQUEST:
      return true;
    case LOGOUT_SUCCESS:
    case LOGOUT_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsMakingLogout = state => state.isMakingLogout;

export default isMakingLogout;
