import {
  UNACTIVATE_FILTER_REQUEST,
  UNACTIVATE_FILTER_SUCCESS,
  UNACTIVATE_FILTER_ERROR,
} from '../../_actions/userAssign';

const isUnactivatingFilters = (state = false, action) => {
  switch (action.type) {
    case UNACTIVATE_FILTER_REQUEST:
      return true;
    case UNACTIVATE_FILTER_SUCCESS:
    case UNACTIVATE_FILTER_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsUnactivatingFilters = state => state.isUnactivatingFilters;

export default isUnactivatingFilters;
