import {combineReducers} from 'redux';
import deviceId, * as fromDeviceId from './deviceId';
import isRegistering, * as fromIsRegistering from './isRegistering';
import registerError, * as fromRegisterError from './registerError';
import deviceStatus, * as fromDeviceStatus from './deviceStatus';
import isFetchingDeviceStatus, * as fromIsFetchingDeviceStatus from './isFetchingDeviceStatus';
import fetchDeviceStatusError, * as fromFetchDeviceStatusError from './fetchDeviceStatusError';
import device, * as fromDevice from './device';
import isFetchingDevice, * as fromIsFetchingDevice from './isFetchingDevice';
import fetchDeviceError, * as fromFetchDeviceError from './fetchDeviceError';
import isUpdatingLanguage, * as fromIsUpdatingLanguage from './isUpdatingLanguage';
import updateLanguageError, * as fromUpdateLanguageError from './updateLanguageError';
import isUpdatingTimezone, * as fromIsUpdatingTimezone from './isUpdatingTimezone';
import updateTimezoneError, * as fromUpdateTimezoneError from './updateTimezoneError';
import workTimeAccessError, * as fromWorkTimeAccessError from './workTimeAccessError';
import isUpdatingTimeout, * as fromIsUpdatingTimeout from './isUpdatingTimeout';
import updateTimeoutError, * as fromUpdateTimeoutError from './updateTimeoutError';
import isUpdatingGoFaceIdEnabled, * as fromIsUpdatingGoFaceIdEnabled from './isUpdatingGoFaceIdEnabled';
import updateGoFaceIdEnabledError, * as fromUpdateGoFaceIdEnabledError from './updateGoFaceIdEnabledError';

const getLocalState = state => state.device;

export default combineReducers({
  deviceId,
  isRegistering,
  registerError,
  deviceStatus,
  isFetchingDeviceStatus,
  fetchDeviceStatusError,
  device,
  isFetchingDevice,
  fetchDeviceError,
  updateLanguageError,
  isUpdatingLanguage,
  isUpdatingTimezone,
  updateTimezoneError,
  workTimeAccessError,
  isUpdatingTimeout,
  updateTimeoutError,
  isUpdatingGoFaceIdEnabled,
  updateGoFaceIdEnabledError,
});

export const getDeviceId = state =>
  fromDeviceId.getDeviceId(getLocalState(state));

export const getIsRegistering = state =>
  fromIsRegistering.getIsRegistering(getLocalState(state));

export const getRegisterError = state =>
  fromRegisterError.getRegisterError(getLocalState(state));

export const getDeviceStatus = state =>
  fromDeviceStatus.getDeviceStatus(getLocalState(state));

export const getIsFetchingDeviceStatus = state =>
  fromIsFetchingDeviceStatus.getIsFetchingDeviceStatus(getLocalState(state));

export const getFetchDeviceStatusError = state =>
  fromFetchDeviceStatusError.getFetchDeviceStatusError(getLocalState(state));

export const getDevice = state => fromDevice.getDevice(getLocalState(state));

export const getIsFetchingDevice = state =>
  fromIsFetchingDevice.getIsFetchingDevice(getLocalState(state));

export const getFetchDeviceError = state =>
  fromFetchDeviceError.getFetchDeviceError(getLocalState(state));

export const getUpdateLanguageError = state =>
  fromUpdateLanguageError.getUpdateLanguageError(getLocalState(state));

export const getIsUpdatingLanguage = state =>
  fromIsUpdatingLanguage.getIsUpdatingLanguage(getLocalState(state));

export const getIsUpdatingTimezone = state =>
  fromIsUpdatingTimezone.getIsUpdatingTimezone(getLocalState(state));

export const getUpdateTimezoneError = state =>
  fromUpdateTimezoneError.getUpdateTimezoneError(getLocalState(state));

export const getWorkTimeAccessError = state =>
  fromWorkTimeAccessError.getWorkTimeAccessError(getLocalState(state));

export const getIsUpdatingTimeout = state =>
  fromIsUpdatingTimeout.getIsUpdatingTimeout(getLocalState(state));

export const getUpdateTimeoutError = state =>
  fromUpdateTimeoutError.getUpdateTimeoutError(getLocalState(state));

export const getIsUpdatingGoFaceIdEnabled = state =>
  fromIsUpdatingGoFaceIdEnabled.getIsUpdatingGoFaceIdEnabled(
    getLocalState(state)
  );

export const getUpdateGoFaceIdEnabledError = state =>
  fromUpdateGoFaceIdEnabledError.getUpdateGoFaceIdEnabledError(
    getLocalState(state)
  );
