import React from 'react';
import PropTypes from 'prop-types';
import MaterialDialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';

const Dialog = ({children, ...props}) => (
  <MaterialDialog
    {...props}
    scroll={'body'}
    disableBackdropClick={true}
    disableEscapeKeyDown={true}
  >
    {children}
  </MaterialDialog>
);

Dialog.propTypes = {
  children: PropTypes.node,
};

export default withStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  paper: {
    minWidth: 350,
  },
}))(Dialog);
