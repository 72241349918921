import {
  FETCH_DOCUMENT_DETAIL_REQUEST,
  FETCH_DOCUMENT_DETAIL_SUCCESS,
  FETCH_DOCUMENT_DETAIL_ERROR,
} from '../../_actions/documentDetail';

const fetchError = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_DETAIL_REQUEST:
    case FETCH_DOCUMENT_DETAIL_SUCCESS:
      return {
        ...state,
        [action.documentTypeId]: {
          ...state[action.documentTypeId],
          [action.documentId]: null,
        },
      };
    case FETCH_DOCUMENT_DETAIL_ERROR:
      return {
        ...state,
        [action.documentTypeId]: {
          ...state[action.documentTypeId],
          [action.documentId]: action.error,
        },
      };
    default:
      return state;
  }
};

export const getFetchError = (state, documentTypeId, documentId) => {
  const byDocumentType = state.fetchError[documentTypeId];
  if (byDocumentType) {
    return byDocumentType[documentId];
  }

  return null;
};

export default fetchError;
