import {
  SEND_TWOFA_SMS_REQUEST,
  SEND_TWOFA_SMS_SUCCESS,
  SEND_TWOFA_SMS_ERROR,
} from '../../_actions/twofa';

const isSendingTwoFaSms = (state = false, action) => {
  switch (action.type) {
    case SEND_TWOFA_SMS_REQUEST:
      return true;
    case SEND_TWOFA_SMS_SUCCESS:
    case SEND_TWOFA_SMS_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsSendingTwoFaSms = state => state.isSendingTwoFaSms;

export default isSendingTwoFaSms;
