import {schema} from 'normalizr';

export const documentType = new schema.Entity('documentType');
export const arrayOfDocumentTypes = [documentType];

export const documentSummary = new schema.Entity('documentSummary');

export const document = new schema.Entity(
  'document',
  {},
  {idAttribute: '__id'}
);
export const arrayOfDocuments = [document];

export const documentDetail = new schema.Entity(
  'documentDetail',
  {},
  {idAttribute: '__id'}
);
