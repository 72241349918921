import {
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER_REQUEST,
} from '../../_actions/authentication';

const isAuthenticated = (state = null, action) => {
  switch (action.type) {
    case AUTHENTICATE_USER_SUCCESS:
      return action.response.authenticated;
    case AUTHENTICATE_USER_REQUEST:
      return null;
    default:
      return state;
  }
};

export const getIsAuthenticated = state => state.isAuthenticated;

export default isAuthenticated;
