import {
  UPDATE_DEVICE_LANGUAGE_REQUEST,
  UPDATE_DEVICE_LANGUAGE_SUCCESS,
  UPDATE_DEVICE_LANGUAGE_ERROR,
} from '../../_actions/device';

const updateLanguageError = (state = null, action) => {
  switch (action.type) {
    case UPDATE_DEVICE_LANGUAGE_REQUEST:
    case UPDATE_DEVICE_LANGUAGE_SUCCESS:
      return null;
    case UPDATE_DEVICE_LANGUAGE_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getUpdateLanguageError = state => state.updateLanguageError;

export default updateLanguageError;
