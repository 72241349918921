import {
  FETCH_APP_LOG_REQUEST,
  FETCH_APP_LOG_SUCCESS,
  FETCH_APP_LOG_ERROR,
} from '../../_actions/appLog';

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_APP_LOG_REQUEST:
      return true;
    case FETCH_APP_LOG_SUCCESS:
    case FETCH_APP_LOG_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsFetching = state => state.isFetching;

export default isFetching;
