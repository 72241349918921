import {
  CREATE_FILTER_REQUEST,
  CREATE_FILTER_SUCCESS,
  CREATE_FILTER_ERROR,
} from '../../_actions/userAssign';

const isCreatingFilter = (state = false, action) => {
  switch (action.type) {
    case CREATE_FILTER_REQUEST:
      return true;
    case CREATE_FILTER_SUCCESS:
    case CREATE_FILTER_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsCreatingFilter = state => state.isCreatingFilter;

export default isCreatingFilter;
