import {PROCESS_DOCUMENTS_SUCCESS} from '../../_actions/processDocument';

const processResult = (state = {}, action) => {
  switch (action.type) {
    case PROCESS_DOCUMENTS_SUCCESS:
      return {
        ...state,
        [action.documentTypeId]: Object.keys(action.keys),
      };
    default:
      return state;
  }
};

export const getProcessedDocumentIds = (state, documentTypeId) => {
  const ids = state.processResult[documentTypeId];
  if (Array.isArray(ids)) {
    return ids;
  }

  return [];
};

export default processResult;
