import {combineReducers} from 'redux';
import isFetchingMicrosoftAuth, * as fromIsFetchingMicrosoftAuth from './isFetchingMicrosoftAuth';
import authentication, * as fromMicrosoftAuth from './microsoftAuth';
import fetchMicrosoftAuthError, * as fromFetchMicrosoftAuthError from './fetchMicrosoftAuthError';

const getLocalState = state => state.microsoftAuthentication;

export default combineReducers({
  isFetchingMicrosoftAuth,
  authentication,
  fetchMicrosoftAuthError,
});

export const getIsFetchingMicrosoftAuth = state =>
  fromIsFetchingMicrosoftAuth.getIsFetchingMicrosoftAuth(getLocalState(state));

export const getMicrosoftAuth = state =>
  fromMicrosoftAuth.getMicrosoftAuth(getLocalState(state));

export const getFetchMicrosoftAuthError = state =>
  fromFetchMicrosoftAuthError.getFetchMicrosoftAuthError(getLocalState(state));
