import {TimeoutError} from '../config/messageCodes';

const DEFAULT_TIMEOUT_TIME = 60000;

let timeoutTime = DEFAULT_TIMEOUT_TIME;

export const setTimeoutTime = newTimeoutTime => {
  if (typeof newTimeoutTime !== 'number') {
    return;
  }
  timeoutTime = newTimeoutTime;
};

export const getTimeoutTime = () => {
  return timeoutTime;
};

export const timeoutFetch = (url, options) => {
  let requestDidTimeout = false;

  return new Promise((resolve, reject) => {
    const tm = setTimeout(() => {
      requestDidTimeout = true;
      reject(TimeoutError);
    }, timeoutTime);

    fetch(url, options)
      .then(res => {
        if (!requestDidTimeout) {
          resolve(res);
        }
      })
      .catch(error => {
        if (!requestDidTimeout) {
          reject(error);
        }
      })
      .finally(() => clearTimeout(tm));
  });
};
