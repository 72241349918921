import React, {useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import {ErrorMessage} from '../../Error';
import {
  changePassForSystem,
  clearChangePassForSystemError,
} from '../../../_actions/authentication';
import {
  getIsChangingPassword,
  getChangePasswordError,
} from '../../../_reducers/authentication';
import {getUserAssign} from '../../../_reducers/userAssign';
import {useOnChangePasswordSuccess} from '../../../hooks/useOnChangePassSuccess';
import {useOnChangePasswordError} from '../../../hooks/useOnChangePassError.js';
import {showSuccess} from '../../../utils/toast';
import {
  Dialog,
  DialogTitleContainer,
  DialogTitle,
  DialogContent,
  DialogActionsContainer,
} from '../../ModularDialog';
import SaveButton from '../../themed/Button';

const StyledDialogContent = styled(DialogContent)`
  && {
    display: flex;
    flex-direction: column;
  }
`;

const PasswordField = styled(TextField)`
  && {
    margin-bottom: 20px;
  }
`;

const ChangePasswordDialog = ({
  open,
  hideDialog,
  intl,
  isChangingPassword,
  changePasswordError,
  userAssign,
  changePassForSystem,
  clearChangePassForSystemError,
}) => {
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  useOnChangePasswordSuccess(() => {
    setPassword('');
    setIsPasswordVisible(false);
    showSuccess(intl.formatMessage({id: 'changePassword.success'}));
    hideDialog();
  });

  useOnChangePasswordError(() => {
    setPassword('');
  });

  const handlePasswordChange = event => {
    if (changePasswordError) {
      clearChangePassForSystemError();
    }
    setPassword(event.target.value);
  };

  const handleChangePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleSaveButtonClick = async () => {
    await changePassForSystem(userAssign.auth_system, password);
  };

  return (
    <Dialog open={open}>
      <DialogTitleContainer onClose={hideDialog}>
        <DialogTitle>
          <FormattedMessage id="changePassword.updateYourPassword" />
        </DialogTitle>
      </DialogTitleContainer>
      <StyledDialogContent>
        <PasswordField
          label={intl.formatMessage({
            id: 'changePassword.authPassword',
          })}
          fullWidth
          value={password}
          type={isPasswordVisible ? 'text' : 'password'}
          onChange={handlePasswordChange}
          error={Boolean(changePasswordError)}
          helperText={
            changePasswordError ? (
              <ErrorMessage error={changePasswordError} />
            ) : (
              ' '
            )
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={handleChangePasswordVisibility}
                  onMouseDown={() => {}}
                >
                  {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <DialogActionsContainer>
          <Button
            onClick={hideDialog}
            variant="contained"
            color="primary"
            disabled={isChangingPassword}
          >
            <FormattedMessage id="changePassword.cancel" />
          </Button>
          <SaveButton
            onClick={handleSaveButtonClick}
            variant="contained"
            color="primary"
            showProgress={isChangingPassword}
            disabled={password.length === 0}
          >
            <FormattedMessage id="changePassword.save" />
          </SaveButton>
        </DialogActionsContainer>
      </StyledDialogContent>
    </Dialog>
  );
};

ChangePasswordDialog.propTypes = {
  open: PropTypes.bool,
  hideDialog: PropTypes.func,
  intl: PropTypes.object,
  isChangingPassword: PropTypes.bool,
  changePasswordError: PropTypes.object,
  userAssign: PropTypes.object,
  changePassForSystem: PropTypes.func,
  clearChangePassForSystemError: PropTypes.func,
};

export default connect(
  state => {
    return {
      isChangingPassword: getIsChangingPassword(state),
      changePasswordError: getChangePasswordError(state),
      userAssign: getUserAssign(state),
    };
  },
  {changePassForSystem, clearChangePassForSystemError}
)(injectIntl(ChangePasswordDialog));
