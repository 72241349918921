import {
  FETCH_APP_LOG_SUCCESS,
  DELETE_ALL_APP_LOG_SUCCESS,
} from '../../_actions/appLog';

const byUser = (state = [], action) => {
  switch (action.type) {
    case FETCH_APP_LOG_SUCCESS:
      return action.response.appLogs;
    case DELETE_ALL_APP_LOG_SUCCESS:
      return [];
    default:
      return state;
  }
};

export const getAppLogByUser = state => state.byUser;

export default byUser;
