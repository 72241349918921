import {
  FETCH_USER_ASSIGN_REQUEST,
  FETCH_USER_ASSIGN_SUCCESS,
  FETCH_USER_ASSIGN_ERROR,
} from '../../_actions/userAssign';

const fetchUserAssignError = (state = null, action) => {
  switch (action.type) {
    case FETCH_USER_ASSIGN_REQUEST:
    case FETCH_USER_ASSIGN_SUCCESS:
      return null;
    case FETCH_USER_ASSIGN_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getFetchUserAssignError = state => state.fetchUserAssignError;

export default fetchUserAssignError;
