import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import {FormattedMessage} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {ErrorMessage} from '../Error';
import {DialogContent, DialogActionsContainer} from '../ModularDialog';
import styled from 'styled-components';

const ErrorDetailTitle = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 15px;
  }
`;
const ErrorDetailMessage = styled(Typography)`
  && {
    font-size: 14px;
  }
`;
const ErrorDetailDialogActionsContainer = styled(DialogActionsContainer)`
  && {
    padding: 20px;
  }
`;

const ErrorDetailDialog = ({shortErrorText, error, onClose}) => (
  <Dialog
    open
    disableBackdropClick
    disableEscapeKeyDown
    onClick={event => event.stopPropagation()}
  >
    <DialogContent>
      <ErrorDetailTitle>
        <FormattedMessage id="toast.errorDialogTitle" />
      </ErrorDetailTitle>
      <div style={{minWidth: 300, maxWidth: 600}}>
        <ErrorDetailMessage>{shortErrorText}</ErrorDetailMessage>
        <ErrorDetailMessage>
          <ErrorMessage error={error} />
        </ErrorDetailMessage>
      </div>
    </DialogContent>
    <ErrorDetailDialogActionsContainer>
      <Button onClick={onClose} color="primary">
        <FormattedMessage id="toast.errorDialogClose" />
      </Button>
    </ErrorDetailDialogActionsContainer>
  </Dialog>
);

ErrorDetailDialog.propTypes = {
  shortErrorText: PropTypes.node,
  error: PropTypes.object,
  onClose: PropTypes.func,
};

export default ErrorDetailDialog;
