import {normalize} from 'normalizr';
import {arrayOfDocumentTypes, documentType} from '../../_actions/schema';
import * as documentTypeApi from '../../utils/api/documentType';
import {parseDocumentTypeTemplates} from '../../utils/template';
import {DocumentTypesUnableToGetError} from '../../config/messageCodes';
import {getActiveOtherUser} from '../../_reducers/otherUser';
import {parseError} from '../../utils/agiron';
import {AppLogType} from '../../utils/appLog/types';
import {createAppLog} from '../appLog';

export const FETCH_DOCUMENT_TYPES_REQUEST = 'FETCH_DOCUMENT_TYPES_REQUEST';
export const FETCH_DOCUMENT_TYPES_SUCCESS = 'FETCH_DOCUMENT_TYPES_SUCCESS';
export const FETCH_DOCUMENT_TYPES_ERROR = 'FETCH_DOCUMENT_TYPES_ERROR';
export const FETCH_DOCUMENT_TYPE_BY_ID_REQUEST =
  'FETCH_DOCUMENT_TYPE_BY_ID_REQUEST';
export const FETCH_DOCUMENT_TYPE_BY_ID_SUCCESS =
  'FETCH_DOCUMENT_TYPE_BY_ID_SUCCESS';
export const FETCH_DOCUMENT_TYPE_BY_ID_ERROR =
  'FETCH_DOCUMENT_TYPE_BY_ID_ERROR';

export const fetchDocumentTypes = () => async (dispatch, getState) => {
  const state = getState();
  const user = getActiveOtherUser(state);
  dispatch(fetchDocumentTypesRequest());
  const response = await documentTypeApi
    .fetchDocumentTypes(user)
    .catch(error => {
      const parsedError = parseError(error, DocumentTypesUnableToGetError);
      createAppLog({
        requestType: AppLogType.DOCUMENT_TYPE,
        details: {otherActiveUser: user, error: parsedError},
      })(dispatch, getState);
      dispatch(fetchDocumentTypesError(parsedError));
    });

  if (response) {
    const parsedResponse = response.map(documentType => {
      return parseDocumentTypeTemplates(documentType);
    });
    const normalizedData = normalize(parsedResponse, arrayOfDocumentTypes);
    dispatch(fetchDocumentTypesSuccess(normalizedData));
  }
};

export const fetchDocumentType = documentTypeId => async dispatch => {
  dispatch(fetchDocumentTypeByIdRequest());
  const response = await documentTypeApi
    .fetchDocumentType(documentTypeId)
    .catch(error => {
      const parsedError = parseError(error, DocumentTypesUnableToGetError);
      dispatch(fetchDocumentTypeByIdError(parsedError));
    });

  if (response) {
    const parsedResponse = parseDocumentTypeTemplates(response);
    const normalizedData = normalize(parsedResponse, documentType);
    dispatch(fetchDocumentTypeByIdSuccess(normalizedData));
  }
};

export const fetchDocumentTypesRequest = () => ({
  type: FETCH_DOCUMENT_TYPES_REQUEST,
});

export const fetchDocumentTypesSuccess = response => ({
  type: FETCH_DOCUMENT_TYPES_SUCCESS,
  response,
});

export const fetchDocumentTypesError = error => ({
  type: FETCH_DOCUMENT_TYPES_ERROR,
  error,
});

export const fetchDocumentTypeByIdRequest = () => ({
  type: FETCH_DOCUMENT_TYPE_BY_ID_REQUEST,
});

export const fetchDocumentTypeByIdSuccess = response => ({
  type: FETCH_DOCUMENT_TYPE_BY_ID_SUCCESS,
  response,
});

export const fetchDocumentTypeByIdError = error => ({
  type: FETCH_DOCUMENT_TYPE_BY_ID_ERROR,
  error,
});
