import {
  FETCH_DOCUMENT_TYPES_REQUEST,
  FETCH_DOCUMENT_TYPES_SUCCESS,
  FETCH_DOCUMENT_TYPES_ERROR,
} from '../../_actions/documentType';

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_TYPES_REQUEST:
      return true;
    case FETCH_DOCUMENT_TYPES_SUCCESS:
    case FETCH_DOCUMENT_TYPES_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsFetching = state => state.isFetching;

export default isFetching;
