import {FETCH_DOCUMENT_TYPES_SUCCESS} from '../../_actions/documentType';

const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_TYPES_SUCCESS:
      return action.response.result;
    default:
      return state;
  }
};

export const getIds = state => state.ids;

export default ids;
