import forge from 'node-forge';

export const generateRandomKey = () => forge.random.getBytesSync(32);

export const encryptWithSymmKey = (data, key, iv) => {
  const cipher = forge.cipher.createCipher('AES-CBC', key);
  cipher.start({iv});
  cipher.update(forge.util.createBuffer(data, 'utf8'));
  cipher.finish();

  return cipher.output.getBytes();
};

export const encryptWithPublicKey = (data, key) => {
  const buffer = forge.util.createBuffer(data);
  const binaryData = buffer.getBytes();

  const publicKey = forge.pki.publicKeyFromPem(key);
  const encrypted = publicKey.encrypt(binaryData);
  const encoded = forge.util.encode64(encrypted);
  return encoded;
};

export const decryptWithSymmKey = (data, key, iv) => {
  const dataBuffer = forge.util.createBuffer(forge.util.decode64(data));
  const decipher = forge.cipher.createDecipher(
    'AES-CBC',
    forge.util.hexToBytes(key)
  );

  decipher.start({iv: forge.util.hexToBytes(iv)});
  decipher.update(dataBuffer);
  decipher.finish();
  return decipher.output.toString();
};

export const decryptWithPrivateKey = (data, key) => {
  const decoded = forge.util.decode64(data);
  const privKey = forge.pki.privateKeyFromPem(key);
  return privKey.decrypt(decoded);
};
