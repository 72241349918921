import {combineReducers} from 'redux';
import isFetchingOtherUsers, * as fromIsFetchingOtherUsers from './isFetchingOtherUsers';
import otherUsers, * as fromOtherUsers from './otherUsers';
import activeOtherUser, * as fromActiveOtherUser from './activeOtherUser';
import fetchOtherUsersError, * as fromFetchOtherUsersError from './fetchOtherUsersError';
import isActivatingOtherUser, * as fromIsActivatingOtherUser from './isActivatingOtherUser';
import activateOtherUserError, * as fromActivateOtherUserError from './activateOtherUserError';

const getLocalState = state => state.otherUser;

export default combineReducers({
  isFetchingOtherUsers,
  otherUsers,
  activeOtherUser,
  fetchOtherUsersError,
  isActivatingOtherUser,
  activateOtherUserError,
});

export const getIsFetchingOtherUsers = state =>
  fromIsFetchingOtherUsers.getIsFetchingOtherUsers(getLocalState(state));

export const getOtherUsers = state =>
  fromOtherUsers.getOtherUsers(getLocalState(state));

export const getActiveOtherUser = state =>
  fromActiveOtherUser.getActiveOtherUser(getLocalState(state));

export const getFetchOtherUsersError = state =>
  fromFetchOtherUsersError.getFetchOtherUsersError(getLocalState(state));

export const getIsActivatingOtherUser = state =>
  fromIsActivatingOtherUser.getIsActivatingOtherUser(getLocalState(state));

export const getActivateOtherUserError = state =>
  fromActivateOtherUserError.getActivateOtherUserError(getLocalState(state));
