import {combineReducers} from 'redux';
import isFetching, * as fromIsFetching from './isFetching';
import fetchError, * as fromFetchError from './fetchError';
import file, * as fromFile from './file';
import isFetchingFileList, * as fromIsFetchingFileList from './isFetchingFileList';
import fetchFileListError, * as fromFetchFileListError from './fetchFileListError';
import fileList, * as fromFileList from './fileList';

const getLocalState = state => state.documentFile;

export default combineReducers({
  isFetching,
  fetchError,
  file,
  isFetchingFileList,
  fetchFileListError,
  fileList,
});

export const getIsFetchingFileList = (state, documentTypeId, documentId) =>
  fromIsFetchingFileList.getIsFetchingFileList(
    getLocalState(state),
    documentTypeId,
    documentId
  );

export const getFetchFileListError = (state, documentTypeId, documentId) =>
  fromFetchFileListError.getFetchFileListError(
    getLocalState(state),
    documentTypeId,
    documentId
  );

export const getFileList = (state, documentTypeId, documentId) =>
  fromFileList.getFileList(getLocalState(state), documentTypeId, documentId);

export const getIsFetching = (state, documentTypeId, documentId, fileId) =>
  fromIsFetching.getIsFetching(
    getLocalState(state),
    documentTypeId,
    documentId,
    fileId
  );

export const getFetchError = (state, documentTypeId, documentId, fileId) =>
  fromFetchError.getFetchError(
    getLocalState(state),
    documentTypeId,
    documentId,
    fileId
  );

export const getFile = (state, documentTypeId, documentId, fileId) =>
  fromFile.getFile(getLocalState(state), documentTypeId, documentId, fileId);
