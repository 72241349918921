import {
  CREATE_FILTER_REQUEST,
  CREATE_FILTER_SUCCESS,
  CREATE_FILTER_ERROR,
} from '../../_actions/userAssign';

const createFilterError = (state = null, action) => {
  switch (action.type) {
    case CREATE_FILTER_REQUEST:
    case CREATE_FILTER_SUCCESS:
      return null;
    case CREATE_FILTER_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getCreateFilterError = state => state.createFilterError;

export default createFilterError;
