import * as MicrosoftSystem from '../../../utils/api/microsoft/system';
import {parseError} from '../../../utils/agiron';

export const FETCH_MICROSOFT_SYSTEM_REQUEST = 'FETCH_MICROSOFT_SYSTEM_REQUEST';
export const FETCH_MICROSOFT_SYSTEM_SUCCESS = 'FETCH_MICROSOFT_SYSTEM_SUCCESS';
export const FETCH_MICROSOFT_SYSTEM_ERROR = 'FETCH_MICROSOFT_SYSTEM_ERROR';

export const fetchMicrosoftSystemById = microsoftSystemId => async dispatch => {
  dispatch(fetchMicrosoftSystemRequest());

  const response = await MicrosoftSystem.getMicrosoftSystemById(
    microsoftSystemId
  ).catch(error => {
    const parsedError = parseError(error, null);
    dispatch(fetchMicrosoftSystemError(parsedError));
  });

  if (response) {
    dispatch(fetchMicrosoftSystemSuccess(response));
  }
};

export const fetchMicrosoftSystemRequest = () => ({
  type: FETCH_MICROSOFT_SYSTEM_REQUEST,
});

export const fetchMicrosoftSystemSuccess = response => ({
  type: FETCH_MICROSOFT_SYSTEM_SUCCESS,
  response,
});

export const fetchMicrosoftSystemError = error => ({
  type: FETCH_MICROSOFT_SYSTEM_ERROR,
  error,
});
