import {
  UPDATE_DEVICE_TIMEOUT_REQUEST,
  UPDATE_DEVICE_TIMEOUT_SUCCESS,
  UPDATE_DEVICE_TIMEOUT_ERROR,
} from '../../_actions/device';

const updateTimeoutError = (state = null, action) => {
  switch (action.type) {
    case UPDATE_DEVICE_TIMEOUT_REQUEST:
    case UPDATE_DEVICE_TIMEOUT_SUCCESS:
      return null;
    case UPDATE_DEVICE_TIMEOUT_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getUpdateTimeoutError = state => state.updateTimeoutError;

export default updateTimeoutError;
