import callApi from '../../utils/api/callApi';

export const faceAuthentication = async (person_group_id, person_id, image) => {
  const response = await callApi('POST', `/authentication/face`, {
    image,
    person_id,
    person_group_id,
  });

  return response;
};

export const createPerson = async (personGroupId, person) => {
  const response = await callApi(
    'POST',
    `/person_group/${personGroupId}/person`,
    {person}
  );
  return response;
};

export const getPerson = async (personGroupId, personId) => {
  const response = await callApi(
    'GET',
    `/person_group/${personGroupId}/person/${personId}`,
    undefined
  );
  return response;
};

export const deletePerson = async (personGroupId, personId) => {
  const response = await callApi(
    'DELETE',
    `/person_group/${personGroupId}/person/${personId}`,
    undefined
  );
  return response;
};

export const createFace = async (personGroupId, personId, image) => {
  const response = await callApi(
    'POST',
    `/person_group/${personGroupId}/person/${personId}/face`,
    {image}
  );
  return response;
};
