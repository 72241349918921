import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AlertIcon from '../../../assets/images/alert.png';
import {ErrorMessage} from '../../../components/Error';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {FormattedMessage} from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';

const ErrorContainer = styled.div`
  flex: 0.3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ErrorIcon = styled.img`
  object-fit: contain;
  margin-bottom: 30px;
`;

const TextError = styled(Typography)`
  && {
    font-size: 22px;
  }
`;

const ErrorButton = styled(Button)`
  && {
    margin-top: 20px;
  }
`;

class UserAssignError extends React.Component {
  render() {
    const {error, tryAgainButton, loginAgainButton, isLoading} = this.props;

    return (
      <ErrorContainer>
        <ErrorIcon src={AlertIcon} />
        {isLoading ? (
          <CircularProgress size={32} />
        ) : (
          <TextError>
            <ErrorMessage error={error} />
          </TextError>
        )}
        <ErrorButton variant={'outlined'} onClick={() => tryAgainButton()}>
          <FormattedMessage id="error.tryAgain" />
        </ErrorButton>
        <ErrorButton variant={'outlined'} onClick={() => loginAgainButton()}>
          <FormattedMessage id="error.loginAgain" />
        </ErrorButton>
      </ErrorContainer>
    );
  }
}

UserAssignError.propTypes = {
  error: PropTypes.object,
  tryAgainButton: PropTypes.func,
  loginAgainButton: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default UserAssignError;
