import {
  PROCESS_DOCUMENTS_REQUEST,
  PROCESS_DOCUMENTS_SUCCESS,
} from '../../_actions/processDocument';

const isProcessing = (state = {}, action) => {
  switch (action.type) {
    case PROCESS_DOCUMENTS_REQUEST:
      return {
        ...state,
        [action.documentTypeId]: true,
      };
    case PROCESS_DOCUMENTS_SUCCESS:
      return {
        ...state,
        [action.documentTypeId]: false,
      };
    default:
      return state;
  }
};

export const getIsProcessing = (state, documentTypeId) =>
  Boolean(state.isProcessing[documentTypeId]);

export default isProcessing;
