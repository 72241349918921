import {normalize} from 'normalizr';
import {documentDetail} from '../../_actions/schema';
import * as DocumentDetailApi from '../../utils/api/documentDetail';
import {addIdFieldToDocumentDetail} from '../../utils/template';
import {getActiveOtherUser} from '../../_reducers/otherUser';
import {DocumentDetailUnableToGetError} from '../../config/messageCodes';
import {parseError} from '../../utils/agiron';
import {AppLogType} from '../../utils/appLog/types';
import {createAppLog} from '../appLog';

export const FETCH_DOCUMENT_DETAIL_REQUEST = 'FETCH_DOCUMENT_DETAIL_REQUEST';
export const FETCH_DOCUMENT_DETAIL_SUCCESS = 'FETCH_DOCUMENT_DETAIL_SUCCESS';
export const FETCH_DOCUMENT_DETAIL_ERROR = 'FETCH_DOCUMENT_DETAIL_ERROR';

export const fetchDocumentDetail = (documentTypeId, documentId) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const user = getActiveOtherUser(state);
  dispatch(fetchDocumentDetailRequest(documentTypeId, documentId));
  const response = await DocumentDetailApi.fetchDocumentDetail(
    documentTypeId,
    documentId,
    user
  ).catch(error => {
    const parsedError = parseError(error, DocumentDetailUnableToGetError);
    createAppLog({
      requestType: AppLogType.DOCUMENT_DETAIL,
      documentTypeId,
      details: {otherActiveUser: user, error: parsedError, documentId},
    })(dispatch, getState);
    dispatch(fetchDocumentDetailError(documentTypeId, documentId, parsedError));
  });

  if (response) {
    const documentDetailWithId = addIdFieldToDocumentDetail(
      response,
      documentId
    );
    const normalizedData = normalize(documentDetailWithId, documentDetail);
    dispatch(
      fetchDocumentDetailSuccess(documentTypeId, documentId, normalizedData)
    );
  }
};

export const fetchDocumentDetailRequest = (documentTypeId, documentId) => ({
  type: FETCH_DOCUMENT_DETAIL_REQUEST,
  documentTypeId,
  documentId,
});

export const fetchDocumentDetailSuccess = (
  documentTypeId,
  documentId,
  response
) => ({
  type: FETCH_DOCUMENT_DETAIL_SUCCESS,
  documentTypeId,
  documentId,
  response,
});

export const fetchDocumentDetailError = (
  documentTypeId,
  documentId,
  error
) => ({
  type: FETCH_DOCUMENT_DETAIL_ERROR,
  documentTypeId,
  documentId,
  error,
});
