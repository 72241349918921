import {
  FETCH_PERSON_REQUEST,
  FETCH_PERSON_SUCCESS,
  FETCH_PERSON_ERROR,
} from '../../_actions/faceAuthentication';

const fetchPersonError = (state = null, action) => {
  switch (action.type) {
    case FETCH_PERSON_REQUEST:
    case FETCH_PERSON_SUCCESS:
      return null;
    case FETCH_PERSON_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getFetchPersonError = state => state.fetchPersonError;

export default fetchPersonError;
