import React, {useReducer, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {requestPermission} from '../utils/pushNotification';
import notificationSound from '../assets/sounds/notification.mp3';

const PushNotificationContext = React.createContext({});

export const usePushNotificationContext = () => {
  return useContext(PushNotificationContext);
};

const NEW_NOTIFICATION = 'NEW_NOTIFICATION';
const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

const reducer = (state, action) => {
  switch (action.type) {
    case NEW_NOTIFICATION:
      return [...state, action.notification];
    case CLEAR_NOTIFICATIONS:
      return [];
    default:
      return state;
  }
};

const PushNotificationProviderBase = ({children}) => {
  const [notifications, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    requestPermission(notification => {
      const notificationAudio = new Audio(notificationSound);

      // Retorna uma promise no Chrome. Toca o audio normalmente em outros browsers
      const audioPromise = notificationAudio.play();

      if (audioPromise !== undefined && audioPromise.then) {
        audioPromise.then(() => {
          // Não faz nada depois do audio ser executado
        });
      }

      dispatch({
        type: NEW_NOTIFICATION,
        notification,
      });
    });
  }, []);

  const clearNotifications = () => {
    dispatch({
      type: CLEAR_NOTIFICATIONS,
    });
  };

  return (
    <PushNotificationContext.Provider
      value={{count: notifications.length, notifications, clearNotifications}}
    >
      {children}
    </PushNotificationContext.Provider>
  );
};

PushNotificationProviderBase.propTypes = {
  children: PropTypes.node,
};

export const PushNotificationProvider = PushNotificationProviderBase;
