import {
  FETCH_USER_ASSIGN_REQUEST,
  FETCH_USER_ASSIGN_SUCCESS,
  FETCH_USER_ASSIGN_ERROR,
} from '../../_actions/userAssign';

const isFetchingUserAssign = (state = false, action) => {
  switch (action.type) {
    case FETCH_USER_ASSIGN_REQUEST:
      return true;
    case FETCH_USER_ASSIGN_SUCCESS:
    case FETCH_USER_ASSIGN_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsFetchingUserAssign = state => state.isFetchingUserAssign;

export default isFetchingUserAssign;
