import React from 'react';
import PropTypes from 'prop-types';
import MaterialDialog from '@material-ui/core/Dialog';

const Dialog = ({children, ...props}) => (
  <MaterialDialog
    {...props}
    scroll={'body'}
    disableBackdropClick={true}
    disableEscapeKeyDown={true}
  >
    {children}
  </MaterialDialog>
);

Dialog.propTypes = {
  children: PropTypes.node,
};

export default Dialog;
