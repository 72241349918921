import {
  REGISTER_DEVICE_SUCCESS,
  FETCH_DEVICE_STATUS_SUCCESS,
} from '../../_actions/device';

const deviceStatus = (state = null, action) => {
  switch (action.type) {
    case REGISTER_DEVICE_SUCCESS:
    case FETCH_DEVICE_STATUS_SUCCESS:
      return action.response.status;
    default:
      return state;
  }
};

export const getDeviceStatus = state => state.deviceStatus;

export default deviceStatus;
