import {
  VERIFY_AUTH_SYSTEM_SUCCESS,
  VERIFY_AUTH_SYSTEM_REQUEST,
  VERIFY_AUTH_SYSTEM_ERROR,
} from '../../_actions/authentication';

const isAuthSystemAuthenticated = (state = false, action) => {
  switch (action.type) {
    case VERIFY_AUTH_SYSTEM_SUCCESS:
      return action.response.status;
    case VERIFY_AUTH_SYSTEM_REQUEST:
    case VERIFY_AUTH_SYSTEM_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsAuthSystemAuthenticated = state =>
  state.isAuthSystemAuthenticated;

export default isAuthSystemAuthenticated;
