import * as userAssignApi from '../../utils/api/userAssign';

export const FETCH_USER_ASSIGN_REQUEST = 'FETCH_USER_ASSIGN_REQUEST';
export const FETCH_USER_ASSIGN_SUCCESS = 'FETCH_USER_ASSIGN_SUCCESS';
export const FETCH_USER_ASSIGN_ERROR = 'FETCH_USER_ASSIGN_ERROR';
// FILTERS
export const CREATE_FILTER_REQUEST = 'CREATE_FILTER_REQUEST';
export const CREATE_FILTER_SUCCESS = 'CREATE_FILTER_SUCCESS';
export const CREATE_FILTER_ERROR = 'CREATE_FILTER_ERROR';
export const UPDATE_FILTER_REQUEST = 'UPDATE_FILTER_REQUEST';
export const UPDATE_FILTER_SUCCESS = 'UPDATE_FILTER_SUCCESS';
export const UPDATE_FILTER_ERROR = 'UPDATE_FILTER_ERROR';
export const DELETE_FILTER_REQUEST = 'DELETE_FILTER_REQUEST';
export const DELETE_FILTER_SUCCESS = 'DELETE_FILTER_SUCCESS';
export const DELETE_FILTER_ERROR = 'DELETE_FILTER_ERROR';
export const ACTIVE_FILTER_REQUEST = 'ACTIVE_FILTER_REQUEST';
export const ACTIVE_FILTER_SUCCESS = 'ACTIVE_FILTER_SUCCESS';
export const ACTIVE_FILTER_ERROR = 'ACTIVE_FILTER_ERROR';
export const UNACTIVATE_FILTER_REQUEST = 'UNACTIVATE_FILTER_REQUEST';
export const UNACTIVATE_FILTER_SUCCESS = 'UNACTIVATE_FILTER_SUCCESS';
export const UNACTIVATE_FILTER_ERROR = 'UNACTIVATE_FILTER_ERROR';

export const fetchUserAssign = () => async dispatch => {
  dispatch(fetchUserAssignRequest());
  const response = await userAssignApi.getUserAssignConfig().catch(error => {
    dispatch(fetchUserAssignError(error));
  });
  if (response) {
    dispatch(fetchUserAssignSuccess(response));
  }
};

export const fetchUserAssignRequest = () => ({
  type: FETCH_USER_ASSIGN_REQUEST,
});

export const fetchUserAssignSuccess = response => ({
  type: FETCH_USER_ASSIGN_SUCCESS,
  response,
});

export const fetchUserAssignError = error => ({
  type: FETCH_USER_ASSIGN_ERROR,
  error,
});

export const createFilter = filter => async dispatch => {
  dispatch(createFilterRequest());
  const response = await userAssignApi.createFilter(filter).catch(error => {
    dispatch(createFilterError(error));
  });

  if (response) {
    dispatch(createFilterSuccess(response));
  }
};

export const createFilterRequest = () => ({
  type: CREATE_FILTER_REQUEST,
});

export const createFilterSuccess = response => ({
  type: CREATE_FILTER_SUCCESS,
  response,
});

export const createFilterError = error => ({
  type: CREATE_FILTER_ERROR,
  error,
});

export const updateFilter = (filterId, filter) => async dispatch => {
  dispatch(updateFilterRequest(filterId));
  const response = await userAssignApi
    .updateFilter(filterId, filter)
    .catch(error => {
      dispatch(updateFilterError(error, filterId));
    });

  if (response) {
    dispatch(updateFilterSuccess(response));
  }
};

export const updateFilterRequest = filterId => ({
  type: UPDATE_FILTER_REQUEST,
  filterId,
});

export const updateFilterSuccess = response => ({
  type: UPDATE_FILTER_SUCCESS,
  response,
});

export const updateFilterError = (error, filterId) => ({
  type: UPDATE_FILTER_ERROR,
  error,
  filterId,
});

export const deleteFilter = filterId => async dispatch => {
  dispatch(deleteFilterRequest(filterId));
  await userAssignApi.deleteFilter(filterId).catch(error => {
    dispatch(deleteFilterError(error, filterId));
  });

  dispatch(deleteFilterSuccess(filterId));
};

export const deleteFilterRequest = filterId => ({
  type: DELETE_FILTER_REQUEST,
  filterId,
});

export const deleteFilterSuccess = filterId => ({
  type: DELETE_FILTER_SUCCESS,
  filterId,
});

export const deleteFilterError = (error, filterId) => ({
  type: DELETE_FILTER_ERROR,
  error,
  filterId,
});

export const activeFilter = (filterId, active) => async dispatch => {
  dispatch(activeFilterRequest(filterId));
  const response = await userAssignApi
    .activeFilter(filterId, active)
    .catch(error => {
      dispatch(activeFilterError(error, filterId));
    });

  if (response) {
    dispatch(activeFilterSuccess(response, filterId));
  }
};

export const activeFilterRequest = filterId => ({
  type: ACTIVE_FILTER_REQUEST,
  filterId,
});

export const activeFilterSuccess = (response, filterId) => ({
  type: ACTIVE_FILTER_SUCCESS,
  response,
  filterId,
});

export const activeFilterError = (error, filterId) => ({
  type: ACTIVE_FILTER_ERROR,
  error,
  filterId,
});

export const unactivateAll = documentTypeId => async dispatch => {
  dispatch(unactivateFilterRequest());
  const response = await userAssignApi
    .unactivateAll(documentTypeId)
    .catch(error => {
      dispatch(unactivateFilterError(error));
    });

  if (response) {
    dispatch(unactivateFilterSuccess(response));
  }
};

export const unactivateFilterRequest = () => ({
  type: UNACTIVATE_FILTER_REQUEST,
});

export const unactivateFilterSuccess = response => ({
  type: UNACTIVATE_FILTER_SUCCESS,
  response,
});

export const unactivateFilterError = error => ({
  type: UNACTIVATE_FILTER_ERROR,
  error,
});
