import {
  FETCH_DEVICE_SUCCESS,
  UPDATE_DEVICE_LANGUAGE_SUCCESS,
} from '../_actions/device';
import {setLanguage} from '../utils/api/callApi';
import {setDeviceLanguage} from '../utils/locale';

export const setLanguageMiddleware = () => next => action => {
  if (
    action.type === FETCH_DEVICE_SUCCESS ||
    action.type === UPDATE_DEVICE_LANGUAGE_SUCCESS
  ) {
    const {language} = action.response;
    setLanguage(language);
    setDeviceLanguage(language);
  }
  next(action);
};
