import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

const AgironError = ({error, onlyText}) => {
  const {agironCode, message, translatedMessage} = error;
  const text = translatedMessage || message;

  let content = null;
  if (onlyText) {
    content = text;
  } else {
    content = (
      <React.Fragment>
        <FormattedMessage id="error.label" />
        {`${text} (${agironCode})`}
      </React.Fragment>
    );
  }

  return content;
};

AgironError.propTypes = {
  error: PropTypes.object,
  onlyText: PropTypes.bool,
};

export default AgironError;
