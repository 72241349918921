import {
  SET_ACTIVE_OTHER_USER_REQUEST,
  SET_ACTIVE_OTHER_USER_SUCCESS,
  SET_ACTIVE_OTHER_USER_ERROR,
} from '../../_actions/otherUser';

const isActivatingOtherUser = (state = false, action) => {
  switch (action.type) {
    case SET_ACTIVE_OTHER_USER_REQUEST:
      return true;
    case SET_ACTIVE_OTHER_USER_SUCCESS:
    case SET_ACTIVE_OTHER_USER_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsActivatingOtherUser = state => state.isActivatingOtherUser;

export default isActivatingOtherUser;
