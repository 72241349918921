import {
  UPDATE_DEVICE_GO_FACE_ID_ENABLED_REQUEST,
  UPDATE_DEVICE_GO_FACE_ID_ENABLED_SUCCESS,
  UPDATE_DEVICE_GO_FACE_ID_ENABLED_ERROR,
} from '../../_actions/device';

const updateGoFaceIdEnabledError = (state = false, action) => {
  switch (action.type) {
    case UPDATE_DEVICE_GO_FACE_ID_ENABLED_REQUEST:
    case UPDATE_DEVICE_GO_FACE_ID_ENABLED_SUCCESS:
      return null;
    case UPDATE_DEVICE_GO_FACE_ID_ENABLED_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getUpdateGoFaceIdEnabledError = state =>
  state.updateGoFaceIdEnabledError;

export default updateGoFaceIdEnabledError;
