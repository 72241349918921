import _ from 'lodash';
import {
  FETCH_DOCUMENT_LIST_SUCCESS,
  RESET_DOCUMENT_LIST,
} from '../../_actions/documentList';
import {PROCESS_DOCUMENTS_SUCCESS} from '../../_actions/processDocument';
import {WORK_TIME_ACCESS_ERROR} from '../../_middlewares/detectWorkTimeAccessError';

const ids = (state = {}, action) => {
  switch (action.type) {
    case RESET_DOCUMENT_LIST: {
      return [];
    }
    case FETCH_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        [action.documentTypeId]: action.response.result,
      };
    case PROCESS_DOCUMENTS_SUCCESS:
      return {
        ...state,
        [action.documentTypeId]: _.difference(
          state[action.documentTypeId],
          action.keys
        ),
      };
    case WORK_TIME_ACCESS_ERROR:
      return {};
    default:
      return state;
  }
};

export const getIds = (state, documentTypeId) => {
  const ids = state.ids[documentTypeId];
  if (Array.isArray(ids)) {
    return ids;
  }

  return [];
};

export default ids;
