import _ from 'lodash';
import {
  SET_ACTIVE_OTHER_USER_SUCCESS,
  UNSET_ACTIVE_OTHER_USER,
  FETCH_OTHER_USERS_ERROR,
} from '../../_actions/otherUser';
import {OtherUserNoPermission} from '../../config/messageCodes';

const activeOtherUser = (state = null, action) => {
  switch (action.type) {
    case SET_ACTIVE_OTHER_USER_SUCCESS:
      return action.userId;
    case UNSET_ACTIVE_OTHER_USER:
      return null;
    case FETCH_OTHER_USERS_ERROR:
      if (_.isEqual(action.error, OtherUserNoPermission)) {
        return null;
      }
      return state;
    default:
      return state;
  }
};

export const getActiveOtherUser = state => state.activeOtherUser;

export default activeOtherUser;
