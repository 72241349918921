import {
  UPDATE_DEVICE_TIMEOUT_REQUEST,
  UPDATE_DEVICE_TIMEOUT_SUCCESS,
  UPDATE_DEVICE_TIMEOUT_ERROR,
} from '../../_actions/device';

const isUpdatingTimeout = (state = false, action) => {
  switch (action.type) {
    case UPDATE_DEVICE_TIMEOUT_REQUEST:
      return true;
    case UPDATE_DEVICE_TIMEOUT_SUCCESS:
    case UPDATE_DEVICE_TIMEOUT_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsUpdatingTimeout = state => state.isUpdatingTimeout;

export default isUpdatingTimeout;
