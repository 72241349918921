import callApi from './callApi';

export const fetchDocumentFileList = async (
  documentTypeId,
  documentId,
  user
) => {
  const response = await callApi(
    'GET',
    `/document_types/${documentTypeId}/documents/${documentId}/files`,
    undefined,
    user
  );
  return response.document_files;
};

export const fetchDocumentFile = async (
  documentTypeId,
  documentId,
  fileId,
  fileExtension,
  user
) => {
  const path = `/document_types/${documentTypeId}/documents/${documentId}/files/${fileId}?e=${fileExtension}`;

  return await callApi('GET', path, undefined, user, true);
};
