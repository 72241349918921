import * as DocumentFileApi from '../../utils/api/documentFile';
import {
  DocumentFileListUnableToGetError,
  DocumentFileUnableToGetError,
} from '../../config/messageCodes';
import {getActiveOtherUser} from '../../_reducers/otherUser';
import {parseError} from '../../utils/agiron';
import {createAppLog} from '../appLog';
import {AppLogType} from '../../utils/appLog/types';

export const FETCH_DOCUMENT_FILE_LIST_REQUEST =
  'FETCH_DOCUMENT_FILE_LIST_REQUEST';
export const FETCH_DOCUMENT_FILE_LIST_SUCCESS =
  'FETCH_DOCUMENT_FILE_LIST_SUCCESS';
export const FETCH_DOCUMENT_FILE_LIST_ERROR = 'FETCH_DOCUMENT_FILE_LIST_ERROR';
export const FETCH_DOCUMENT_FILE_REQUEST = 'FETCH_DOCUMENT_FILE_REQUEST';
export const FETCH_DOCUMENT_FILE_SUCCESS = 'FETCH_DOCUMENT_FILE_SUCCESS';
export const FETCH_DOCUMENT_FILE_ERROR = 'FETCH_DOCUMENT_FILE_ERROR';

export const fetchDocumentFileList = (documentTypeId, documentId) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const user = getActiveOtherUser(state);

  dispatch(fetchDocumentFileListRequest(documentTypeId, documentId));
  const response = await DocumentFileApi.fetchDocumentFileList(
    documentTypeId,
    documentId,
    user
  ).catch(error => {
    const parsedError = parseError(error, DocumentFileListUnableToGetError);
    createAppLog({
      requestType: AppLogType.DOCUMENT_FILE_LIST,
      documentTypeId,
      details: {
        otherActiveUser: user,
        documentId,
        error: parsedError,
      },
    })(dispatch, getState);
    dispatch(
      fetchDocumentFileListError(documentTypeId, documentId, parsedError)
    );
  });

  if (response) {
    dispatch(
      fetchDocumentFileListSuccess(documentTypeId, documentId, response)
    );
  }
};

export const fetchDocumentFile = (
  documentTypeId,
  documentId,
  fileId,
  fileExtension,
  fieldName
) => async (dispatch, getState) => {
  const state = getState();
  const user = getActiveOtherUser(state);

  dispatch(fetchDocumentFileRequest(documentTypeId, documentId, fileId));
  const response = await DocumentFileApi.fetchDocumentFile(
    documentTypeId,
    documentId,
    fileId,
    fileExtension,
    user
  ).catch(error => {
    const parsedError = parseError(error, DocumentFileUnableToGetError);
    createAppLog({
      requestType: AppLogType.DOCUMENT_FILE,
      documentTypeId,
      details: {
        otherActiveUser: user,
        documentId,
        fileId,
        fieldName,
        fileExtension,
        error: parsedError,
      },
    })(dispatch, getState);
    dispatch(
      fetchDocumentFileError(documentTypeId, documentId, fileId, parsedError)
    );
  });

  if (response) {
    dispatch(
      fetchDocumentFileSuccess(documentTypeId, documentId, fileId, response)
    );
  }
};

export const fetchDocumentFileListRequest = (documentTypeId, documentId) => ({
  type: FETCH_DOCUMENT_FILE_LIST_REQUEST,
  documentTypeId,
  documentId,
});

export const fetchDocumentFileListSuccess = (
  documentTypeId,
  documentId,
  response
) => ({
  type: FETCH_DOCUMENT_FILE_LIST_SUCCESS,
  documentTypeId,
  documentId,
  response,
});

export const fetchDocumentFileListError = (
  documentTypeId,
  documentId,
  error
) => ({
  type: FETCH_DOCUMENT_FILE_LIST_ERROR,
  documentTypeId,
  documentId,
  error,
});

export const fetchDocumentFileRequest = (
  documentTypeId,
  documentId,
  fileId
) => ({
  type: FETCH_DOCUMENT_FILE_REQUEST,
  documentTypeId,
  documentId,
  fileId,
});

export const fetchDocumentFileSuccess = (
  documentTypeId,
  documentId,
  fileId,
  response
) => ({
  type: FETCH_DOCUMENT_FILE_SUCCESS,
  documentTypeId,
  documentId,
  fileId,
  fileURL: response,
});

export const fetchDocumentFileError = (
  documentTypeId,
  documentId,
  fileId,
  error
) => ({
  type: FETCH_DOCUMENT_FILE_ERROR,
  documentTypeId,
  documentId,
  fileId,
  error,
});
