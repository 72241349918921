import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

const DefaultError = ({error, onlyText}) => {
  return (
    <React.Fragment>
      {!onlyText && <FormattedMessage id="error.label" />}
      {`${error}`}
    </React.Fragment>
  );
};

DefaultError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onlyText: PropTypes.bool,
};

export default DefaultError;
