import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from '../_actions/authentication';
import {APP_SHOULD_START_OVER} from '../_middlewares/detectAppShouldStartOver';
import device from './device';
import twofa from './twofa';
import documentType from './documentType';
import documentSummary from './documentSummary';
import documentList from './documentList';
import documentDetail from './documentDetail';
import processDocument from './processDocument';
import userAssign from './userAssign';
import authentication from './authentication';
import log from './log';
import documentFile from './documentFile';
import appearance from './appearance';
import otherUser from './otherUser';
import config from './config';
import appLog from './appLog';
import microsoftAuthentication from './microsoft/auth';
import microsoftSystem from './microsoft/system';
import faceAuthentication from './faceAuthentication';

const appReducer = combineReducers({
  device,
  twofa,
  documentType,
  documentSummary,
  documentList,
  documentDetail,
  processDocument,
  userAssign,
  authentication,
  log,
  documentFile,
  appearance,
  otherUser,
  config,
  appLog,
  microsoftAuthentication,
  microsoftSystem,
  faceAuthentication,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    return appReducer(undefined, action);
  }

  if (action.type === APP_SHOULD_START_OVER) {
    return appReducer(
      {
        config: {
          appShouldStartOver: state.config.appShouldStartOver,
        },
        authentication: {
          isCheckingAuthentication:
            state.authentication.isCheckingAuthentication,
        },
      },
      action
    );
  }

  return appReducer(state, action);
};

export default rootReducer;
