import {
  DELETE_APP_LOG_REQUEST,
  DELETE_APP_LOG_SUCCESS,
  DELETE_APP_LOG_ERROR,
} from '../../_actions/appLog';

const isDeleting = (state = false, action) => {
  switch (action.type) {
    case DELETE_APP_LOG_REQUEST:
      return true;
    case DELETE_APP_LOG_SUCCESS:
    case DELETE_APP_LOG_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsDeleting = state => state.isDeleting;

export default isDeleting;
