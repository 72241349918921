import {
  FETCH_DOCUMENT_LIST_REQUEST,
  FETCH_DOCUMENT_LIST_SUCCESS,
  FETCH_DOCUMENT_LIST_ERROR,
} from '../../_actions/documentList';

const isFetching = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_LIST_REQUEST:
      return {
        ...state,
        [action.documentTypeId]: true,
      };
    case FETCH_DOCUMENT_LIST_SUCCESS:
    case FETCH_DOCUMENT_LIST_ERROR:
      return {
        ...state,
        [action.documentTypeId]: false,
      };
    default:
      return state;
  }
};

export const getIsFetching = (state, documentTypeId) => {
  const fetching = state.isFetching[documentTypeId];
  if (fetching === undefined) {
    return false;
  }

  return fetching;
};

export default isFetching;
