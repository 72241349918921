import * as TwoFaApi from '../../utils/api/twofa';
import {getPinId} from '../../_reducers/twofa';

export const SEND_TWOFA_SMS_REQUEST = 'SEND_TWOFA_SMS_REQUEST';
export const SEND_TWOFA_SMS_SUCCESS = 'SEND_TWOFA_SMS_SUCCESS';
export const SEND_TWOFA_SMS_ERROR = 'SEND_TWOFA_SMS_ERROR ';
export const VERIFY_TWOFA_PIN_REQUEST = 'VERIFY_TWOFA_PIN_REQUEST';
export const VERIFY_TWOFA_PIN_SUCCESS = 'VERIFY_TWOFA_PIN_SUCCESS';
export const VERIFY_TWOFA_PIN_ERROR = 'VERIFY_TWOFA_PIN_ERROR';

export const sendTwoFaSms = language => async dispatch => {
  dispatch(sendTwoFaSmsRequest());
  /*
  // Para utilizar em testes envolvendo o 2FA sem disparar um SMS
  return new Promise(resolve =>
    setTimeout(() => {
      dispatch(sendTwoFaSmsSuccess({ pinId: "1234", phoneNumber: "12" }));
      resolve();
    }, 3000)
  );*/

  const response = await TwoFaApi.sendTwoFaSms(language).catch(error => {
    dispatch(sendTwoFaSmsError(error));
  });

  if (response) {
    dispatch(sendTwoFaSmsSuccess(response));
  }
};

export const verifyTwoFaPin = pin => async (dispatch, getState) => {
  const pinId = getPinId(getState());
  if (pinId === null || typeof pinId !== 'string') {
    dispatch(verifyTwoFaPinError('PinNotRequested'));
    return Promise.resolve();
  }
  dispatch(verifyTwoFaPinRequest());
  const response = await TwoFaApi.verifyTwoFaPin(pinId, pin).catch(error => {
    dispatch(verifyTwoFaPinError(error));
  });

  if (response) {
    dispatch(verifyTwoFaPinSuccess(response));
  }
};

export const sendTwoFaSmsRequest = () => ({
  type: SEND_TWOFA_SMS_REQUEST,
});

export const sendTwoFaSmsSuccess = response => ({
  type: SEND_TWOFA_SMS_SUCCESS,
  response,
});

export const sendTwoFaSmsError = error => ({
  type: SEND_TWOFA_SMS_ERROR,
  error,
});

export const verifyTwoFaPinRequest = () => ({
  type: VERIFY_TWOFA_PIN_REQUEST,
});

export const verifyTwoFaPinSuccess = response => ({
  type: VERIFY_TWOFA_PIN_SUCCESS,
  response,
});

export const verifyTwoFaPinError = error => ({
  type: VERIFY_TWOFA_PIN_ERROR,
  error,
});
