import styled from 'styled-components';
import DialogActions from '@material-ui/core/DialogActions';

const DialogActionsContainer = styled(DialogActions)`
  && {
    padding: 0;
    margin: 0;
  }
`;
export default DialogActionsContainer;
