import {REQUEST_AUTHENTICATION_ERROR} from '../../_middlewares/detectRequestAuthenticationError';
import {FETCH_DOCUMENT_SUMMARY_REQUEST} from '../../_actions/documentSummary';
import {FETCH_DOCUMENT_LIST_REQUEST} from '../../_actions/documentList';
import {FETCH_DOCUMENT_DETAIL_REQUEST} from '../../_actions/documentDetail';
import {
  FETCH_DOCUMENT_FILE_LIST_REQUEST,
  FETCH_DOCUMENT_FILE_REQUEST,
} from '../../_actions/documentFile';

const requestAuthenticationError = (state = null, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_SUMMARY_REQUEST:
    case FETCH_DOCUMENT_LIST_REQUEST:
    case FETCH_DOCUMENT_DETAIL_REQUEST:
    case FETCH_DOCUMENT_FILE_LIST_REQUEST:
    case FETCH_DOCUMENT_FILE_REQUEST:
      return null;
    case REQUEST_AUTHENTICATION_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getRequestAuthenticationError = state =>
  state.requestAuthenticationError;

export default requestAuthenticationError;
