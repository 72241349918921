import * as appearanceApi from '../../utils/api/appearance';

export const FETCH_APPEARANCE_REQUEST = 'FETCH_APPEARANCE_REQUEST';
export const FETCH_APPEARANCE_SUCCESS = 'FETCH_APPEARANCE_SUCCESS';
export const FETCH_APPEARANCE_ERROR = 'FETCH_APPEARANCE_ERROR';

export const fetchAppearance = () => async dispatch => {
  dispatch(fetchAppearanceRequest());
  const response = await appearanceApi.getAppearance().catch(error => {
    dispatch(fetchAppearanceError(error));
  });

  if (response) {
    dispatch(fetchAppearanceSuccess(response));
  }
};

export const fetchAppearanceRequest = () => ({
  type: FETCH_APPEARANCE_REQUEST,
});

export const fetchAppearanceSuccess = response => ({
  type: FETCH_APPEARANCE_SUCCESS,
  response,
});

export const fetchAppearanceError = error => ({
  type: FETCH_APPEARANCE_ERROR,
  error,
});
