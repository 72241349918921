import {WORK_TIME_ACCESS_ERROR} from '../../_middlewares/detectWorkTimeAccessError';
import {CLEAR_WORK_TIME_ACCESS_ERROR} from '../../_actions/device';

const workTimeAccessError = (state = null, action) => {
  switch (action.type) {
    case WORK_TIME_ACCESS_ERROR:
      return action.workTimeError;
    case CLEAR_WORK_TIME_ACCESS_ERROR:
      return null;
    default:
      return state;
  }
};

export const getWorkTimeAccessError = state => state.workTimeAccessError;

export default workTimeAccessError;
