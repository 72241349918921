import {
  AUTHENTICATE_USER_REQUEST,
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER_ERROR,
} from '../../_actions/authentication';

const isAuthenticating = (state = null, action) => {
  switch (action.type) {
    case AUTHENTICATE_USER_REQUEST:
      return true;
    case AUTHENTICATE_USER_SUCCESS:
    case AUTHENTICATE_USER_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsAuthenticating = state => state.isAuthenticating;

export default isAuthenticating;
