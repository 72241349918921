import {
  UPDATE_DEVICE_LANGUAGE_REQUEST,
  UPDATE_DEVICE_LANGUAGE_SUCCESS,
  UPDATE_DEVICE_LANGUAGE_ERROR,
} from '../../_actions/device';

const isUpdatingLanguage = (state = false, action) => {
  switch (action.type) {
    case UPDATE_DEVICE_LANGUAGE_REQUEST:
      return true;
    case UPDATE_DEVICE_LANGUAGE_SUCCESS:
    case UPDATE_DEVICE_LANGUAGE_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsUpdatingLanguage = state => state.isUpdatingLanguage;

export default isUpdatingLanguage;
