export const parseError = (error, defaultError) => {
  if (error && (error.agironCode || error.goApproveCode)) {
    return error;
  }

  return {
    ...defaultError,
    data: {error: error && error.message ? error.message : error},
  };
};

export const isAgironError = error => {
  if (error && Boolean(error.agironCode)) {
    return true;
  }

  return false;
};

export const isGoApproveError = error => {
  if (error && Boolean(error.goApproveCode)) {
    return true;
  }

  return false;
};
