import {combineReducers} from 'redux';
import byId, * as fromById from './byId';
import isFetching, * as fromIsFetching from './isFetching';
import fetchError, * as fromFetchError from './fetchError';

const getLocalState = state => state.documentDetail;

export default combineReducers({byId, isFetching, fetchError});

export const getDocumentDetailById = (state, documentTypeId, documentId) =>
  fromById.getDocumentDetailById(
    getLocalState(state),
    documentTypeId,
    documentId
  );

export const getIsFetching = (state, documentTypeId, documentId) =>
  fromIsFetching.getIsFetching(
    getLocalState(state),
    documentTypeId,
    documentId
  );

export const getFetchError = (state, documentTypeId, documentId) =>
  fromFetchError.getFetchError(
    getLocalState(state),
    documentTypeId,
    documentId
  );
