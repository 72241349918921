import {
  DELETE_APP_LOG_REQUEST,
  DELETE_APP_LOG_SUCCESS,
  DELETE_APP_LOG_ERROR,
} from '../../_actions/appLog';

const deleteError = (state = null, action) => {
  switch (action.type) {
    case DELETE_APP_LOG_REQUEST:
    case DELETE_APP_LOG_SUCCESS:
      return null;
    case DELETE_APP_LOG_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getDeleteError = state => state.deleteError;

export default deleteError;
