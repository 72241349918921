export const API_BASE_URL = process.env.REACT_APP_API_BASE;
export const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
export const FCM_SENDER_ID = process.env.REACT_APP_FCM_SENDER_ID;

if (!process.env.REACT_APP_PRIVATE_CLIENT_KEY_P1) {
  throw new Error('Env variable REACT_APP_PRIVATE_CLIENT_KEY_P1 not set');
}

if (!process.env.REACT_APP_PRIVATE_CLIENT_KEY_P2) {
  throw new Error('Env variable REACT_APP_PRIVATE_CLIENT_KEY_P2 not set');
}

export const CLIENT_PRIVATE_KEY =
  process.env.REACT_APP_PRIVATE_CLIENT_KEY_P1 +
  process.env.REACT_APP_PRIVATE_CLIENT_KEY_P2;

export const RESENT_SMS_DELAY = 15000;
export const MINIMUM_PHOTOS_TO_CONFIGURE = 3;
export const FLASH_EFFECT_DURATION = 150;
export const MOBILE_TEMPLATE_TYPE = 'm';
export const WEB_TEMPLATE_TYPE = 'w';
export const DOCUMENT_TEMPLATE_TYPE = WEB_TEMPLATE_TYPE;
export const DEFAULT_COLOR = '#4285F4';
export const SELECTED_HEADER_COLOR = '#F2FFFC';
export const FACE_CONFIGURATION_EXIT_DELAY = 500;

export const SETTINGS_LANGUAGES = {
  EN: {id: 'EN', translationCode: 'settings.english'},
  PT: {id: 'PT', translationCode: 'settings.portuguese'},
  ES: {id: 'ES', translationCode: 'settings.spanish'},
};

export const WORK_TIME_ACCESS_ERROR_CODE = '2000.300.20.5';
export const WORK_TIME_ACCESS_AUTH_ERROR_CODE = '2000.300.20.6';
export const AUTH_SYSTEM_AUTHENTICATION_ERROR_CODE = '2000.200.40.2';
export const SESSION_FAILURE_ERROR_CODE = '2000.900.30.1';
