import {
  FETCH_PERSON_REQUEST,
  FETCH_PERSON_SUCCESS,
  FETCH_PERSON_ERROR,
} from '../../_actions/faceAuthentication';

const isFetchingPerson = (state = false, action) => {
  switch (action.type) {
    case FETCH_PERSON_REQUEST:
      return true;
    case FETCH_PERSON_SUCCESS:
    case FETCH_PERSON_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsFetchingPerson = state => state.isFetching;

export default isFetchingPerson;
