import {
  SummaryTemplateError,
  SummaryTemplateRenderError,
  SummaryTemplateNotFoundError,
  SummaryTemplateHasNoStructureError,
  SummaryTemplateHasNoLayoutError,
  ListTemplateError,
  ListTemplateRenderError,
  ListTemplateNotFoundError,
  ListTemplateHasNoStructureError,
  ListTemplateHasNoLayoutError,
  DocumentTypesUnableToGetError,
  SummaryUnableToGetError,
  DocumentListUnableToGetError,
  NoConnectionError,
  DocumentDetailUnableToGetError,
  DocumentFileListUnableToGetError,
  DocumentFileUnableToGetError,
  DetailTemplateNotFoundError,
  DetailTemplateHasNoStructureError,
  DetailDetailTabTemplateRenderError,
  DetailDetailTabTemplateStructureHasNoLayout,
  DetailListTabTemplateRenderError,
  DetailListTabTemplateStructureHasNoLayout,
  DetailFileTemplateRenderError,
  DetailFileTemplateStructureHasNoLayout,
  DetailTemplateTypeNotFoundError,
  ActionTemplateRenderError,
  ActionTemplateNotFoundError,
  ActionTemplateHasNoStructureError,
  ActionTemplateHasNoLayoutError,
  ActionTemplateHasNoKeyError,
  OtherUserNoPermission,
  MicrosoftAuthenticationError,
  MicrosoftAuthenticationNotPermission,
  MicrosoftAuthenticationEmailNotMatch,
  MicrosoftAuthenticationPopupError,
  MicrosoftAuthenticationUserCancelledPopup,
  TimeoutError,
  UnknownRequestError,
} from '../../config/messageCodes';

const en = {
  authentication: {
    enterEmail: 'Enter your e-mail',
    enterPinCode: 'Enter the code',
    nextStep: 'Next',
    userGreeting: 'Hi, {name}!',
    sendingSms: 'Sending SMS',
    smsSent:
      'A SMS with a 6-digit code was send to phone number ******-****{phone}.',
    verifyingPin: 'Verifying PIN',
    incorrectPin: 'Incorrect PIN',
    enterPassword: 'Enter your password',
    showPassword: 'Show password',
    hidePassword: 'Hide password',
    resendSms: "Didn't receive the code?",
    resendSmsButton: 'Resend',
    invalidAuthentication: 'Invalid authentication',
  },
  changePassword: {
    updateYourPassword: 'Update your Password',
    authPassword: 'Authentication Password',
    save: 'SAVE',
    cancel: 'CANCEL',
    success: 'Password updated successfully',
  },
  documentTypes: {
    noDocumentType: 'You user does not have a Document Type assigned.',
    noDocumentTypeWhatToDo:
      'Ask the responsible department to assign one to your user.',
  },
  fullContentMessage: {
    retry: 'RETRY',
    whatToDoLabel: 'WHAT TO DO?',
    loginAsAnotherUser: 'Login as another user',
  },
  toast: {
    errorClose: 'CLOSE',
    errorDetails: 'DETAILS',
    errorDialogTitle: 'Details',
    errorDialogClose: 'CLOSE',
  },
  workLimitAccess: {
    title: 'Access temporarily blocked',
    timeLabel: 'Enabled hours:',
    reload: 'Reload',
  },
  error: {
    label: 'Error: ',
    message: 'Request failed, try again later.',
    tryAgain: 'Try again',
    loginAgain: 'Log in with another user',
    notNow: 'Not Now',
    updateFailed: 'Update Failed.',
    generalWhatToDo:
      'You can contact the responsible department and communicate this error.',
  },
  success: {
    updatedSuccessfully: 'Updated Successfully!',
  },
  inProgress: {
    sending: 'Sending...',
    updating: 'Updating',
  },
  drawer: {
    defineSubstitute: 'Define Substitute',
    otherUserTitle: 'View Other User Documents',
    otherUserSearch: 'Search User',
    logs: 'Logs',
    about: 'About',
    settings: 'Settings',
    exit: 'Exit',
    exitConfirmationMessage:
      'Are you sure you want to quit? To return to using the Go Approve Plus, you will need to sign in again.',
    attention: 'Attention',
  },
  title: {
    documentTypes: 'Document Types',
    documentList: 'Documents',
    documentDetail: 'Details',
    authentication: 'Authentication',
  },
  button: {
    others: 'Others',
    send: 'Send',
    cancel: 'Cancel',
    ok: 'Ok',
    close: 'Close',
    showLog: 'Show Log',
    confirm: 'Confirm',
    apply: 'Apply',
    save: 'Save',
    delete: 'Delete',
    yes: 'Yes',
    no: 'No',
  },
  detail: {
    clickADocument: 'Select a document to view its details.',
    emptyDetail: 'No details found.',
    emptyItem: 'No items found.',
  },
  list: {
    clickADocumentType: 'Select a document type',
    noItems: 'There are no items to process at this time.',
    selectedToProcess: 'Selected to process',
    selectAll: 'Select All',
    unselectAll: 'Unselect All',
  },
  emptyList: {
    message: 'No items in this list',
    reload: 'Click here to reload',
  },
  template: {
    longText: 'Long text',
  },
  log: {
    documentActionTitle: 'Log',
    documentActionSuccess: 'Success',
    documentActionError: 'Error',
  },
  documentProcess: {
    enterPassword: 'Enter your password',
    passwordRequired: 'The password field is required.',
    documentProcess: 'Document process',
    processedDocument: `{count} {count, plural,
      one {successfully processed!}
      other {processed successfully!}}`,
    userAlreadyAuthentcated: 'Your user will be automatically authenticated',
  },
  header: {
    searchPlaceholder: 'Enter here',
  },
  otherUser: {
    searchDialogTitle: 'Search User',
    searchDialogInputLabel: 'Name or email',
    searchDialogSearchButton: 'Search',
    searchDialogCloseButton: 'Close',
    searchDialogNoUserFound: 'No user found',
    searchDialogActivatingMessage: 'Checking permissions...',
    searchResultItemActivate: 'Activate',
    stopDialogMessage:
      'Do you wish to stop viewing the documents from the user "{name}"?',
    stopDialogCancel: 'Cancel',
    stopDialogStopViewing: 'Stop viewing',
    indicatorBarMessage: 'Viewing documents from: {name}',
  },
  about: {
    version: 'Version',
    developedBy: 'Developed by',
    goApproveIntro: 'GO APPROVE PLUS IS AN APP FROM',
    goAppsIntro: 'GO APPS - PRODUCTIVITY TOOLS.',
  },
  settings: {
    language: 'Language',
    english: 'English',
    portuguese: 'Portuguese',
    spanish: 'Spanish',
    general: 'General',
    timeout: 'Timeout',
    seconds: 'seconds',
    security: 'Security',
    goFaceId: 'Go Face ID',
  },
  filters: {
    title: 'Filters',
    createFilter: 'Create Filters',
    emptyFilters: 'No created filters',
    enterFilterValue: 'Enter the field value',
    createNewFilter: 'Create new filter',
    selectField: 'Select the field',
    editFilter: 'Edit filter',
    deleteFilter: 'Delete filter',
    deleteFilterTitle: 'Delete filter?',
    deleteFilterMessage: 'Are you sure you want to delete this filter?',
    deleteFilterTip:
      'Tip: You can disable it if you want to use this filter later.',
    cleanFilters: 'Clean filters',
    unactiveAllFilters: 'Unactivate all filters',
    unactiveAllFiltersMessage:
      'Are you sure you want to unactivate all filters to this document type?',
  },
  appLogs: {
    documentId: 'Document key',
    fieldName: 'Field name',
    fileExtension: 'File extension',
    logDetails: 'Log Details',
    goApproveCode: 'Error code',
    agironCode: 'Error code',
    translatedMessage: 'Message',
    otherActiveUserName: 'Viewing by',
    documentSummary: 'Summary',
    documentType: 'Document type',
    documentList: 'List',
    documentProcess: 'Document process',
    documentDetail: 'Detail',
    attachmentList: 'Attachment list',
    attachmentDownload: 'Attachment download',
    unknown: 'Unknown',
    itemError: `{count} {count, plural,
      one {Error}
      other {Errors}}`,
    noLogs: 'There is no registered log.',
    attention: 'Attention!',
    deleteLogModalMsg: 'Do you want to permanently delete this record?',
    deleteLogsSuccess: `{count, plural,
      one {Deleted successfully}
      other {Deleted successfully}}`,
    deleteLogsError: 'Error deleting',
    deleteAllLogsMessage: 'Do you want to permanently delete all records?',
  },
  goFace: {
    authWithFace: 'AUTHENTICATE WITH FACIAL BIOMETRY',
    registerFace: 'REGISTER FACIAL BIOMETRY',
    registerFaceTitle: 'Face registration',
    prepositionOf: 'of',
    userRegistered: 'User configured!',
    userRegisteredMessage:
      'You can now use Go Face ID to process your documents.',
    registerFaceMessage:
      'You can now process documents with Face Recognition, even if your device does not have this feature.',
    configureFaceMessage: `Take {count, plural,
        one {# photo}
        other {# photos}
      } of your face`,
    authFaceTitle: 'Go Face ID Authentication',
    authError: 'Authentication Error',
    okButton: 'OK',
    cancelButton: 'CANCEL',
    facePermissionTitle: 'Permission to use camera',
    facePermissionMessage: 'We need your permission to use your camera',
    askStatusOneFace: 'Keep just your face in the image',
    askStatusFaceOnMark: 'Position your face on the markup',
    askStatusDone: 'Successfully completed!',
    askStatusSmile: 'Please, smile',
    askStatusBlink: 'Please, blink',
    askStatusTurn: 'Please, gently turn your face',
    askStatusDetecting: 'Detecting face...',
    configure: 'SET UP',
    cameraDisabledTitle: 'Waiting for permission',
    cameraDisabledMessage:
      'To use Go Face ID, you need to allow permissions to use your camera.',
    noCameraTitle: 'No cameras found',
    noCameraMessage:
      'To authenticate with Go Face ID a webcam is required and it must be enabled.',
    passwdAuthMessage:
      'Please authenticate with your password or contact support if password authentication is not available.',
    noCameraHelp: 'To enable the camera, follow ',
    noCameraHelpLink: 'these steps',
  },
  goApproveError: {
    [SummaryTemplateError.goApproveCode]: 'Summary template error',
    [SummaryTemplateRenderError.goApproveCode]:
      'There was a problem rendering the summary',
    [SummaryTemplateNotFoundError.goApproveCode]: 'No summary template found',
    [SummaryTemplateHasNoStructureError.goApproveCode]:
      'Summary template has no structure',
    [SummaryTemplateHasNoLayoutError.goApproveCode]:
      'Summary template structure has no layout',
    [ListTemplateError.goApproveCode]: 'List template error',
    [ListTemplateRenderError.goApproveCode]:
      'There was a problem rendering the document list',
    [ListTemplateNotFoundError.goApproveCode]: 'No list template found',
    [ListTemplateHasNoStructureError.goApproveCode]:
      'List template has no structure',
    [ListTemplateHasNoLayoutError.goApproveCode]:
      'List template structure has no layout',
    [DocumentTypesUnableToGetError.goApproveCode]:
      'Unable to get document types',
    [SummaryUnableToGetError.goApproveCode]: 'Unable to get summary',
    [DocumentListUnableToGetError.goApproveCode]: 'Unable to get document list',
    [DocumentDetailUnableToGetError.goApproveCode]:
      'Unable to get document detail',
    [DocumentFileListUnableToGetError.goApproveCode]: 'Unable to get file list',
    [DocumentFileUnableToGetError.goApproveCode]: 'Unable to get file',
    [DetailTemplateNotFoundError.goApproveCode]: 'No detail template found',
    [DetailTemplateHasNoStructureError.goApproveCode]:
      'Detail template has no structure',
    [DetailDetailTabTemplateRenderError.goApproveCode]:
      'There was a problem rendering the document detail',
    [DetailDetailTabTemplateStructureHasNoLayout.goApproveCode]:
      'Detail template structure has no layout',
    [DetailListTabTemplateRenderError.goApproveCode]:
      'There was a problem rendering this list',
    [DetailListTabTemplateStructureHasNoLayout.goApproveCode]:
      'List template structure has no layout',
    [DetailFileTemplateRenderError.goApproveCode]:
      'There was a problem rendering the file list',
    [DetailFileTemplateStructureHasNoLayout.goApproveCode]:
      'File list template structure has no layout',
    [DetailTemplateTypeNotFoundError.goApproveCode]:
      'Estrutura do template não possui um tipo definido',
    [ActionTemplateRenderError.goApproveCode]:
      'There was a problem rendering the action fields',
    [ActionTemplateNotFoundError.goApproveCode]: 'No action template found',
    [ActionTemplateHasNoStructureError.goApproveCode]:
      'Action template has no structure',
    [ActionTemplateHasNoLayoutError.goApproveCode]:
      'Action template structure has no layout',
    [ActionTemplateHasNoKeyError.goApproveCode]:
      'Action template structure has no key',
    [NoConnectionError.goApproveCode]: 'No network connection',
    [OtherUserNoPermission.goApproveCode]: 'User does not have permission',
    [MicrosoftAuthenticationError.goApproveCode]:
      'There was a problem authenticating with the Microsoft account',
    [MicrosoftAuthenticationNotPermission.goApproveCode]:
      'Your company or user does not have authentication via Microsoft account',
    [MicrosoftAuthenticationEmailNotMatch.goApproveCode]:
      'Microsoft email address does not match the email address registered on the platform.',
    [MicrosoftAuthenticationPopupError.goApproveCode]:
      'Error opening popup window. This can happen if you are using IE or if popups are blocked in the browser.',
    [MicrosoftAuthenticationUserCancelledPopup.goApproveCode]:
      'Click the next button to proceed with authentication.',
    [TimeoutError.goApproveCode]:
      'The server did not respond within the expected time and the request has expired',
    [UnknownRequestError.goApproveCode]: 'Unknown request error',
  },
  booleanOption: {
    enabled: 'Enabled',
    disabled: 'Disabled',
  },
};

export default en;
