import {
  FETCH_DOCUMENT_SUMMARY_REQUEST,
  FETCH_DOCUMENT_SUMMARY_SUCCESS,
  FETCH_DOCUMENT_SUMMARY_ERROR,
} from '../../_actions/documentSummary';

const fetchError = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_SUMMARY_REQUEST:
    case FETCH_DOCUMENT_SUMMARY_SUCCESS:
      return {
        ...state,
        [action.id]: null,
      };
    case FETCH_DOCUMENT_SUMMARY_ERROR:
      return {
        ...state,
        [action.id]: action.error,
      };
    default:
      return state;
  }
};

export const getFetchError = (state, id) => {
  const error = state.fetchError[id];
  if (error === undefined) {
    return null;
  }

  return error;
};

export default fetchError;
