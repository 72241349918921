import * as deviceApi from '../../utils/api/device';

export const REGISTER_DEVICE_REQUEST = 'REGISTER_DEVICE_REQUEST';
export const REGISTER_DEVICE_SUCCESS = 'REGISTER_DEVICE_SUCCESS';
export const REGISTER_DEVICE_ERROR = 'REGISTER_DEVICE_ERROR';
export const FETCH_DEVICE_STATUS_REQUEST = 'FETCH_DEVICE_STATUS_REQUEST';
export const FETCH_DEVICE_STATUS_SUCCESS = 'FETCH_DEVICE_STATUS_SUCCESS';
export const FETCH_DEVICE_STATUS_ERROR = 'FETCH_DEVICE_STATUS_ERROR';
export const FETCH_DEVICE_REQUEST = 'FETCH_DEVICE_REQUEST';
export const FETCH_DEVICE_SUCCESS = 'FETCH_DEVICE_SUCCESS';
export const FETCH_DEVICE_ERROR = 'FETCH_DEVICE_ERROR';
export const UPDATE_DEVICE_LANGUAGE_REQUEST = 'UPDATE_DEVICE_LANGUAGE_REQUEST';
export const UPDATE_DEVICE_LANGUAGE_SUCCESS = 'UPDATE_DEVICE_LANGUAGE_SUCCESS';
export const UPDATE_DEVICE_LANGUAGE_ERROR = 'UPDATE_DEVICE_LANGUAGE_ERROR';
export const UPDATE_DEVICE_TIMEZONE_REQUEST = 'UPDATE_DEVICE_TIMEZONE_REQUEST';
export const UPDATE_DEVICE_TIMEZONE_SUCCESS = 'UPDATE_DEVICE_TIMEZONE_SUCCESS';
export const UPDATE_DEVICE_TIMEZONE_ERROR = 'UPDATE_DEVICE_TIMEZONE_ERROR';
export const CLEAR_WORK_TIME_ACCESS_ERROR = 'CLEAR_WORK_TIME_ACCESS_ERROR';
export const UPDATE_DEVICE_TIMEOUT_REQUEST = 'UPDATE_DEVICE_TIMEOUT_REQUEST';
export const UPDATE_DEVICE_TIMEOUT_SUCCESS = 'UPDATE_DEVICE_TIMEOUT_SUCCESS';
export const UPDATE_DEVICE_TIMEOUT_ERROR = 'UPDATE_DEVICE_TIMEOUT_ERROR';
export const UPDATE_DEVICE_GO_FACE_ID_ENABLED_REQUEST =
  'UPDATE_DEVICE_GO_FACE_ID_ENABLED_REQUEST';
export const UPDATE_DEVICE_GO_FACE_ID_ENABLED_SUCCESS =
  'UPDATE_DEVICE_GO_FACE_ID_ENABLED_SUCCESS';
export const UPDATE_DEVICE_GO_FACE_ID_ENABLED_ERROR =
  'UPDATE_DEVICE_GO_FACE_ID_ENABLED_ERROR';

export const registerDevice = (
  os,
  browserName,
  browserVersion,
  email,
  timezone,
  ipAddress
) => async dispatch => {
  dispatch(registerDeviceRequest());
  const response = await deviceApi
    .registerDevice(os, browserName, browserVersion, email, timezone, ipAddress)
    .catch(error => {
      dispatch(registerDeviceError(error));
    });

  if (response) {
    dispatch(registerDeviceSuccess(response));
  }
};

export const fetchDeviceStatus = () => async dispatch => {
  dispatch(fetchDeviceStatusRequest());
  const response = await deviceApi.getDeviceStatus().catch(error => {
    dispatch(fetchDeviceStatusError(error));
  });

  if (response) {
    dispatch(fetchDeviceStatusSuccess(response));
  }
};

export const updateDeviceLanguage = language => async dispatch => {
  dispatch(updateDeviceLanguageRequest());
  const response = await deviceApi.updateLanguage(language).catch(error => {
    dispatch(updateDeviceLanguageError(error));
  });

  if (response) {
    dispatch(updateDeviceLanguageSuccess(response));
  }
};

export const updateDeviceTimezone = timezone => async dispatch => {
  dispatch(updateDeviceTimezoneRequest());
  const response = await deviceApi.updateTimezone(timezone).catch(error => {
    dispatch(updateDeviceTimezoneError(error));
  });

  if (response) {
    dispatch(updateDeviceTimezoneSuccess(response));
  }
};

export const fetchDevice = () => async dispatch => {
  dispatch(fetchDeviceRequest());
  const response = await deviceApi.getDevice().catch(error => {
    dispatch(fetchDeviceError(error));
  });

  if (response) {
    dispatch(fetchDeviceSuccess(response));
  }
};

export const fetchDeviceInfo = () => {};

export const updateTimeout = timeout => async dispatch => {
  dispatch(updateDeviceTimeoutRequest());
  const response = await deviceApi.updateTimeout(timeout).catch(error => {
    dispatch(updateDeviceTimeoutError(error));
  });

  if (response) {
    dispatch(updateDeviceTimeoutSuccess(response));
  }
};

export const updateDeviceGoFaceIdEnabled = goFaceIdEnabled => async dispatch => {
  dispatch(updateDeviceGoFaceIdEnabledRequest());
  const response = await deviceApi
    .updateGoFaceIdEnabled(goFaceIdEnabled)
    .catch(error => {
      dispatch(updateDeviceGoFaceIdEnabledError(error));
    });

  if (response) {
    dispatch(updateDeviceGoFaceIdEnabledSuccess(response));
  }
};

export const clearWorkTimeAccessError = () => ({
  type: CLEAR_WORK_TIME_ACCESS_ERROR,
});

export const registerDeviceRequest = () => ({
  type: REGISTER_DEVICE_REQUEST,
});

export const registerDeviceSuccess = response => ({
  type: REGISTER_DEVICE_SUCCESS,
  response,
});

export const registerDeviceError = error => ({
  type: REGISTER_DEVICE_ERROR,
  error,
});

export const fetchDeviceStatusRequest = () => ({
  type: FETCH_DEVICE_STATUS_REQUEST,
});

export const fetchDeviceStatusSuccess = response => ({
  type: FETCH_DEVICE_STATUS_SUCCESS,
  response,
});

export const fetchDeviceStatusError = error => ({
  type: FETCH_DEVICE_STATUS_ERROR,
  error,
});

export const fetchDeviceRequest = () => ({
  type: FETCH_DEVICE_REQUEST,
});

export const fetchDeviceSuccess = response => ({
  type: FETCH_DEVICE_SUCCESS,
  response,
});

export const fetchDeviceError = error => ({
  type: FETCH_DEVICE_ERROR,
  error,
});

export const updateDeviceLanguageRequest = () => ({
  type: UPDATE_DEVICE_LANGUAGE_REQUEST,
});

export const updateDeviceLanguageSuccess = response => ({
  type: UPDATE_DEVICE_LANGUAGE_SUCCESS,
  response,
});

export const updateDeviceLanguageError = error => ({
  type: UPDATE_DEVICE_LANGUAGE_ERROR,
  error,
});

export const updateDeviceTimezoneRequest = () => ({
  type: UPDATE_DEVICE_TIMEZONE_REQUEST,
});

export const updateDeviceTimezoneSuccess = response => ({
  type: UPDATE_DEVICE_TIMEZONE_SUCCESS,
  response,
});

export const updateDeviceTimezoneError = error => ({
  type: UPDATE_DEVICE_TIMEZONE_ERROR,
  error,
});

export const updateDeviceTimeoutRequest = () => ({
  type: UPDATE_DEVICE_TIMEOUT_REQUEST,
});

export const updateDeviceTimeoutSuccess = response => ({
  type: UPDATE_DEVICE_TIMEOUT_SUCCESS,
  response,
});

export const updateDeviceTimeoutError = error => ({
  type: UPDATE_DEVICE_TIMEOUT_ERROR,
  error,
});

export const updateDeviceGoFaceIdEnabledRequest = () => ({
  type: UPDATE_DEVICE_GO_FACE_ID_ENABLED_REQUEST,
});

export const updateDeviceGoFaceIdEnabledSuccess = response => ({
  type: UPDATE_DEVICE_GO_FACE_ID_ENABLED_SUCCESS,
  response,
});

export const updateDeviceGoFaceIdEnabledError = error => ({
  type: UPDATE_DEVICE_GO_FACE_ID_ENABLED_ERROR,
  error,
});
