import {normalize} from 'normalizr';
import {arrayOfDocuments} from '../../_actions/schema';
import * as documentListApi from '../../utils/api/documentList';
import {parseDocumentList} from '../../utils/template';
import {getDocumentType} from '../../_reducers/documentType';
import {getDocumentListIds} from '../../_reducers/documentList';
import {getActiveOtherUser} from '../../_reducers/otherUser';
import {DocumentListUnableToGetError} from '../../config/messageCodes';
import {parseError} from '../../utils/agiron';
import {AppLogType} from '../../utils/appLog/types';
import {createAppLog} from '../appLog';

export const FETCH_DOCUMENT_LIST_REQUEST = 'FETCH_DOCUMENT_LIST_REQUEST';
export const FETCH_DOCUMENT_LIST_SUCCESS = 'FETCH_DOCUMENT_LIST_SUCCESS';
export const FETCH_DOCUMENT_LIST_ERROR = 'FETCH_DOCUMENT_LIST_ERROR';
export const CLEAR_FETCH_DOCUMENT_LIST_ERROR =
  'CLEAR_FETCH_DOCUMENT_LIST_ERROR';
export const CLEAR_ALL_FETCH_DOCUMENT_LIST_ERRORS =
  'CLEAR_ALL_FETCH_DOCUMENT_LIST_ERRORS';
export const SELECT_DOCUMENT = 'SELECT_DOCUMENT';
export const UNSELECT_DOCUMENT = 'UNSELECT_DOCUMENT';
export const SELECT_ALL_DOCUMENTS = 'SELECT_ALL_DOCUMENTS';
export const SELECT_DOCUMENTS = 'SELECT_DOCUMENTS';
export const UNSELECT_ALL_DOCUMENTS = 'UNSELECT_ALL_DOCUMENTS';
export const RESET_DOCUMENT_LIST = 'RESET_DOCUMENT_LIST';

export const fetchDocumentList = documentTypeId => async (
  dispatch,
  getState
) => {
  const state = getState();
  const user = getActiveOtherUser(state);
  dispatch(fetchDocumentListRequest(documentTypeId));
  const response = await documentListApi
    .fetchDocumentList(documentTypeId, user)
    .catch(error => {
      const parsedError = parseError(error, DocumentListUnableToGetError);
      createAppLog({
        requestType: AppLogType.DOCUMENT_LIST,
        documentTypeId,
        details: {otherActiveUser: user, error: parsedError},
      })(dispatch, getState);
      dispatch(fetchDocumentListError(documentTypeId, parsedError));
    });

  if (response) {
    const documentType = getDocumentType(getState(), documentTypeId);
    const parsedDocumentList = parseDocumentList(response, documentType);
    const normalizedData = normalize(parsedDocumentList, arrayOfDocuments);
    dispatch(fetchDocumentListSuccess(documentTypeId, normalizedData));
  }
};

export const selectAllDocuments = documentTypeId => async (
  dispatch,
  getState
) => {
  const state = getState();
  const ids = getDocumentListIds(state, documentTypeId);
  dispatch({
    type: SELECT_ALL_DOCUMENTS,
    documentTypeId,
    ids,
  });
};

export const doClearAllFetchDocumentListErrors = () => async dispatch => {
  dispatch(clearAllFetchDocumentListErrors());
};

export const selectDocuments = (documentTypeId, ids) => async dispatch => {
  dispatch({
    type: SELECT_DOCUMENTS,
    documentTypeId,
    ids,
  });
};

export const doResetDocumentList = () => async dispatch => {
  dispatch(resetDocumentList());
};

export const fetchDocumentListRequest = documentTypeId => ({
  type: FETCH_DOCUMENT_LIST_REQUEST,
  documentTypeId,
});

export const fetchDocumentListSuccess = (documentTypeId, response) => ({
  type: FETCH_DOCUMENT_LIST_SUCCESS,
  documentTypeId,
  response,
});

export const fetchDocumentListError = (documentTypeId, error) => ({
  type: FETCH_DOCUMENT_LIST_ERROR,
  documentTypeId,
  error,
});

export const clearFetchDocumentListError = documentTypeId => ({
  type: CLEAR_FETCH_DOCUMENT_LIST_ERROR,
  documentTypeId,
});

export const clearAllFetchDocumentListErrors = () => ({
  type: CLEAR_ALL_FETCH_DOCUMENT_LIST_ERRORS,
});

export const selectDocument = (documentTypeId, id) => ({
  type: SELECT_DOCUMENT,
  documentTypeId,
  id,
});

export const unselectDocument = (documentTypeId, id) => ({
  type: UNSELECT_DOCUMENT,
  documentTypeId,
  id,
});

export const unselectAllDocuments = documentTypeId => ({
  type: UNSELECT_ALL_DOCUMENTS,
  documentTypeId,
});

export const resetDocumentList = () => ({
  type: RESET_DOCUMENT_LIST,
});
