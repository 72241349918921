import {
  FETCH_OTHER_USERS_REQUEST,
  FETCH_OTHER_USERS_SUCCCESS,
  FETCH_OTHER_USERS_ERROR,
} from '../../_actions/otherUser';

const isFetchingOtherUsers = (state = false, action) => {
  switch (action.type) {
    case FETCH_OTHER_USERS_REQUEST:
      return true;
    case FETCH_OTHER_USERS_SUCCCESS:
    case FETCH_OTHER_USERS_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsFetchingOtherUsers = state => state.isFetchingOtherUsers;

export default isFetchingOtherUsers;
