import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  getIsChangingPassword,
  getChangePasswordError,
} from '../_reducers/authentication';

const ChangePassContext = React.createContext({});

const ChangePassProviderBase = ({
  children,
  isChangingPassword,
  changePasswordError,
}) => (
  <ChangePassContext.Provider value={{isChangingPassword, changePasswordError}}>
    {children}
  </ChangePassContext.Provider>
);

ChangePassProviderBase.propTypes = {
  children: PropTypes.element,
  isChangingPassword: PropTypes.bool,
  changePasswordError: PropTypes.object,
};

// Connects the provider component with redux and retrieves the
// values from the Redux store.
export const ChangePassProvider = connect(state => {
  return {
    isChangingPassword: getIsChangingPassword(state),
    changePasswordError: getChangePasswordError(state),
  };
})(ChangePassProviderBase);

export const useChangePassContext = () => useContext(ChangePassContext);
