import React from 'react';
import PropTypes from 'prop-types';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {getUserHasSession} from '../../_reducers/authentication';

const PrivateRoute = ({component: Component, hasSession, ...rest}) => (
  <Route
    {...rest}
    render={props =>
      hasSession === true ? (
        <Component {...props} />
      ) : (
        <Redirect to="/authentication" />
      )
    }
  />
);

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  component: PropTypes.any,
  hasSession: PropTypes.bool,
};

export default connect(state => {
  return {
    hasSession: getUserHasSession(state),
  };
}, null)(PrivateRoute);
