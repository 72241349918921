import React from 'react';
import {Route, BrowserRouter, Switch, Redirect} from 'react-router-dom';
import Loadable from 'react-loadable';
import LoadingBlank from './components/loading/LoadingBlank';
import PrivateRoute from './components/PrivateRoute';

const AuthenticationScreen = Loadable({
  loader: () => import('./screens/AuthenticationScreen'),
  loading: LoadingBlank,
});

const HomeScreen = Loadable({
  loader: () => import('./screens/HomeScreen'),
  loading: LoadingBlank,
});

const RouterConfig = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/authentication" component={AuthenticationScreen} />
      <PrivateRoute
        path="/dt/:documentTypeId?/:documentId?"
        component={HomeScreen}
      />
      <Redirect to="/dt" />
    </Switch>
  </BrowserRouter>
);

export default RouterConfig;
