import {
  WORK_TIME_ACCESS_ERROR_CODE,
  WORK_TIME_ACCESS_AUTH_ERROR_CODE,
} from '../config/constants';

export const WORK_TIME_ACCESS_ERROR = 'WORK_TIME_ACCESS_ERROR';

export const detectWorkTimeAccessError = ({dispatch}) => next => action => {
  if (isWorkTimeAccessError(action.error)) {
    dispatch({
      type: WORK_TIME_ACCESS_ERROR,
      workTimeError: action.error,
    });
  }

  next(action);
};

const isWorkTimeAccessError = error => {
  return (
    error &&
    (error.agironCode === WORK_TIME_ACCESS_ERROR_CODE ||
      error.agironCode === WORK_TIME_ACCESS_AUTH_ERROR_CODE)
  );
};
