import {PROCESS_DOCUMENTS_SUCCESS} from '../../_actions/processDocument';

const ids = (state = [], action) => {
  switch (action.type) {
    case PROCESS_DOCUMENTS_SUCCESS:
      return [...state, action.log.id];
    default:
      return state;
  }
};

export const getIds = state => state.ids;

export default ids;
