import uuidv4 from 'uuid/v4';

// Log types
export const LOG_TYPE_DOCUMENT_ACTION = 'LOG_TYPE_DOCUMENT_ACTION';
export const LOG_TYPE_MESSAGE = 'LOG_TYPE_MESSAGE';

const createLogId = () => uuidv4();

const createLogDefaultParams = type => ({
  id: createLogId(),
  timestamp: new Date(),
  type,
});

export const createDocumentActionLog = (documentTypeId, action, result) => ({
  ...createLogDefaultParams(LOG_TYPE_DOCUMENT_ACTION),
  result,
  action,
  documentTypeId,
});

export const createMessageLog = text => ({
  ...createLogDefaultParams(LOG_TYPE_MESSAGE),
  text,
});
