import {
  FETCH_OTHER_USERS_REQUEST,
  FETCH_OTHER_USERS_SUCCCESS,
  FETCH_OTHER_USERS_ERROR,
} from '../../_actions/otherUser';

const fetchOtherUsersError = (state = null, action) => {
  switch (action.type) {
    case FETCH_OTHER_USERS_REQUEST:
    case FETCH_OTHER_USERS_SUCCCESS:
      return null;
    case FETCH_OTHER_USERS_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getFetchOtherUsersError = state => state.fetchOtherUsersError;

export default fetchOtherUsersError;
