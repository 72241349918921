import {
  CREATE_PERSON_REQUEST,
  CREATE_PERSON_SUCCESS,
  CREATE_PERSON_ERROR,
} from '../../_actions/faceAuthentication';

const createPersonError = (state = null, action) => {
  switch (action.type) {
    case CREATE_PERSON_REQUEST:
    case CREATE_PERSON_SUCCESS:
      return null;
    case CREATE_PERSON_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getCreatePersonError = state => state.createPersonError;

export default createPersonError;
