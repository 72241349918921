import {combineReducers} from 'redux';
import person, * as fromPerson from './person';
import isFetchingPerson, * as fromIsFetchingPerson from './isFetchingPerson';
import fetchPersonError, * as fromFetchPersonError from './fetchPersonError';
import isCreatingPerson, * as fromIsCreatingPerson from './isCreatingPerson';
import createPersonError, * as fromCreatePersonError from './createPersonError';

import faceCreated, * as fromFaceCreated from './faceCreated';
import isCreatingFace, * as fromIsCreatingFace from './isCreatingFace';
import createFaceError, * as fromCreateFaceError from './createFaceError';

import isAuthenticated, * as fromIsAuthenticated from './isAuthenticated';
import isAuthenticating, * as fromIsAuthenticating from './isAuthenticating';
import authenticationError, * as fromAuthenticationError from './authenticationError';

const getLocalState = state => state.faceAuthentication;

export default combineReducers({
  isCreatingFace,
  isCreatingPerson,
  createFaceError,
  createPersonError,
  faceCreated,
  isFetchingPerson,
  fetchPersonError,
  person,
  isAuthenticated,
  isAuthenticating,
  authenticationError,
});

export const getPerson = state => fromPerson.getPerson(getLocalState(state));

export const getIsFetchingPerson = state =>
  fromIsFetchingPerson.getIsFetchingPerson(getLocalState(state));

export const getFetchPersonError = state =>
  fromFetchPersonError.getFetchPersonError(getLocalState(state));

export const getIsCreatingPerson = state =>
  fromIsCreatingPerson.getIsCreatingPerson(getLocalState(state));

export const getCreatePersonError = state =>
  fromCreatePersonError.getCreatePersonError(getLocalState(state));

export const getFaceCreated = state =>
  fromFaceCreated.getFaceCreated(getLocalState(state));

export const getIsCreatingFace = state =>
  fromIsCreatingFace.getIsCreatingFace(getLocalState(state));

export const getCreateFaceError = state =>
  fromCreateFaceError.getCreateFaceError(getLocalState(state));

export const getIsAuthenticated = state =>
  fromIsAuthenticated.getIsAuthenticated(getLocalState(state));

export const getIsAuthenticating = state =>
  fromIsAuthenticating.getIsAuthenticating(getLocalState(state));

export const getAuthenticationError = state =>
  fromAuthenticationError.getAuthenticationError(getLocalState(state));
