import {FETCH_USER_ASSIGN_SUCCESS} from '../../_actions/userAssign';

const userGroupConfigs = (state = null, action) => {
  switch (action.type) {
    case FETCH_USER_ASSIGN_SUCCESS:
      return action.response.user_assign.user_group.configuration;
    default:
      return state;
  }
};

export const getUserGroupConfigs = state => state.userGroupConfigs;

export default userGroupConfigs;
