import React from 'react';
import PropTypes from 'prop-types';
import AgironError from './AgironError';
import DefaultError from './DefaultError';
import GoApproveError from './GoApproveError';

const isAgironError = error => error && typeof error.agironCode === 'string';

const isGoApproveError = error =>
  error && typeof error.goApproveCode === 'string';

const ErrorMessage = props => {
  let errorMessage = null;

  if (isAgironError(props.error)) {
    errorMessage = <AgironError {...props} />;
  } else if (isGoApproveError(props.error)) {
    errorMessage = <GoApproveError {...props} />;
  } else if (props.error) {
    errorMessage = <DefaultError {...props} />;
  }

  return errorMessage;
};

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  textStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  showLabel: PropTypes.bool,
};

export default ErrorMessage;
