import {
  FETCH_PERSON_SUCCESS,
  CREATE_PERSON_SUCCESS,
  CREATE_FACE_SUCCESS,
  DELETE_PERSON_SUCCESS,
} from '../../_actions/faceAuthentication';

const person = (state = null, action) => {
  switch (action.type) {
    case FETCH_PERSON_SUCCESS:
      return action.response.person;
    case CREATE_PERSON_SUCCESS:
      return {
        name: action.response.name,
        personId: action.response.user_face_id,
        persistedFaceIds: [],
      };
    case CREATE_FACE_SUCCESS:
      return {
        ...state,
        persistedFaceIds: [...state.persistedFaceIds, action.response.faceId],
      };
    case DELETE_PERSON_SUCCESS:
      return null;
    default:
      return state;
  }
};

export const getPerson = state => state.person;

export default person;
