import {useRef, useEffect} from 'react';
import {useEffectSkipRender} from './useEffectSkipRender';
import {useChangePassContext} from '../context/changePassContext';

export const useOnChangePasswordError = onError => {
  const {changePasswordError} = useChangePassContext();
  let ref = useRef({changePasswordError, count: 0});

  let count = ref.current.count;

  if (!ref.current.changePasswordError && changePasswordError) {
    count++;
  }

  useEffect(() => {
    ref.current = {
      ...ref.current,
      count,
      changePasswordError,
    };
  });

  useEffectSkipRender(onError, [count]);
};
