import {
  FETCH_MICROSOFT_SYSTEM_REQUEST,
  FETCH_MICROSOFT_SYSTEM_SUCCESS,
  FETCH_MICROSOFT_SYSTEM_ERROR,
} from '../../../_actions/microsoft/system';

const isFetchingMicrosoftSystem = (state = false, action) => {
  switch (action.type) {
    case FETCH_MICROSOFT_SYSTEM_REQUEST:
      return true;
    case FETCH_MICROSOFT_SYSTEM_SUCCESS:
    case FETCH_MICROSOFT_SYSTEM_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsFetchingMicrosoftSystem = state =>
  state.isFetchingMicrosoftSystem;

export default isFetchingMicrosoftSystem;
