import callApi from './callApi';

export const fetchDocumentDetail = async (documentTypeId, documentId, user) => {
  const response = await callApi(
    'GET',
    `/document_types/${documentTypeId}/documents/${documentId}`,
    undefined,
    user
  );
  return response.document_detail;
};
