import {getLocale} from '../../utils/locale';
import {
  FETCH_DEVICE_SUCCESS,
  UPDATE_DEVICE_LANGUAGE_SUCCESS,
  UPDATE_DEVICE_TIMEZONE_SUCCESS,
  UPDATE_DEVICE_TIMEOUT_SUCCESS,
  UPDATE_DEVICE_GO_FACE_ID_ENABLED_SUCCESS,
} from '../../_actions/device';

const initialState = {
  language: getLocale().toUpperCase(),
};

const device = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEVICE_SUCCESS:
    case UPDATE_DEVICE_LANGUAGE_SUCCESS:
    case UPDATE_DEVICE_TIMEZONE_SUCCESS:
    case UPDATE_DEVICE_TIMEOUT_SUCCESS:
    case UPDATE_DEVICE_GO_FACE_ID_ENABLED_SUCCESS:
      return action.response;
    default:
      return state;
  }
};

export const getDevice = state => state.device;

export default device;
