import {
  VERIFY_TWOFA_PIN_REQUEST,
  VERIFY_TWOFA_PIN_ERROR,
  SEND_TWOFA_SMS_REQUEST,
} from '../../_actions/twofa';

const verifyTwoFaPinError = (state = null, action) => {
  switch (action.type) {
    case SEND_TWOFA_SMS_REQUEST:
    case VERIFY_TWOFA_PIN_REQUEST:
      return null;
    case VERIFY_TWOFA_PIN_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getVerifyTwoFaPinError = state => state.verifyTwoFaPinError;

export default verifyTwoFaPinError;
