import {
  CHANGE_PASS_FOR_SYSTEM_REQUEST,
  CHANGE_PASS_FOR_SYSTEM_SUCCESS,
  CHANGE_PASS_FOR_SYSTEM_ERROR,
  CLEAR_CHANGE_PASS_FOR_SYSTEM_ERROR,
} from '../../_actions/authentication';
import {FETCH_DOCUMENT_TYPES_REQUEST} from '../../_actions/documentType';
import {FETCH_DOCUMENT_LIST_REQUEST} from '../../_actions/documentList';
import {FETCH_DOCUMENT_FILE_LIST_REQUEST} from '../../_actions/documentFile';
import {PROCESS_DOCUMENTS_REQUEST} from '../../_actions/processDocument';

const changePasswordError = (state = null, action) => {
  switch (action.type) {
    case CHANGE_PASS_FOR_SYSTEM_REQUEST:
    case CHANGE_PASS_FOR_SYSTEM_SUCCESS:
    case FETCH_DOCUMENT_TYPES_REQUEST:
    case FETCH_DOCUMENT_LIST_REQUEST:
    case FETCH_DOCUMENT_FILE_LIST_REQUEST:
    case PROCESS_DOCUMENTS_REQUEST:
    case CLEAR_CHANGE_PASS_FOR_SYSTEM_ERROR:
      return null;
    case CHANGE_PASS_FOR_SYSTEM_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getChangePasswordError = state => state.changePasswordError;

export default changePasswordError;
