import {
  CHECK_AUTHENTICATION_REQUEST,
  CHECK_AUTHENTICATION_SUCCESS,
  CHECK_AUTHENTICATION_ERROR,
  AUTHENTICATE_USER_SUCCESS,
} from '../../_actions/authentication';
import {MICROSOFT_POP_UP_CLOSED} from '../../_actions/microsoft/auth';
import {APP_SHOULD_START_OVER} from '../../_middlewares/detectAppShouldStartOver';

const userHasSession = (state = null, action) => {
  switch (action.type) {
    case CHECK_AUTHENTICATION_REQUEST:
    case CHECK_AUTHENTICATION_ERROR:
      return null;
    case CHECK_AUTHENTICATION_SUCCESS:
      return action.response.status;
    case AUTHENTICATE_USER_SUCCESS:
      return action.response.authenticated;
    case APP_SHOULD_START_OVER:
      return false;
    case MICROSOFT_POP_UP_CLOSED:
      return false;
    default:
      return state;
  }
};

export const getUserHasSession = state => state.userHasSession;

export default userHasSession;
