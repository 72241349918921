import {APP_SHOULD_START_OVER} from '../../_middlewares/detectAppShouldStartOver';
import {AUTHENTICATE_USER_SUCCESS} from '../../_actions/authentication';
import {REGISTER_DEVICE_REQUEST} from '../../_actions/device';

const appShouldStartOver = (state = false, action) => {
  switch (action.type) {
    case APP_SHOULD_START_OVER:
      return true;
    case AUTHENTICATE_USER_SUCCESS:
    case REGISTER_DEVICE_REQUEST:
      return false;
    default:
      return state;
  }
};

export const getAppShouldStartOver = state => state.appShouldStartOver;

export default appShouldStartOver;
