import {PROCESS_DOCUMENTS_SUCCESS} from '../../_actions/processDocument';

const byId = (state = {}, action) => {
  switch (action.type) {
    case PROCESS_DOCUMENTS_SUCCESS:
      return {
        ...state,
        [action.log.id]: action.log,
      };
    default:
      return state;
  }
};

export const getById = (state, id) => state.byId[id];

export default byId;
