import {
  FETCH_APPEARANCE_REQUEST,
  FETCH_APPEARANCE_SUCCESS,
  FETCH_APPEARANCE_ERROR,
} from '../../_actions/appearance';

const isFetchingAppearance = (state = false, action) => {
  switch (action.type) {
    case FETCH_APPEARANCE_REQUEST:
      return true;
    case FETCH_APPEARANCE_SUCCESS:
    case FETCH_APPEARANCE_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsFetchingAppearance = state => state.isFetchingAppearance;

export default isFetchingAppearance;
