import {SESSION_FAILURE_ERROR_CODE} from '../config/constants';

const errorCodes = [SESSION_FAILURE_ERROR_CODE];

export const APP_SHOULD_START_OVER = 'APP_SHOULD_START_OVER';

export const detectAppShouldStartOver = ({dispatch}) => next => action => {
  if (checkAppShouldStartOver(action.error)) {
    dispatch({
      type: APP_SHOULD_START_OVER,
    });
  }

  next(action);
};

const checkAppShouldStartOver = error => {
  if (
    error &&
    (typeof error.agironCode === 'string' ||
      typeof error.goApproveCode === 'string')
  ) {
    return Boolean(
      errorCodes.find(
        errorCode =>
          errorCode === error.agironCode || errorCode === error.goApproveCode
      )
    );
  }

  return false;
};
