import {
  FETCH_DOCUMENT_DETAIL_REQUEST,
  FETCH_DOCUMENT_DETAIL_SUCCESS,
  FETCH_DOCUMENT_DETAIL_ERROR,
} from '../../_actions/documentDetail';

const isFetching = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_DETAIL_REQUEST:
      return {
        ...state,
        [action.documentTypeId]: {
          ...state[action.documentTypeId],
          [action.documentId]: true,
        },
      };
    case FETCH_DOCUMENT_DETAIL_SUCCESS:
    case FETCH_DOCUMENT_DETAIL_ERROR:
      return {
        ...state,
        [action.documentTypeId]: {
          ...state[action.documentTypeId],
          [action.documentId]: false,
        },
      };
    default:
      return state;
  }
};

export const getIsFetching = (state, documentTypeId, documentId) => {
  const byDocumentType = state.isFetching[documentTypeId];
  if (byDocumentType) {
    return byDocumentType[documentId];
  }

  return false;
};

export default isFetching;
