const RESOURCE_CODE = '2020';
export const TYPE_ERROR = 'error';
export const TYPE_WARNING = 'warn';
export const TYPE_INFO = 'info';

const makeCreateMessage = categoryCode => (
  goapproveCode,
  type = TYPE_ERROR
) => ({
  goApproveCode: `${RESOURCE_CODE}.${categoryCode}.${goapproveCode}`,
  type,
});

// Status
const createStatusMessage = makeCreateMessage('100');
export const NotAuthenticatedError = createStatusMessage('10.1');

// Template
const createTemplateError = makeCreateMessage('200');
export const SummaryTemplateError = createTemplateError('10');
export const SummaryTemplateRenderError = createTemplateError('10.1');
export const SummaryTemplateNotFoundError = createTemplateError('10.2');
export const SummaryTemplateHasNoStructureError = createTemplateError('10.3');
export const SummaryTemplateHasNoLayoutError = createTemplateError('10.4');
export const ListTemplateError = createTemplateError('20');
export const ListTemplateRenderError = createTemplateError('20.1');
export const ListTemplateNotFoundError = createTemplateError('20.2');
export const ListTemplateHasNoStructureError = createTemplateError('20.3');
export const ListTemplateHasNoLayoutError = createTemplateError('20.4');
export const DetailTemplateNotFoundError = createTemplateError(
  '30.1',
  TYPE_INFO
);
export const DetailTemplateHasNoStructureError = createTemplateError(
  '30.2',
  TYPE_INFO
);
export const DetailTemplateTypeNotFoundError = createTemplateError(
  '30.3',
  TYPE_INFO
);
export const DetailDetailTabTemplateRenderError = createTemplateError(
  '40.1',
  TYPE_INFO
);
export const DetailDetailTabTemplateStructureHasNoLayout = createTemplateError(
  '40.2',
  TYPE_INFO
);
export const DetailListTabTemplateRenderError = createTemplateError(
  '50.1',
  TYPE_INFO
);
export const DetailListTabTemplateStructureHasNoLayout = createTemplateError(
  '50.2',
  TYPE_INFO
);
export const DetailFileTemplateRenderError = createTemplateError(
  '60.1',
  TYPE_INFO
);
export const DetailFileTemplateStructureHasNoLayout = createTemplateError(
  '60.2',
  TYPE_INFO
);
export const ActionTemplateRenderError = createTemplateError('70.1');
export const ActionTemplateNotFoundError = createTemplateError('70.2');
export const ActionTemplateHasNoStructureError = createTemplateError('70.3');
export const ActionTemplateHasNoLayoutError = createTemplateError('70.4');
export const ActionTemplateHasNoKeyError = createTemplateError('70.5');

// Document
const createDocumentMessage = makeCreateMessage('300');
export const DocumentTypesUnableToGetError = createDocumentMessage('10.1');
export const SummaryUnableToGetError = createDocumentMessage('20.1');
export const DocumentListUnableToGetError = createDocumentMessage('30.1');
export const DocumentDetailUnableToGetError = createDocumentMessage('40.1');
export const DocumentFileListUnableToGetError = createDocumentMessage('40.2');
export const DocumentFileUnableToGetError = createDocumentMessage('40.3');

// Other user
const createOtherUserError = makeCreateMessage('500');
export const OtherUserNoPermission = createOtherUserError('10.1');

// Microsoft Auth
const createMicrosoftAuthError = makeCreateMessage('600');
export const MicrosoftAuthenticationError = createMicrosoftAuthError('10.1');
export const MicrosoftAuthenticationNotPermission = createMicrosoftAuthError(
  '10.2'
);
export const MicrosoftAuthenticationEmailNotMatch = createMicrosoftAuthError(
  '10.3'
);
export const MicrosoftAuthenticationPopupError = createMicrosoftAuthError(
  '10.4'
);
export const MicrosoftAuthenticationUserCancelledPopup = createMicrosoftAuthError(
  '10.5'
);

// Connection error
const createConnectionError = makeCreateMessage('900');
export const NoConnectionError = createConnectionError('10.1');
export const TimeoutError = createConnectionError('10.2');
export const ApiCallErrorWithoutBodyError = createConnectionError('10.3');
export const NotAllowedError = createConnectionError('10.5');
export const UnknownRequestError = createConnectionError('10.6');
