import _ from 'lodash';
import {FETCH_DOCUMENT_LIST_SUCCESS} from '../../_actions/documentList';
import {PROCESS_DOCUMENTS_SUCCESS} from '../../_actions/processDocument';
import {WORK_TIME_ACCESS_ERROR} from '../../_middlewares/detectWorkTimeAccessError';
const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        [action.documentTypeId]: action.response.entities.document,
      };
    case PROCESS_DOCUMENTS_SUCCESS:
      return {
        ...state,
        [action.documentTypeId]: _.omit(
          state[action.documentTypeId],
          action.keys
        ),
      };
    case WORK_TIME_ACCESS_ERROR:
      return {};

    default:
      return state;
  }
};

export const getDocumentById = (state, documentTypeId, id) =>
  state.byId[documentTypeId][id];

export default byId;
