import {
  FETCH_APP_LOG_REQUEST,
  FETCH_APP_LOG_SUCCESS,
  FETCH_APP_LOG_ERROR,
} from '../../_actions/appLog';

const fetchError = (state = null, action) => {
  switch (action.type) {
    case FETCH_APP_LOG_REQUEST:
    case FETCH_APP_LOG_SUCCESS:
      return null;
    case FETCH_APP_LOG_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getFetchError = state => state.fetchError;

export default fetchError;
