import {
  FACE_AUTHENTICATE_USER_REQUEST,
  FACE_AUTHENTICATE_USER_SUCCESS,
  FACE_AUTHENTICATE_USER_ERROR,
} from '../../_actions/faceAuthentication';

const isAuthenticating = (state = false, action) => {
  switch (action.type) {
    case FACE_AUTHENTICATE_USER_REQUEST:
      return true;
    case FACE_AUTHENTICATE_USER_SUCCESS:
    case FACE_AUTHENTICATE_USER_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsAuthenticating = state => state.isAuthenticating;

export default isAuthenticating;
