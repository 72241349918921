import {FETCH_DOCUMENT_SUMMARY_SUCCESS} from '../../_actions/documentSummary';
import {WORK_TIME_ACCESS_ERROR} from '../../_middlewares/detectWorkTimeAccessError';

const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_SUMMARY_SUCCESS:
      return {...state, ...action.response.entities.documentSummary};
    case WORK_TIME_ACCESS_ERROR:
      return {};
    default:
      return state;
  }
};

export const getDocumentSummary = (state, documentSummaryId) =>
  state.byId[documentSummaryId];

export default byId;
