import {FETCH_MICROSOFT_SYSTEM_SUCCESS} from '../../../_actions/microsoft/system';

const system = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MICROSOFT_SYSTEM_SUCCESS:
      return action.response;
    default:
      return state;
  }
};

export const getMicrosoftSystem = state => state.system;

export default system;
