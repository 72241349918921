import _ from 'lodash';
import {
  FETCH_DOCUMENT_FILE_REQUEST,
  FETCH_DOCUMENT_FILE_SUCCESS,
  FETCH_DOCUMENT_FILE_ERROR,
} from '../../_actions/documentFile';

const isFetching = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_FILE_REQUEST:
      return _.merge({}, state, {
        [action.documentTypeId]: {
          [action.documentId]: {
            [action.fileId]: true,
          },
        },
      });
    case FETCH_DOCUMENT_FILE_SUCCESS:
    case FETCH_DOCUMENT_FILE_ERROR:
      return _.merge({}, state, {
        [action.documentTypeId]: {
          [action.documentId]: {
            [action.fileId]: false,
          },
        },
      });
    default:
      return state;
  }
};

export const getIsFetching = (state, documentTypeId, documentId, fileId) =>
  _.get(state, ['isFetching', documentTypeId, documentId, fileId], false);

export default isFetching;
