import React from 'react';
import PropTypes from 'prop-types';

class ErrorHandler extends React.Component {
  componentDidCatch(error, info) {
    if (typeof this.props.onError === 'function') {
      this.props.onError(error, info);
    }
  }

  render() {
    const content = this.props.error ? null : this.props.children;

    return content;
  }
}

ErrorHandler.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.element.isRequired,
  onError: PropTypes.func.isRequired,
};

ErrorHandler.defaultProps = {
  error: null,
};

export default ErrorHandler;
