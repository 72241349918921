import {
  CREATE_APP_LOG_REQUEST,
  CREATE_APP_LOG_SUCCESS,
  CREATE_APP_LOG_ERROR,
} from '../../_actions/appLog';

const isCreating = (state = false, action) => {
  switch (action.type) {
    case CREATE_APP_LOG_REQUEST:
      return true;
    case CREATE_APP_LOG_SUCCESS:
    case CREATE_APP_LOG_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsCreating = state => state.isCreating;

export default isCreating;
