import {
  SEND_TWOFA_SMS_REQUEST,
  SEND_TWOFA_SMS_SUCCESS,
} from '../../_actions/twofa';

const pinId = (state = null, action) => {
  switch (action.type) {
    case SEND_TWOFA_SMS_REQUEST:
      return null;
    case SEND_TWOFA_SMS_SUCCESS:
      return action.response.pinId;
    default:
      return state;
  }
};

export const getPinId = state => state.pinId;

export default pinId;
