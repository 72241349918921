import {
  REGISTER_DEVICE_REQUEST,
  REGISTER_DEVICE_SUCCESS,
} from '../../_actions/device';

const deviceId = (state = null, action) => {
  switch (action.type) {
    case REGISTER_DEVICE_REQUEST:
      return null;
    case REGISTER_DEVICE_SUCCESS:
      return action.response.device_id;
    default:
      return state;
  }
};

export const getDeviceId = state => state.deviceId;

export default deviceId;
