import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/messaging';
import {updatePushToken} from '../utils/api/device';
import {FCM_SENDER_ID} from '../config/constants';

export const initializePush = () => {
  if (firebase.apps.length === 0) {
    return firebase.initializeApp({
      messagingSenderId: FCM_SENDER_ID,
    });
  } else {
    return firebase.app();
  }
};

export const requestPermission = onPushReceived => {
  try {
    const firebaseApp = initializePush();
    const messaging = firebaseApp.messaging();
    messaging.onMessage(onPushReceived);

    messaging
      .requestPermission()
      .then(() => {
        return messaging.getToken();
      })
      .then(token => {
        return updatePushToken(token);
      })
      .then(() => {
        // Token updated
      });
  } catch (error) {
    if (error.code === 'messaging/permission-blocked') {
      // Usuário recusou permissão de recebimento de push
    }
  }
};
