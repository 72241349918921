import {useEffect, useRef} from 'react';

/**
 * A useEffect that does not run on the initial render phase
 * @param {function} effect
 * @param {Array<any>} diffArray
 */
export const useEffectSkipRender = (effect, diffArray) => {
  const ref = useRef(true);

  useEffect(() => {
    if (ref.current) {
      ref.current = false;
      return;
    }
    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, diffArray);
};
