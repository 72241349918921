import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialButton from '@material-ui/core/Button';
import {withTheme} from '@material-ui/core/styles';

const Progress = withTheme()(styled(CircularProgress)`
  color: ${props => props.theme.palette.goapprove.primaryColor};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`);

const Container = styled.div`
  position: relative;
`;

const Button = ({children, showProgress, disabled, ...props}) => (
  <Container>
    <MaterialButton {...props} disabled={disabled || showProgress}>
      {children}
    </MaterialButton>
    {showProgress && <Progress size={24} />}
  </Container>
);

Button.propTypes = {
  children: PropTypes.node,
  showProgress: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default Button;
