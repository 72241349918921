import {
  VERIFY_TWOFA_PIN_REQUEST,
  VERIFY_TWOFA_PIN_SUCCESS,
  VERIFY_TWOFA_PIN_ERROR,
} from '../../_actions/twofa';

const isVerifyingTwoFaPin = (state = false, action) => {
  switch (action.type) {
    case VERIFY_TWOFA_PIN_REQUEST:
      return true;
    case VERIFY_TWOFA_PIN_SUCCESS:
    case VERIFY_TWOFA_PIN_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsVerifyingTwoFaPin = state => state.isVerifyingTwoFaPin;

export default isVerifyingTwoFaPin;
