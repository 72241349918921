import {
  VERIFY_TWOFA_PIN_REQUEST,
  VERIFY_TWOFA_PIN_SUCCESS,
} from '../../_actions/twofa';

const verifyTwoFaPinResult = (state = null, action) => {
  switch (action.type) {
    case VERIFY_TWOFA_PIN_REQUEST:
      return null;
    case VERIFY_TWOFA_PIN_SUCCESS:
      return action.response.success;
    default:
      return state;
  }
};

export const getVerifyTwoFaPinResult = state => state.verifyTwoFaPinResult;

export default verifyTwoFaPinResult;
