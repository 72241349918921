import * as faceApi from '../../utils/api/faceAuthentication';

export const FACE_AUTHENTICATE_USER_REQUEST = 'FACE_AUTHENTICATE_USER_REQUEST';
export const FACE_AUTHENTICATE_USER_SUCCESS = 'FACE_AUTHENTICATE_USER_SUCCESS';
export const FACE_AUTHENTICATE_USER_ERROR = 'FACE_AUTHENTICATE_USER_ERROR';
export const CREATE_PERSON_REQUEST = 'CREATE_PERSON_REQUEST';
export const CREATE_PERSON_SUCCESS = 'CREATE_PERSON_SUCCESS';
export const CREATE_PERSON_ERROR = 'CREATE_PERSON_ERROR';
export const FETCH_PERSON_REQUEST = 'FETCH_PERSON_REQUEST';
export const FETCH_PERSON_SUCCESS = 'FETCH_PERSON_SUCCESS';
export const FETCH_PERSON_ERROR = 'FETCH_PERSON_ERROR';
export const DELETE_PERSON_REQUEST = 'DELETE_PERSON_REQUEST';
export const DELETE_PERSON_SUCCESS = 'DELETE_PERSON_SUCCESS';
export const DELETE_PERSON_ERROR = 'DELETE_PERSON_ERROR';
export const CREATE_FACE_REQUEST = 'CREATE_FACE_REQUEST';
export const CREATE_FACE_SUCCESS = 'CREATE_FACE_SUCCESS';
export const CREATE_FACE_ERROR = 'CREATE_FACE_ERROR';

export const faceAuthentication = (
  personGroupId,
  person,
  photo
) => async dispatch => {
  dispatch(faceAuthenticationRequest());
  await faceApi
    .faceAuthentication(personGroupId, person, photo)
    .then(response => {
      dispatch(faceAuthenticationSuccess(response));
    })
    .catch(error => {
      dispatch(faceAuthenticationError(error));
    });
};

export const createPerson = (personGroupId, person) => async dispatch => {
  dispatch(createPersonRequest());
  await faceApi
    .createPerson(personGroupId, {name: person})
    .then(response => {
      dispatch(createPersonSuccess(response));
    })
    .catch(error => {
      dispatch(createPersonError(error));
    });
};

export const fetchPerson = (personGroupId, personId) => async dispatch => {
  dispatch(fetchPersonRequest());
  await faceApi
    .getPerson(personGroupId, personId)
    .then(response => {
      dispatch(fetchPersonSuccess(response));
    })
    .catch(error => {
      dispatch(fetchPersonError(error));
    });
};

export const deletePerson = (personGroupId, personId) => async dispatch => {
  dispatch(deletePersonRequest());
  await faceApi
    .deletePerson(personGroupId, personId)
    .then(response => {
      dispatch(deletePersonSuccess(response));
    })
    .catch(error => {
      dispatch(deletePersonError(error));
    });
};

export const createFace = (
  personGroupId,
  personId,
  photo
) => async dispatch => {
  dispatch(createFaceRequest());
  await faceApi
    .createFace(personGroupId, personId, photo)
    .then(response => {
      dispatch(createFaceSuccess(response));
    })
    .catch(error => {
      dispatch(createFaceError(error));
    });
};

export const faceAuthenticationRequest = () => ({
  type: FACE_AUTHENTICATE_USER_REQUEST,
});

export const faceAuthenticationSuccess = response => ({
  type: FACE_AUTHENTICATE_USER_SUCCESS,
  response,
});

export const faceAuthenticationError = error => ({
  type: FACE_AUTHENTICATE_USER_ERROR,
  error,
});

export const createPersonRequest = () => ({
  type: CREATE_PERSON_REQUEST,
});

export const createPersonSuccess = response => ({
  type: CREATE_PERSON_SUCCESS,
  response,
});

export const createPersonError = error => ({
  type: CREATE_PERSON_ERROR,
  error,
});

export const fetchPersonRequest = () => ({
  type: FETCH_PERSON_REQUEST,
});

export const fetchPersonSuccess = response => ({
  type: FETCH_PERSON_SUCCESS,
  response,
});

export const fetchPersonError = error => ({
  type: FETCH_PERSON_ERROR,
  error,
});

export const deletePersonRequest = () => ({
  type: DELETE_PERSON_REQUEST,
});

export const deletePersonSuccess = response => ({
  type: DELETE_PERSON_SUCCESS,
  response,
});

export const deletePersonError = error => ({
  type: DELETE_PERSON_ERROR,
  error,
});

export const createFaceRequest = () => ({
  type: CREATE_FACE_REQUEST,
});

export const createFaceSuccess = response => ({
  type: CREATE_FACE_SUCCESS,
  response,
});

export const createFaceError = error => ({
  type: CREATE_FACE_ERROR,
  error,
});
