import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {addLocaleData, IntlProvider} from 'react-intl';
import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/pt';
import 'intl/locale-data/jsonp/es';
import en from 'react-intl/locale-data/en';
import pt from 'react-intl/locale-data/pt';
import es from 'react-intl/locale-data/es';
import translations from '../../assets/translations';

import {getDevice} from '../../_reducers/device';

addLocaleData([...en, ...pt, ...es]);

const flattenMessages = (nestedMessages, prefix = '') => {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key];
    let prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

const AppIntlProvider = ({children, language}) => (
  <IntlProvider
    locale={language}
    messages={flattenMessages(translations[language])}
    textComponent={React.Fragment}
  >
    {children}
  </IntlProvider>
);

AppIntlProvider.propTypes = {
  children: PropTypes.object,
  language: PropTypes.string.isRequired,
};

export default connect(state => {
  const {language} = getDevice(state);
  return {
    language: language.toLowerCase(),
  };
}, null)(AppIntlProvider);
