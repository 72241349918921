import {
  FETCH_DEVICE_REQUEST,
  FETCH_DEVICE_SUCCESS,
  FETCH_DEVICE_ERROR,
} from '../../_actions/device';

const fetchDeviceError = (state = null, action) => {
  switch (action.type) {
    case FETCH_DEVICE_REQUEST:
    case FETCH_DEVICE_SUCCESS:
      return null;
    case FETCH_DEVICE_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getFetchDeviceError = state => state.fetchDeviceError;

export default fetchDeviceError;
