import {
  SEND_TWOFA_SMS_REQUEST,
  SEND_TWOFA_SMS_SUCCESS,
  SEND_TWOFA_SMS_ERROR,
} from '../../_actions/twofa';

const sendTwoFaSmsError = (state = null, action) => {
  switch (action.type) {
    case SEND_TWOFA_SMS_REQUEST:
    case SEND_TWOFA_SMS_SUCCESS:
      return null;
    case SEND_TWOFA_SMS_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getSendTwoFaSmsError = state => state.sendTwoFaSmsError;

export default sendTwoFaSmsError;
