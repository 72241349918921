import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ChangePasswordDialog from './ChangePasswordDialog';
import {getRequestAuthenticationError} from '../../../_reducers/authentication';

export const ChangePassContext = React.createContext({
  openChangePassDialog: () => {},
});

class Provider extends React.Component {
  state = {
    showDialog: false,
  };

  openChangePassDialog = () => {
    this.setState({showDialog: true});
  };

  closeChangePassDialog = () => {
    this.setState({
      showDialog: false,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      !prevProps.requestAuthenticationError &&
      Boolean(this.props.requestAuthenticationError)
    ) {
      this.openChangePassDialog();
    }
  }

  render() {
    return (
      <ChangePassContext.Provider
        value={{
          openChangePassDialog: this.openChangePassDialog,
        }}
      >
        <React.Fragment>
          <ChangePasswordDialog
            open={this.state.showDialog}
            hideDialog={this.closeChangePassDialog}
          />
          {this.props.children}
        </React.Fragment>
      </ChangePassContext.Provider>
    );
  }
}

Provider.propTypes = {
  children: PropTypes.node,
  requestAuthenticationError: PropTypes.object,
};

export const ChangePassProvider = connect(state => {
  return {requestAuthenticationError: getRequestAuthenticationError(state)};
}, null)(Provider);
