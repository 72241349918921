import {combineReducers} from 'redux';
import isFetching, * as fromIsFetching from './isFetching';
import fetchError, * as fromFetchError from './fetchError';
import byId, * as fromById from './byId';
import ids, * as fromIds from './ids';

const getLocalState = state => state.documentSummary;

export default combineReducers({isFetching, fetchError, byId, ids});

export const getIsFetching = (state, id) =>
  fromIsFetching.getIsFetching(getLocalState(state), id);

export const getFetchError = (state, id) =>
  fromFetchError.getFetchError(getLocalState(state), id);

export const getDocumentSummary = (state, id) =>
  fromById.getDocumentSummary(getLocalState(state), id);

export const getDocumentSummaries = state => {
  const localState = getLocalState(state);
  const ids = fromIds.getIds(localState);
  return ids.map(id => fromById.getDocumentSummary(localState, id));
};
