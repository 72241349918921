import {
  FETCH_MICROSOFT_AUTH_REQUEST,
  FETCH_MICROSOFT_AUTH_SUCCESS,
  FETCH_MICROSOFT_AUTH_ERROR,
} from '../../../_actions/microsoft/auth';

const fetchMicrosoftAuthError = (state = null, action) => {
  switch (action.type) {
    case FETCH_MICROSOFT_AUTH_REQUEST:
    case FETCH_MICROSOFT_AUTH_SUCCESS:
      return null;
    case FETCH_MICROSOFT_AUTH_ERROR:
      return action.error;
    default:
      return state;
  }
};

export const getFetchMicrosoftAuthError = state =>
  state.fetchMicrosoftAuthError;

export default fetchMicrosoftAuthError;
