import {FETCH_APPEARANCE_SUCCESS} from '../../_actions/appearance';

const appearance = (state = {}, action) => {
  switch (action.type) {
    case FETCH_APPEARANCE_SUCCESS:
      return action.response.appearance;
    default:
      return state;
  }
};

export const getAppearance = state => state.appearance;

export default appearance;
