import _ from 'lodash';
import {
  FETCH_DOCUMENT_FILE_SUCCESS,
  FETCH_DOCUMENT_FILE_ERROR,
} from '../../_actions/documentFile';

const file = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_FILE_SUCCESS:
      return _.merge({}, state, {
        [action.documentTypeId]: {
          [action.documentId]: {
            [action.fileId]: {
              fileURL: `${action.fileURL}`,
            },
          },
        },
      });
    case FETCH_DOCUMENT_FILE_ERROR:
      return _.merge({}, state, {
        [action.documentTypeId]: {
          [action.documentId]: {
            [action.fileId]: null,
          },
        },
      });
    default:
      return state;
  }
};

export const getFile = (state, documentTypeId, documentId, fileId) =>
  _.get(state, ['file', documentTypeId, documentId, fileId]);

export default file;
