import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import reducers from './_reducers';
import {setLanguageMiddleware} from './_middlewares/setLanguageMiddleware';
import {detectRequestAuthenticationError} from './_middlewares/detectRequestAuthenticationError';
import {setTimeoutMiddleware} from './_middlewares/setTimeoutMiddleware';
import {detectAppShouldStartOver} from './_middlewares/detectAppShouldStartOver';
import {detectWorkTimeAccessError} from './_middlewares/detectWorkTimeAccessError';

const configureStore = () =>
  createStore(
    reducers,
    composeWithDevTools(
      applyMiddleware(
        thunk,
        setLanguageMiddleware,
        setTimeoutMiddleware,
        detectRequestAuthenticationError,
        detectAppShouldStartOver,
        detectWorkTimeAccessError
      )
    )
  );

export default configureStore;
